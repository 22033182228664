import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../utils/interfaces/IBase";
import { IPostCOE, ISinglePostVM } from "../../utils/interfaces/IPost";
import { makeRequest } from "../auth/axios";

export const usePutPost = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IBaseData<ISinglePostVM>,
    Error,
    { postData: IPostCOE; postId: number }
  >({
    mutationFn: ({ postId, postData }) =>
      makeRequest.put(`/Posts/${postId}`, postData),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetPost"] });
      queryClient.invalidateQueries({ queryKey: ["useGetPostGroups"] });
    },
  });
};
