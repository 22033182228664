import SModal from "../../design-system/SModal";

export interface IConfirmDeleteProps {
  openModal: boolean;
  setOpenModal: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  title: string;
}

export const ConfirmDelete = ({
  openModal,
  setOpenModal,
  onConfirm,
  onCancel,
  title,
}: IConfirmDeleteProps) => {
  return (
    <SModal
      isOpen={openModal}
      setIsOpen={setOpenModal}
      handleCancel={onCancel || (() => {})}
      handleSubmit={onConfirm || (() => {})}
      title={title}
      type="error"
      actionButtonText="Confirm"
      actionButtonProps={{
        onClick: onConfirm,
      }}
    />
  );
};
