import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../../axios";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ITokenVM } from "../../../utils/interfaces/IToken";
import { useCopilotStore } from "../../../store/copilotStore";

export const usePutSwitchTeam = () => {
  const queryClient = useQueryClient();
  const { reset: resetCopilotStore } = useCopilotStore();
  return useMutation<IBaseData<ITokenVM>, any, { teamId: number }>({
    mutationFn: ({ teamId }) =>
      makeRequest.put(`/Auth/switch-workspace/${teamId}`),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetAllBrands"] });
      resetCopilotStore();
      localStorage.setItem("token", res.data.accessToken);
      queryClient
        .invalidateQueries({ queryKey: ["useGetBrand"] })
        .then((r) => null);
      queryClient.invalidateQueries({ queryKey: ["useGetActiveBrand"] });
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentBrand"] });
      queryClient.invalidateQueries({ queryKey: ["useGetInvitesForTeam"] });
      queryClient.invalidateQueries({ queryKey: ["useGetAnalytic"] });
      queryClient.invalidateQueries({
        queryKey: ["useGetPendingInvitesForTeam"],
      });
      queryClient.invalidateQueries({ queryKey: ["useGetUser"] });
      queryClient.invalidateQueries({ queryKey: ["useGetAllCreation"] });
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentTeam"] });
      queryClient.invalidateQueries({ queryKey: ["useGetTeamsLimits"] });
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentPackage"] });
      queryClient.invalidateQueries({ queryKey: ["useGetLastPackage"] });
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentPeriodInfo"] });
      queryClient.invalidateQueries({ queryKey: ["useGetAllFiles"] });
      queryClient.invalidateQueries({ queryKey: ["useGetFoldersAndFiles"] });
      queryClient.invalidateQueries({ queryKey: ["useGetCalendarData"] });
      queryClient.invalidateQueries({ queryKey: ["useGetOnboardingInfo"] });
      queryClient.invalidateQueries({ queryKey: ["useGetActiveMembers"] });
      queryClient
        .invalidateQueries({ queryKey: ["useGetAllConnections"] })
        .then((r) => null);
      queryClient
        .invalidateQueries({ queryKey: ["useGetScheduledPosts"] })
        .then((r) => null);
      queryClient
        .invalidateQueries({ queryKey: ["useGetInvoices"] })
        .then((r) => null);
      queryClient
        .invalidateQueries({ queryKey: ["useGetHavePaymentMethod"] })
        .then((r) => null);
      queryClient
        .invalidateQueries({ queryKey: ["useGetFolders"] })
        .then((r) => null);
      queryClient
        .invalidateQueries({ queryKey: ["useGetFolderFiles"] })
        .then((r) => null);
      queryClient
        .invalidateQueries({ queryKey: ["useGetCopilotSessions"] })
        .then((r) => null);
      queryClient
        .invalidateQueries({ queryKey: ["useGetBrandingPost"] })
        .then((r) => null);
      return res;
    },
  });
};
