import React, { useState } from "react";
import SBreadcrumbs, {
  IBreadcrumbsItem,
} from "../../../../../design-system/SBreadcrumbs";
import SUnderlineTabs from "../../../../../design-system/SUnderlineTabs";
import { ISingleFoldersAndFilesVM } from "../../../../../utils/interfaces/IAssets";

function Head({
  folderHistory,
  setFolderHistory,
  setFoldersAndFiles,
  setPage,
  setParentFolder,
}: {
  folderHistory: IBreadcrumbsItem[];
  setFolderHistory: React.Dispatch<React.SetStateAction<IBreadcrumbsItem[]>>;
  setFoldersAndFiles: React.Dispatch<React.SetStateAction<ISingleFoldersAndFilesVM[]>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setParentFolder: React.Dispatch<React.SetStateAction<{ id: number | null; title: string } | null>>;
}) {
  const [selectedFileType, setSelectedFileType] = useState(0);
  return (
    <div className="flex justify-between items-center">
      <SBreadcrumbs
        needGoBack={folderHistory.length >= 2}
        onGoBack={() => {
          const prevItem = folderHistory[folderHistory.length - 2];
          folderHistory.length > 2 &&
            setFolderHistory((prev) => prev.slice(0, -1));
          setFoldersAndFiles([]);
          setPage(1);
          setParentFolder({
            id: prevItem?.id ? prevItem?.id : null,
            title: prevItem.title,
          });
        }}
        needHome={false}
        pages={folderHistory}
      />
      {/* <SUnderlineTabs
        items={[
          {
            index: 0,
            text: "Images",
          },
          {
            index: 1,
            text: "Videos",
          },
        ]}
        selected={selectedFileType}
        setSelected={(e) => setSelectedFileType(e)}
      /> */}
    </div>
  );
}

export default Head;
