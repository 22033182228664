import React from "react";
import SModal from "../../../design-system/SModal";

function CopilotStateChangeAgreement({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (p: boolean) => void;
}) {
  return (
    <SModal
      title={"Copilot state"}
      subtitle={
        'If you write text manually, the copilot will change state and start working in the "texts" mode. Are you sure you want to do this?'
      }
      type="warning"
      isOpen={isOpen}
      setIsOpen={(p) => setIsOpen(p)}
      handleSubmit={() => {
        setIsOpen(false);
      }}
      handleCancel={() => setIsOpen(false)}
      actionButtonText={"Continue writing"}
      actionButtonProps={{
        className: "w-[400px]",
      }}
    />
  );
}

export default CopilotStateChangeAgreement;
