import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { ISingleBrandVM } from "../utils/interfaces/IBrand";

export interface IContentStoreInitialInterface {
  currentBrand?: ISingleBrandVM | null;
  isBrandFetching: boolean;
  isBrandLoading: boolean;
}

const initialState: IContentStoreInitialInterface = {
  currentBrand: null,
  isBrandLoading: false,
  isBrandFetching: true,
};

const store = (set: any) => ({
  ...initialState,
  setCurrentBrand: (currentBrand: ISingleBrandVM | null | undefined) =>
    set({ currentBrand }),
  setIsBrandFetching: (isBrandFetching: boolean) => set({ isBrandFetching }),
  setIsBrandLoading: (isBrandLoading: boolean) => set({ isBrandLoading }),
  reset: () => set(initialState),
});
export const useBrandStore = create(devtools(store));
