import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {
  ICopilotMessage,
  ICopilotSessionVM,
} from "../utils/interfaces/ICopilot";

export interface ICreationStoreInitialInterface {
  isCopilotOpen: boolean;
  activeSession: ICopilotSessionVM | null;
  messages: ICopilotMessage[];
  chatResponse: string;
  isInputDisabled: boolean;
  isWriting: boolean;
}

const initialState: ICreationStoreInitialInterface = {
  isCopilotOpen: false,
  activeSession: null,
  messages: [],
  chatResponse: "",
  isInputDisabled: false,
  isWriting: false,
};

const store = (set: any) => ({
  ...initialState,
  setIsCopilotOpen: (isCopilotOpen: boolean) => set({ isCopilotOpen }),
  setIsWriting: (isWriting: boolean) => set({ isWriting }),
  setIsInputDisabled: (isInputDisabled: boolean) => set({ isInputDisabled }),
  setChatResponse: (chatResponse: string) => set({ chatResponse }),
  setMessages: (messages: ICopilotMessage[]) => set({ messages }),
  setActiveSession: (activeSession: ICopilotSessionVM | null) =>
    set({ activeSession }),
  reset: () => set(initialState),
});
export const useCopilotStore = create(devtools(store));
