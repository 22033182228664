import React, { useEffect, useRef, useState } from "react";
import SInput from "../../design-system/SInput";
import Sketch from "@uiw/react-color-sketch";
import { useHandleOutsideClick } from "../../hooks/useHandleOutsideClick";
import { IBrandCreationData } from "../../pages/CreateBrand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/pro-regular-svg-icons";
const isValidColor = (color: string) => {
  const hexPattern = /^#([0-9A-F]{3}){1,2}$/i;
  const rgbPattern = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/;
  const rgbaPattern =
    /^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(0|1|0?\.\d+)\)$/;
  return (
    hexPattern.test(color) || rgbPattern.test(color) || rgbaPattern.test(color)
  );
};
function Colors({
  setData,
  data,
}: {
  setData: React.Dispatch<React.SetStateAction<IBrandCreationData>>;
  data: IBrandCreationData;
}) {
  const [primaryPickerIsOpen, setPrimaryPickerIsOpen] = useState(false);
  const [secondaryPickerIsOpen, setSecondaryPickerIsOpen] = useState(false);
  const primaryPickerRef = useRef<HTMLDivElement>(null);
  const secondaryPickerRef = useRef<HTMLDivElement>(null);
  const [primaryColor, setPrimaryColor] = useState("#000000");
  const [secondaryColor, setSecondaryColor] = useState("#000000");
  const [error1, setError1] = useState<string | undefined>(undefined);
  const [error2, setError2] = useState<string | undefined>(undefined);
  useHandleOutsideClick({
    ref: primaryPickerRef,
    callBack: () => {
      setPrimaryPickerIsOpen(false);
    },
    excludedIds: ["primary__picker-opener"],
  });

  useHandleOutsideClick({
    ref: secondaryPickerRef,
    callBack: () => {
      setSecondaryPickerIsOpen(false);
    },
    excludedIds: ["secondary__picker-opener"],
  });
  const [primaryClicked, setPrimaryClicked] = useState(!!data.primaryColor);
  const [secondaryClicked, setSecondaryClicked] = useState(
    !!data.secondaryColor,
  );

  useEffect(() => {
    if (primaryClicked) {
      setData((prev) => ({
        ...prev,
        primaryColor,
      }));
    }
    if (secondaryClicked) {
      setData((prev) => ({
        ...prev,
        secondaryColor,
      }));
    }
  }, [primaryColor, secondaryColor, primaryClicked, secondaryClicked]);

  return (
    <div className="w-[48%] max-md:w-full">
      <div className="flex items-center justify-between">
        <p className="text-sm-m text-gray-700">
          Colors
          <span className="text-sm !font-normal text-gray-400">
            {" "}
            (Optional)
          </span>
        </p>
        {(primaryClicked || secondaryClicked) && (
          <p
            onClick={() => {
              setPrimaryClicked(false);
              setSecondaryClicked(false);
              setData((prev) => ({
                ...prev,
                primaryColor: undefined,
                secondaryColor: undefined,
              }));
            }}
            className="text-xs-sb text-gray-600 cursor-pointer"
          >
            Clear
          </p>
        )}
      </div>
      <div className="flex items-center h-[126px] px-[16px] mt-[6px] py-[14px] gap-[32px] bg-base-white border border-gray-200 rounded-[10px]">
        {primaryClicked ? (
          <div className="relative w-full">
            {primaryPickerIsOpen && (
              <Sketch
                id="primary__picker-opener"
                color={primaryColor}
                onChange={(color) => {
                  setPrimaryColor(color.hex);
                  setError1(
                    isValidColor(color.hex)
                      ? undefined
                      : "Invalid primary color",
                  );
                }}
                ref={primaryPickerRef}
                style={{ position: "absolute", top: "100%", zIndex: "10" }}
              />
            )}
            <div
              className="h-[58px] w-full rounded-t-[12px] flex justify-center items-center"
              style={{
                backgroundColor: primaryColor,
              }}
              onClick={() => setPrimaryPickerIsOpen(!primaryPickerIsOpen)}
            >
              <p className="text-sm-sb text-base-white">Primary</p>
            </div>
            <SInput
              inputProps={{
                className:
                  "h-[36px] border-0 border-transparent rounded-b-[12px] text-sm p-[12px] w-full shadow-lg",
              }}
              error={error1}
              value={primaryColor}
              onChange={(e) => {
                const value = e.target.value.trim();
                setPrimaryColor(value);
                setError1(
                  isValidColor(value) ? undefined : "Invalid primary color",
                );
              }}
            />
          </div>
        ) : (
          <div
            onClick={() => {
              setPrimaryClicked(true);
              setPrimaryPickerIsOpen(true);
            }}
            className="cursor-pointer w-full border border-[rgba(0,0,0,0.10)] rounded-[12px] overflow-hidden shadow-xs"
          >
            <div className="h-[58px] w-full flex justify-center items-center bg-gray-50">
              <FontAwesomeIcon icon={faAdd} className="text-gray-600" />
            </div>
            <div className="h-[36px] px-[12px] py-[8px] bg-base-white">
              <p className="text-sm text-gray-800">Primary</p>
            </div>
          </div>
        )}
        {secondaryClicked ? (
          <div className="relative w-full">
            {secondaryPickerIsOpen && (
              <Sketch
                id="primary__picker-opener"
                color={secondaryColor}
                onChange={(color) => {
                  setSecondaryColor(color.hex);
                  setError2(
                    isValidColor(color.hex)
                      ? undefined
                      : "Invalid primary color",
                  );
                }}
                ref={secondaryPickerRef}
                style={{ position: "absolute", top: "100%", zIndex: "10" }}
              />
            )}
            <div
              className="h-[58px] w-full rounded-t-[12px] flex justify-center items-center"
              style={{
                backgroundColor: secondaryColor,
              }}
              onClick={() => setSecondaryPickerIsOpen(!secondaryPickerIsOpen)}
            >
              <p className="text-sm-sb text-base-white">Secondary</p>
            </div>
            <SInput
              inputProps={{
                className:
                  "h-[36px] border-0 border-transparent rounded-b-[12px] text-sm p-[12px] w-full shadow-lg",
              }}
              error={error2}
              value={secondaryColor}
              onChange={(e) => {
                const value = e.target.value.trim();
                setSecondaryColor(value);
                setError2(
                  isValidColor(value) ? undefined : "Invalid primary color",
                );
              }}
            />
          </div>
        ) : (
          <div
            onClick={() => {
              setSecondaryPickerIsOpen(true);
              setSecondaryClicked(true);
            }}
            className="cursor-pointer w-full border border-[rgba(0,0,0,0.10)] rounded-[12px] overflow-hidden shadow-xs"
          >
            <div className="h-[58px] w-full flex justify-center items-center bg-gray-50">
              <FontAwesomeIcon icon={faAdd} className="text-gray-600" />
            </div>
            <div className="h-[36px] px-[12px] py-[8px] bg-base-white">
              <p className="text-sm text-gray-800">Secondary</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Colors;
