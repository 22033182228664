import React, { useEffect, useRef } from "react";
import { format, isSameDay, parse, addHours, startOfDay } from "date-fns";
import { useCalendarStore } from "../../store/calendarStore";
import DayHead from "./day/DayHead";
import DayCard from "./day/DayCard";
import { useCalendarHelper } from "./hooks/useCalendarHelper";
import { useGetCalendarData } from "../../api/calendar/get";
import { useCreationHelperFunctions } from "../creation/hooks/useCreationHelperFunctions";

const hours = Array.from({ length: 24 }, (_, i) => i);

export default function DayView() {
  const { convertToISOAndChangeTimezone } = useCalendarHelper();
  const { handleOpenPost } = useCreationHelperFunctions();
  const { currentDate } = useCalendarStore();
  const { data: posts } = useGetCalendarData({
    from: convertToISOAndChangeTimezone(
      new Date(format(startOfDay(currentDate), "yyyy-MM-dd")),
    ),
    to: convertToISOAndChangeTimezone(
      new Date(format(startOfDay(addHours(currentDate, 24)), "yyyy-MM-dd")),
    ),
  });
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const startOfDayDate = startOfDay(currentDate);

  return (
    <div className="shadow-xs border-[1px] rounded-[8px] overflow-hidden bg-base-white">
      <DayHead />
      <div className="divide-y divide-gray-100 min-h-[789px]" ref={scrollRef}>
        {hours.map((hour) => {
          const hourStart = addHours(startOfDayDate, hour);
          return (
            <div key={hour} className="flex">
              <div className="w-[60px] p-[8px] text-center text-xs text-neutral-400 border-r-[1px] border-gray-100">
                {format(hourStart, "HH a")}
              </div>
              <div
                onClick={() => handleOpenPost({})}
                className="cursor-pointer w-full flex flex-col gap-2 px-[16px] py-[10px]"
              >
                {posts &&
                  posts.data
                    .filter((fPost) => {
                      const postDate = new Date(fPost.releaseDate as string);
                      return (
                        postDate.getHours() === hour && !fPost.isExamplePost
                      );
                    })
                    .map((post) => <DayCard post={post} />)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
