import React from "react";
import ChangePassword from "./ChangePassword";
import RequestDeleteAccount from "./RequestDeleteAccount";
import { IBase } from "../../utils/interfaces/IBase";
import { IAccountVM } from "../../utils/interfaces/IAccount";

function Security({ userInfo }: { userInfo?: IBase<IAccountVM> }) {
  return (
    <div className="mt-[28px]">
      <ChangePassword hasPassword={userInfo?.data.data.hasPassword} />
      {/*<ExternalLinkGroups />*/}
      {userInfo?.data.data.hasPassword ? (
        <RequestDeleteAccount />
      ) : (
        <div className="mb-10 justify-between flex flex-col mt-8 bg-primary rounded-full">
          <div className="px-6 py-5 justify-between flex items-center">
            <p className="ps text-s">
              You have not set a password for your account. Please set a
              password to be able to use the full functionality.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Security;
