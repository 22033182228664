import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function UpcomingPostsHead() {
  return (
    <div className="flex justify-between items-center">
      <p className="text-sm-m text-gray-900">Upcoming Posts</p>
      <div className="flex gap-[4px] items-center cursor-pointer">
        <p className="text-brand-600 text-xs-sb">See All</p>
        <FontAwesomeIcon
          icon={faArrowUpRightFromSquare}
          className="text-brand-600 text-[10px]"
        />
      </div>
    </div>
  );
}

export default UpcomingPostsHead;
