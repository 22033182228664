import React, { ChangeEvent, useEffect, useState } from "react";
import FieldAvatar from "./FieldAvatar";
import FieldInput from "./FieldInput";
import {
  faCheck,
  faEnvelope,
  faMapPin,
  faPhone,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import FieldSelector from "./FieldSelector";
import SButton from "../../design-system/SButton";
import { usePutAccount } from "../../hooks/useUpdateUserInfo";
import { IAccountVM } from "../../utils/interfaces/IAccount";
import { OrganizationUserRole } from "../../utils/enums/Enums";
import { useSelectFiles } from "../../hooks/useSelectFiles";
import { usePostAccountPhoto } from "../../hooks/usePostAccountPhoto";
import useLanguage from "../../hooks/useLanguage";
import useTimezone from "../../hooks/useTimezone";
import timezones from "./Timezones";
import { IBase } from "../../utils/interfaces/IBase";
import { toast } from "../../hooks/toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";
import { usePutChangeEmail } from "../../api/auth/changeEmail/put";

const PersonalInfo = ({ userInfo }: { userInfo?: IBase<IAccountVM> }) => {
  const { mutate: updateUserInfo } = usePutAccount();
  const { mutateAsync: updateEmail } = usePutChangeEmail();
  const { files, selectFile, clearFiles } = useSelectFiles();
  const { mutateAsync: uploadPhoto } = usePostAccountPhoto();

  const {
    selectedLanguage,
    setSelectedLanguage,
    dropdownItems,
    getSelectedIndex,
  } = useLanguage();

  const {
    selectedTimezone,
    setSelectedTimezone,
    timezoneItems,
    getTimezoneIndex,
    getTimezoneLabelFromValue,
  } = useTimezone();

  const [isTimezoneOpen, setIsTimezoneOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const [userData, setUserData] = useState<IAccountVM>({
    id: "",
    name: "",
    photoUrl: null,
    email: "",
    phone: null,
    title: null,
    address: null,
    created: null,
    emailConfirmed: false,
    intercomHash: "",
    hasPassword: false,
    timezone: "",
    isSetupRequested: false,
    userRole: OrganizationUserRole.VIEWER,
  });

  useEffect(() => {
    if (userInfo) {
      setUserData({
        id: userInfo.data.data.id || "",
        name: userInfo.data.data.name || "",
        photoUrl: userInfo.data.data.photoUrl,
        email: userInfo.data.data.email || "",
        phone: userInfo.data.data.phone || "",
        title: userInfo.data.data.title || "",
        address: userInfo.data.data.address || "",
        created: userInfo.data.data.created || null,
        emailConfirmed: userInfo.data.data.emailConfirmed || false,
        intercomHash: userInfo.data.data.intercomHash || "",
        hasPassword: userInfo.data.data.hasPassword || false,
        timezone: userInfo.data.data.timezone || "",
        isSetupRequested: userInfo.data.data.isSetupRequested || false,
        userRole: userInfo.data.data.userRole || OrganizationUserRole.VIEWER,
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (files.length > 0) {
      const formData = new FormData();
      formData.append("file", files[0]);

      uploadPhoto(formData)
        .then(() => clearFiles())
        .catch((err) => {
          clearFiles();
          toast(err.response.data.message, "error");
        });
    }
  }, [files, uploadPhoto]);

  const handleChange = (index: number, value: string) => {
    const fields: (keyof IAccountVM)[] = [
      "name",
      "email",
      "phone",
      "address",
      "title",
      "timezone",
    ];

    const fieldName = fields[index];
    if (fieldName) {
      setUserData((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    }
  };

  const handleSave = () => {
    if (userData.email !== userInfo?.data.data.email) {
      updateEmail({ newEmail: userData.email })
      .then(res => {
        toast('Email change instructions are sent to your new email', 'success')
      })
      .catch((err) => {
        toast(err.response.data.message)
      });
    }
    updateUserInfo({ ...userData, isSetupRequested: false });
  };

  useEffect(() => {
    setUserData((prev) => ({
      ...prev,
      timezone:
        timezones.find((timezone) => timezone.label === selectedTimezone)
          ?.value || "",
    }));
  }, [selectedTimezone]);

  return (
    <div className="mt-[28px] mb-[40px]">
      <Tooltip
        id="tooltip-email-confirmed"
        place="top"
        style={{ zIndex: 1 }}
        disableStyleInjection
      >
        Email is Confirmed
      </Tooltip>
      <Tooltip
        id="tooltip-email-unconfirmed"
        place="top"
        style={{ zIndex: 1 }}
        disableStyleInjection
      >
        Email is not confirmed
      </Tooltip>
      <FieldAvatar
        fieldName="Your photo"
        avatar={userData.photoUrl ?? ""}
        uploaderProps={{
          haveDragAndDrop: true,
          onDrop: (file: File) => selectFile([file]),
        }}
      />
      <FieldInput
        fieldName={"Your name"}
        inputProps={{
          placeholder: "eg. Rick Sanchez",
          value: userData.name,
          onChange: (e) => handleChange(0, e.target.value),
        }}
      />
      <FieldInput
        fieldName={"Email address"}
        inputProps={{
          icon: faEnvelope,
          placeholder: "eg. rick@example.com",
          value: userData.email,
          onChange: (e) => handleChange(1, e.target.value),
          rightAdditionalContent: userData.emailConfirmed && (
            <FontAwesomeIcon
              data-tooltip-id="tooltip-email-confirmed"
              icon={faCheck}
              className="text-success-500"
            />
          ),
        }}
      />
      <FieldInput
        fieldName={"Phone number"}
        inputProps={{
          icon: faPhone,
          placeholder: "Your phone number",
          value: userData.phone || "",
          onChange: (e) => handleChange(2, e.target.value),
        }}
      />
      <FieldInput
        fieldName={"Address"}
        inputProps={{
          icon: faMapPin,
          placeholder: "Your address",
          value: userData.address || "",
          onChange: (e) => handleChange(3, e.target.value),
        }}
      />
      <FieldInput
        fieldName={"Role"}
        inputProps={{
          placeholder: "Your role",
          value: userData.title || "",
          onChange: (e) => handleChange(4, e.target.value),
        }}
      />
      <FieldSelector
        fieldName={"Interface Language"}
        selectorProps={{
          items: dropdownItems,
          selected: getSelectedIndex(),
          setSelected: (index: number | null) => {
            const languageCode =
              dropdownItems.find((item) => item.index === index)?.value || "en";
            setSelectedLanguage(languageCode);
          },
          isOpen: isDropdownOpen,
          setIsOpen: setIsDropdownOpen,
          excludedIds: [],
          className: "absolute bottom-[48px] w-1/2",
          defaultSelect: selectedLanguage
            ? selectedLanguage
            : "Select a Language",
        }}
      />
      {userInfo && (
        <FieldSelector
          fieldName={"Timezone"}
          selectorProps={{
            items: timezoneItems,
            selected: getTimezoneIndex(),
            setSelected: setSelectedTimezone,
            isOpen: isTimezoneOpen,
            setIsOpen: setIsTimezoneOpen,
            excludedIds: [],
            className: "absolute bottom-[48px] w-1/2",
            defaultSelect: getTimezoneLabelFromValue(
              userInfo.data.data.timezone,
            ),
          }}
        />
      )}
      <div className="mt-[20px]">
        <SButton size="sm" onClick={handleSave}>
          {"Save Changes"}
        </SButton>
      </div>
    </div>
  );
};

export default PersonalInfo;
