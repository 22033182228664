import React, { useEffect, useState } from "react";
import { useBrandStore } from "../../../store/brandStore";
import { generateShades } from "../functions/generateColorShades";
import ColorsGrid from "../ColorsGrid";
import ColorsGridSkeleton from "../ColorsGridSkeleton";
import SButton from "../../../design-system/SButton";
import { faSparkles } from "@fortawesome/pro-regular-svg-icons";
import { useBrandHelperFunctions } from "../hooks/useBrandHelperFunctions";

function Colors() {
  const [primaryShades, setPrimaryShades] = useState<string[]>([]);
  const [secondaryShades, setSecondaryShades] = useState<string[]>([]);
  const { currentBrand } = useBrandStore();
  useEffect(() => {
    if (currentBrand?.brandingData?.Colors.PrimaryColor?.HexValue) {
      setPrimaryShades(
        generateShades(currentBrand.brandingData.Colors.PrimaryColor.HexValue),
      );
    }
    if (currentBrand?.brandingData?.Colors.SecondaryColor?.HexValue) {
      setSecondaryShades(
        generateShades(
          currentBrand.brandingData.Colors.SecondaryColor.HexValue,
        ),
      );
    }
  }, [currentBrand]);
  const { handleRegenerateSection } = useBrandHelperFunctions();
  return (
    <div className="group bg-base-white border-[1px] rounded-[8px] border-gray-200">
      <div className="bg-gray-50 rounded-t-[8px] px-[32px] py-[20px] flex justify-between items-center max-md:py-[16px] max-md:px-[20px] max-md:gap-[15px]">
        <div>
          <p className="display-xs-b text-graycool-800">🎨 Colors</p>
          <p className="text-md text-graycool-600 mt-[2px]">
            Which colors describe your brand?
          </p>
        </div>
        <SButton
          onClick={() => handleRegenerateSection("Colors")}
          lIcon={faSparkles}
          type="secondaryColor"
          className="group-hover:opacity-100 group-hover:pointer-events-auto opacity-0 pointer-events-none max-md:opacity-100 max-md:pointer-events-auto"
        >
          <span className="max-md:hidden">Regenerate</span>
        </SButton>
      </div>
      <div className="px-[32px] py-[24px] bg-base-white max-md:py-[16px] max-md:px-[20px]">
        <div className="flex flex-col gap-[16px]">
          {currentBrand?.isBrandDataGenerated &&
          currentBrand?.brandingData?.Colors?.PrimaryColor?.HexValue ? (
            <ColorsGrid
              title="Primary"
              baseColor={currentBrand.brandingData.Colors.PrimaryColor.HexValue}
              shades={primaryShades}
            />
          ) : (
            <ColorsGridSkeleton />
          )}
          {currentBrand?.isBrandDataGenerated &&
          currentBrand?.brandingData?.Colors?.SecondaryColor?.HexValue ? (
            <ColorsGrid
              title="Secondary"
              baseColor={
                currentBrand.brandingData.Colors.SecondaryColor.HexValue
              }
              shades={secondaryShades}
            />
          ) : (
            <ColorsGridSkeleton />
          )}
        </div>
        {currentBrand?.brandingData?.Colors?.PrimaryColor?.Description &&
          currentBrand?.brandingData?.Colors?.SecondaryColor?.Description && (
            <p className="text-md text-graycool-600 mt-[24px]">
              To become the global leader indxzvsdcsc digital design, known for
              our unique blend of honesty, directness, and innovation, while
              fostering a community of clients who dare to be different.
            </p>
          )}
      </div>
    </div>
  );
}

export default Colors;
