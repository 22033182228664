import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../utils/interfaces/IBase";
import { IPostCOE, ISinglePostVM } from "../../utils/interfaces/IPost";
import { makeRequest } from "../auth/axios";

export const usePostPost = () => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<ISinglePostVM>, Error, IPostCOE>({
    mutationFn: (postData) => makeRequest.post("/Posts", postData),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetOnboardingInfo"] });
      queryClient.invalidateQueries({ queryKey: ["useGetPost"] });
      queryClient.invalidateQueries({ queryKey: ["useGetPostGroups"] });
    },
  });
};
