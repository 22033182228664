import { useUpdateGlobalLink } from "../../api/invitations/invitationLink/put";
import SButton from "../../design-system/SButton";
import SModalBase from "../../design-system/SModalBase";
import { toast } from "../../hooks/toast";

export const WorkspaceConfirmLinkUpdateModal = ({
  setConfirmModal,
  confirmModal,
}: {
  setConfirmModal: (value: boolean) => void;
  confirmModal: boolean;
}) => {
  const { mutateAsync: updateLink } = useUpdateGlobalLink();
  return (
    <SModalBase
      dialogPanelClassName="min-w-[440px] z-[20] py-4 text-gray-600"
      isOpen={confirmModal}
      onClose={setConfirmModal}
    >
      <h1 className="text-md-m mb-3">Are you sure to reset the invite link?</h1>
      <hr className="w-auto mx-[-24px]" />
      <div className="flex w-full flex-col gap-3 my-6">
        <h1 className="text-sm w-full">
          This will expire the current link and generate a new one.
        </h1>
        <div className="flex gap-3">
          <SButton
            type="secondaryColor"
            className="mb-[2px]"
            children="Cancel"
            onClick={() => setConfirmModal(false)}
          />
          <SButton
            className="mb-[2px]"
            children="Confirm"
            onClick={() => {
              updateLink().then(() => {
                toast("Invite Link Replaced", "success");
                setConfirmModal(false);
              })
              .catch((e) => {
                toast(e.message);
              })
              
            }}
          />
        </div>
      </div>
    </SModalBase>
  );
};
