import React, { useRef, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import { useHandleOutsideClick } from "../../../../hooks/useHandleOutsideClick";
import { isBefore } from "date-fns";

function DatePickerModal({
  setIsDatePickerOpen,
  selected,
  setSelected,
}: {
  setIsDatePickerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelected: React.Dispatch<React.SetStateAction<Date>>;
  selected: Date;
}) {
  const wrapperRef = useRef<any>();
  useHandleOutsideClick({
    ref: wrapperRef,
    callBack: () => {
      setIsDatePickerOpen(false);
    },
    excludedIds: ["schedule-date-picker-opener"],
  });

  return (
    <div
      ref={wrapperRef}
      className="absolute z-[102] bg-base-white border border-gray-200 top-[calc(100%+12px)] left-0 rounded-[8px] shadow-xl3 p-[16px]"
    >
      <DayPicker
        disabled={(date) => date < new Date(new Date().setHours(0, 0, 0, 0))}
        mode="single"
        required={true}
        selected={selected}
        onSelect={setSelected}
      />
    </div>
  );
}

export default DatePickerModal;
