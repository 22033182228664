import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { IBase } from "../../utils/interfaces/IBase";
import { ITokenVM } from "../../utils/interfaces/IToken";

export const useDeleteBrand = () => {
  const queryClient = useQueryClient();
  return useMutation<IBase<ITokenVM>, Error, { brandId: number }>({
    mutationFn: ({ brandId }) =>
      makeRequest.delete(`/Organizations/${brandId}`),
    onSuccess: (res) => {
      localStorage.setItem('token', res.data.data.accessToken);
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentBrand"] });
      queryClient.invalidateQueries({ queryKey: ["useGetAllBrands"] });
    },
  });
};
