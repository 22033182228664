import React, { useState } from "react";
//@ts-ignore
import colorNamer from "color-namer";

function ColorsGrid({
  shades,
  title,
  baseColor,
}: {
  baseColor: string;
  title: string;
  shades: string[];
}) {
  const [isCopied, setIsCopied] = useState(-1);
  const handleCopy = (i: number) => {
    navigator.clipboard.writeText(shades[i]);
    setIsCopied(i);
    setTimeout(() => {
      setIsCopied(-1);
    }, 2000);
  };
  return (
    <div>
      <p className="text-sm-sb text-gray-900">{title}</p>
      <div className="flex gap-[8px] mt-[8px]">
        <div
          style={{ backgroundColor: baseColor }}
          className="px-[16px] py-[10px] w-[60%] rounded-[8px] h-[80px]"
        >
          <p className="text-xs-sb text-base-white">
            {colorNamer(baseColor).ntc[0].name}
          </p>
          <p className="text-xs text-graycool-25">{baseColor}</p>
        </div>
        <div className="w-[40%] h-[80px] flex flex-col gap-[8px]">
          <div className="grid grid-cols-3 gap-[8px] h-[100%]">
            {[1, 2, 3].map((item, i) => (
              <div
                onClick={() => handleCopy(i)}
                style={{
                  backgroundColor:
                    shades[i] !== "#ffffff" ? shades[i] : "#cccccc",
                }}
                className={`w-[100%] h-[100%] cursor-pointer rounded-[4px]  text-graycool-25 text-xs-sb flex justify-center items-center`}
              >
                {isCopied === i ? (
                  <p>Copied</p>
                ) : (
                  <p className="text-xs-sb text-graycool-25">{item}00</p>
                )}
              </div>
            ))}
          </div>
          <div className="grid grid-cols-3 gap-[8px] h-[100%]">
            {[4, 5, 6].map((item, i) => (
              <div
                onClick={() => handleCopy(i + 3)}
                style={{
                  backgroundColor:
                    shades[i + 3] !== "#ffffff" ? shades[i + 3] : "#cccccc",
                }}
                className={`w-[100%] h-[100%] cursor-pointer rounded-[4px]  text-graycool-25 text-xs-sb flex justify-center items-center`}
              >
                {isCopied === i + 3 ? (
                  <p>Copied</p>
                ) : (
                  <p className="text-xs-sb text-graycool-25">{item}00</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ColorsGrid;
