import { SetStateAction, useEffect } from "react";
import { usePostFolder } from "../../../api/library/folders/post";
import { usePostFile } from "../../../api/library/files/post";
import { useUploadFiles } from "../../../hooks/useUploadFiles";
import {
  ISingleFoldersAndFilesVM,
  IUploadFIleVM,
} from "../../../utils/interfaces/IAssets";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { toast } from "../../../hooks/toast";

export const useUploadAssets = ({
  setFoldersAndFiles,
  setPage,
  parentFolderId,
  uploadFileAdditionalFunction,
}: {
  uploadFileAdditionalFunction?: (res: IBaseData<IUploadFIleVM>) => void;
  setFoldersAndFiles: React.Dispatch<
    SetStateAction<ISingleFoldersAndFilesVM[]>
  >;
  setPage: React.Dispatch<SetStateAction<number>>;
  parentFolderId?: number | null;
}) => {
  const { mutateAsync: createFolder } = usePostFolder({
    setFoldersAndFiles,
    setPage,
  });
  const { mutateAsync: createFile, isPending, isSuccess } = usePostFile();
  const { selectFile, files, setFiles } = useUploadFiles();

  useEffect(() => {
    if (files) {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
      createFile({ folderId: parentFolderId, files: formData }).then((res) => {
        setPage(1);
        setFoldersAndFiles([]);
        uploadFileAdditionalFunction && uploadFileAdditionalFunction(res);
      });
    }
  }, [files]);

  return { selectFile, createFolder, isPending, files, createFile, setFiles};
};
