import React from "react";
import {
  format,
  setHours,
  isSameDay,
  subDays,
  addDays,
  endOfDay,
  setMinutes,
} from "date-fns";
import { useCalendarStore } from "../../store/calendarStore";
import { getWeekDays } from "./functions/daysMapping";
import WeekDays from "./week/WeekDays";
import WeekPostCard from "./week/WeekPostCard";
import { useGetCalendarData } from "../../api/calendar/get";
import { useCalendarHelper } from "./hooks/useCalendarHelper";
import { useCreationHelperFunctions } from "../creation/hooks/useCreationHelperFunctions";

const startOfWeek = (date: Date) => subDays(date, (date.getDay() + 6) % 7);
const endOfWeek = (date: Date) => addDays(startOfWeek(date), 6);

export default function WeekView() {
  const { handleOpenPost } = useCreationHelperFunctions();
  const { convertToISOAndChangeTimezone } = useCalendarHelper();
  const { currentDate } = useCalendarStore();
  const days = getWeekDays(currentDate);
  const { data: posts } = useGetCalendarData({
    from: convertToISOAndChangeTimezone(
      new Date(format(startOfWeek(currentDate), "yyyy-MM-dd'T'00:00:00")),
    ),
    to: convertToISOAndChangeTimezone(
      new Date(
        format(addDays(endOfWeek(currentDate), 1), "yyyy-MM-dd'T'00:00:00"),
      ),
    ),
  });

  return (
    <div className="shadow-xs border-[1px] rounded-[8px] overflow-hidden bg-base-white">
      <WeekDays days={days} />
      <div className="flex">
        <div className="w-16 border-r border-gray-100">
          {Array.from({ length: 24 }, (_, hour) => (
            <div
              key={hour}
              className="h-[58px] border-t border-gray-200 py-[8px] px-[6px]"
            >
              <p className="text-xs text-gray-400">
                {format(setMinutes(setHours(new Date(), hour), 0), "HH:mm")}
              </p>
            </div>
          ))}
        </div>
        <div className="flex-1 grid grid-cols-7 divide-x divide-gray-100 min-h-[789px]">
          {Array.from({ length: 24 }, (_, hour) =>
            days.map((day, dayIndex) => (
              <div
                onClick={() => handleOpenPost({})}
                key={`${day.toISOString()}-${hour}`}
                className="cursor-pointer min-h-[58px] max-h-[120px] h-fit py-[8px] border-t border-gray-200 overflow-auto"
              >
                <div className="flex flex-col gap-[6px] px-[6px]">
                  {posts &&
                    posts.data
                      .filter((fPost) => {
                        const postDate = new Date(fPost.releaseDate as string);
                        return (
                          isSameDay(postDate, day) &&
                          postDate.getHours() === hour &&
                          !fPost.isExamplePost
                        );
                      })
                      .map((post) => (
                        <WeekPostCard key={post.id} post={post} />
                      ))}
                </div>
              </div>
            )),
          )}
        </div>
      </div>
    </div>
  );
}
