import React from "react";
//@ts-ignore
import logo from "../../../assets/images/logos/stori-s-square.svg";
import SButton from "../../../design-system/SButton";
import {
  faPlayCircle,
  faPlusCircle,
  faVideo,
} from "@fortawesome/pro-regular-svg-icons";
import { useCreationHelperFunctions } from "../../creation/hooks/useCreationHelperFunctions";
function PostIdea({ idea }: { idea: string }) {
  const { handleOpenPost } = useCreationHelperFunctions();
  return (
    <div className="flex flex-col items-center justify-center w-full h-[224px] rounded-[8px] px-[20px] py-[16px]">
      <img
        className="w-[46px] h-[46px] mb-[12px] max-w-[46px] max-h-[46px]"
        src={logo}
        alt="logo"
      />
      <p className="text-sm-b text-gray-900 text-center">
        Post Idea For Your Brand
      </p>
      <div className="w-full mt-[8px]">
        <p className="text-xs text-gray-600 text-center">{idea}</p>
        <SButton
          onClick={() => {
            handleOpenPost({ postIdea: idea });
          }}
          type="secondaryColor"
          lIcon={faPlusCircle}
          size="md"
          className="w-full mt-[12px]"
        >
          Create Post
        </SButton>
      </div>
    </div>
  );
}

export default PostIdea;
