import { useQuery } from "@tanstack/react-query";
import { IPostsQueryParams, IPostsVM } from "../../utils/interfaces/IPost";
import { IBaseData } from "../../utils/interfaces/IBase";
import { makeRequest } from "../auth/axios";
export const useGetAllPosts = ({
  page,
  pageSize,
  keyword,
  socialNetworkType,
  from,
  to,
  postStatus,
  groupId,
}: IPostsQueryParams) => {
  const isAuthenticated = !!localStorage.getItem("token");
  return useQuery<IBaseData<IPostsVM>>({
    queryKey: [
      "useGetAllCreation",
      page,
      pageSize,
      keyword,
      socialNetworkType,
      from,
      to,
      groupId,
      postStatus,
    ],
    queryFn: async () => {
      let response = null;
      if (keyword) {
        response = await makeRequest.get(
          `/Posts?Page=${page}&PageSize=${pageSize}&Keyword=${keyword}`
        );
      } else if (socialNetworkType) {
        response = await makeRequest.get(
          `/Posts?Page=${page}&PageSize=${pageSize}&SocialNetworkType=${socialNetworkType}`
        );
      } else if (groupId) {
        response = await makeRequest.get(
          `/Posts?Page=${page}&PageSize=${pageSize}&GroupId=${groupId}`
        );
      } else if (postStatus) {
        response = await makeRequest.get(
          `/Posts?Page=${page}&PageSize=${pageSize}&PostStatus=${postStatus}`
        );
      } else if (from && to) {
        response = await makeRequest.get(
          `/Posts?Page=${page}&PageSize=${pageSize}&From=${from}&To=${to}`
        );
      } else {
        response = await makeRequest.get(
          `/Posts?Page=${page}&PageSize=${pageSize}`
        );
      }

      return response as IBaseData<IPostsVM>;
    },
    enabled: !!isAuthenticated,
  });
};
