import React, { useState } from "react";
import SModalBase from "../../design-system/SModalBase";
import SSelectOpener from "../../design-system/SSelectOpener";
import { TeamRole } from "../../utils/enums/Enums";
import SButton from "../../design-system/SButton";
import { useUpdateMemberRole } from "../../api/brand/activeMembers/put";
import { MemberRolesDropdown } from "../brand/MemberRolesDropdown";
import { useRemoveWorkspaceMember } from "../../api/workspaces/users/delete";
import { IWorkspaceUsersVM } from "../../utils/interfaces/IWorkspace";
import { WorkspaceMemberBrands } from "./WorkspaceMemberBrands";
import { useRemoveMemberFromBrands } from "../../api/brand/activeMembers/deleteMany";
import { ISingleBrandVM } from "../../utils/interfaces/IBrand";
import { useAddMemberToMultipleBrands } from "../../api/brand/activeMembers/postMany";
import { toast } from "../../hooks/toast";
import { useInviteByEmail } from "../../api/invitations/invitationByEmail/post";
import { useRespondInvitation } from "../../api/invitations/invitationPending/put";
import { ConfirmDelete } from "../Library/ConfirmDelete";

export interface IWorkspaceEditMemberModalProps {
  setEditModal: (open: boolean) => void;
  editModal: boolean;
  chosenUser?: {
    userId: string;
    name: string;
    email: string;
    role: TeamRole;
    id: number;
  };
  workspaceUsers?: IWorkspaceUsersVM[];
  allOrganizations?: ISingleBrandVM[];
}

export const WorkspaceEditMemberModal = ({
  editModal,
  setEditModal,
  chosenUser,
  workspaceUsers,
  allOrganizations,
}: IWorkspaceEditMemberModalProps) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selected, setSelected] = useState<TeamRole>(chosenUser ? chosenUser?.role : TeamRole.MEMBER);
  const [isOpen, setIsOpen] = useState(false);
  const { mutateAsync: updateRole } = useUpdateMemberRole();
  const { mutateAsync: deleteMember } = useRemoveWorkspaceMember();
  const [removeFromBrands, setRemoveFromBrands] = useState<number[]>([]);
  const [addToBrands, setAddToBrands] = useState<number[]>([]);
  const { mutateAsync: removeFromBrandsRequest } = useRemoveMemberFromBrands();
  const { mutateAsync: addToBrandsRequest } = useAddMemberToMultipleBrands();
  const { mutateAsync: resendInvitation } = useInviteByEmail({
    setError: undefined,
  });
  const { mutateAsync: removeInvitation } = useRespondInvitation();

  const isPending = chosenUser?.email === chosenUser?.userId;

  const userOrganizations = workspaceUsers?.find(
    (workspaceUser) => workspaceUser.userId === chosenUser?.userId,
  );
  const organizations = userOrganizations?.organizationUsers.map(
    (workspaceUser, index) => {
      return (
        <WorkspaceMemberBrands
          setRemoveFromBrands={setRemoveFromBrands}
          key={index}
          workspaceUser={workspaceUser}
          isPending={isPending}
        />
      );
    },
  );
  const notMemberOfOrganizations = allOrganizations
    ?.filter(
      (organization) =>
        !userOrganizations?.organizationUsers.some(
          (orgUser) => orgUser.organizationId === organization.id,
        ),
    )
    .map((org, index) => {
      return (
        <WorkspaceMemberBrands
          setAddToBrands={setAddToBrands}
          key={index}
          organization={org}
          isPending={isPending}
        />
      );
    });
  return (
    <>
      <ConfirmDelete
        setOpenModal={() => setOpenConfirmation(true)}
        onConfirm={() => {
          if (isPending) {
            removeInvitation({
              id: chosenUser ? chosenUser?.id : 0,
              statusId: 4,
            })
              .then(() => {
                toast("Invitation removed successfully", "success");
              })
              .catch((e) => toast(e.response.data.message, "error"));
          } else {
            deleteMember({
              email: chosenUser?.email,
              userId: chosenUser?.userId,
            })
              .then(() => {
                toast("Member removed from workspace", "success");
              })
              .catch((e) => toast(e.response.data.message, "error"));
          }
          setEditModal(false);
          setOpenConfirmation(false);
        }}
        onCancel={() => setOpenConfirmation(false)}
        openModal={openConfirmation}
        title="Are you sure you want to remove this member from workspace?"
      />
      <SModalBase
        dialogPanelClassName="min-w-[544px] z-10 py-4 text-gray-600"
        isOpen={editModal}
        onClose={setEditModal}
      >
        <h1 className="text-md-m mb-3">
          <span className="capitalize">{chosenUser?.name}</span> settings
        </h1>
        <hr className="w-auto mx-[-24px]" />
        <div className="flex w-full flex-col gap-[8px] my-6">
          {!isPending && (
            <>
              <div className="w-full relative flex flex-col gap-[6px]">
                <h1 className="text-gray-700 text-sm-m">Workspace Role</h1>
                <SSelectOpener
                  onOpen={() => setIsOpen((val) => !val)}
                  text={TeamRole[selected][0] + TeamRole[selected].toLowerCase().substring(1)}
                />
                {isOpen && (
                  <MemberRolesDropdown
                    className="w-full absolute z-10 top-[80%] left-0"
                    setSelected={setSelected}
                    selected={selected}
                    setIsOpen={setIsOpen}
                  />
                )}
              </div>
              <h1 className="text-gray-600 text-sm">
                More about STORI roles.{" "}
                <span className="text-sm-m text-brand-600">
                  <a href="https://develop.storiai.com" target="__blank">
                    See Here
                  </a>
                </span>
              </h1>
            </>
          )}
          <div className="flex flex-col gap-[6px] mt-1">
            <h1 className="text-gray-700 text-sm-m">Brand Access</h1>
            <div className="w-full flex flex-col rounded-xl border h-[200px] overflow-auto border-gray-200 px-4">
              {organizations}
              {notMemberOfOrganizations}
            </div>
          </div>
          <div className="flex items-center mt-[18px] justify-between">
            <SButton
              onClick={() => {
                setOpenConfirmation(true);
              }}
              className="bg-error-600 border-error-600 hover:bg-error-700 hover:border-error-700"
              size="sm"
              children="Remove from workspace"
            />
            <div className="flex gap-3 items-center">
              <SButton
                onClick={() => {
                  setRemoveFromBrands([]);
                  setEditModal(false);
                }}
                type="secondaryGray"
                size="md"
                children="Cancel"
              />
              <SButton
                onClick={() => {
                  if (isPending) {
                    resendInvitation({
                      email: chosenUser ? chosenUser?.email : "",
                      role: chosenUser ? chosenUser?.role : TeamRole["MEMBER"],
                      organizationIds: userOrganizations?.organizationUsers.map(
                        (org) => org.organizationId,
                      ),
                    })
                      .then(() => {
                        toast("Resending Invite", "success");
                      })
                      .catch((e) => toast(e.response.data.message, "error"));
                  }
                  if (!isPending) {
                    updateRole({
                      userId: chosenUser?.userId ? chosenUser?.userId : "",
                      role: selected as TeamRole,
                    })
                      .then(() => {
                        toast("Role updated successfully", "success");
                      })
                      .catch((e) => toast(e.response.data.message, "error"));
                  }
                  if (!isPending && removeFromBrands.length > 0) {
                    removeFromBrandsRequest({
                      userId: chosenUser?.userId,
                      organizationIds: removeFromBrands,
                    })
                      .then(() => {
                        toast("User removed from the brand", "success");
                      })
                      .catch((e) => toast(e.response.data.message, "error"));
                  }
                  if (!isPending && addToBrands.length > 0) {
                    addToBrandsRequest({
                      userId: chosenUser?.userId,
                      organizationIds: addToBrands,
                    })
                      .then(() => {
                        toast(
                          "User added to the brand(s) successfully",
                          "success",
                        );
                      })
                      .catch((e) => toast(e.response.data.message, "error"));
                  }
                  setEditModal(false);
                }}
                type="primary"
                size="md"
                children={isPending ? "Resend" : "Save"}
              />
            </div>
          </div>
        </div>
      </SModalBase>
    </>
  );
};
