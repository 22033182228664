import { faMicrophone, faStop } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { toast } from "react-toastify";
import SButton from "../../../design-system/SButton";

function Textarea({
  value,
  setValue,
  sendMessage,
  disable,
}: {
  disable: boolean;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  sendMessage: (t: string) => void;
}) {
  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  useEffect(() => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      toast(
        "Your browser does not support speech recognition. Please try a different browser."
      );
    }
  }, []);
  const [lastState, setLastState] = useState("");
  useEffect(() => {
    if (listening) setValue(transcript);
  }, [transcript]);

  return (
    <div className="relative mt-[32px] max-h-[64px] h-full">
      <textarea
        id="create-brand-chat-textarea"
        disabled={disable}
        placeholder="Send a message..."
        className="focus-visible:outline-[0] focus:border-gray-300 pr-[110px] focus:ring-0  w-full text-md bg-base-white border h-full border-gray-300 shadow-xs rounded-[8px] resize-none"
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setValue(e.target.value);
          // handleChange(e);
        }}
        value={listening ? lastState + " " + value : value}
        onKeyDown={(e) => {
          if (e.shiftKey && e.key === "Enter") {
            return null;
          } else if (e.code == "Enter" && value && value.length > 0) {
            e.preventDefault();
            sendMessage(value);
          }
        }}
      />
      <div className="absolute bottom-[12px] right-[12px] flex gap-[6px]">
        <div
          onClick={() => {
            if (listening) {
              if (lastState.length > 0) setValue(lastState + " " + value);
              setLastState("");
              SpeechRecognition.stopListening();
              resetTranscript();
            } else {
              resetTranscript();
              setLastState(value);
              setValue("");
              SpeechRecognition.startListening({ continuous: true });
            }
          }}
          className={`
            ${listening ? "bg-red-200 text-red-600" : "bg-base-white text-gray-500"}
            w-[40px] h-[40px] rounded-[8px] flex justify-center items-center bg-base-white cursor-pointer
          `}
        >
          <FontAwesomeIcon
            beat={listening}
            icon={listening ? faStop : faMicrophone}
          />
        </div>
        <SButton
          disabled={disable}
          size="md"
          onClick={() => {
            if (value.length > 0) {
              sendMessage(value);
            }
          }}
        >
          Send
        </SButton>
      </div>
    </div>
  );
}

export default Textarea;
