import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import { faDownload, faPencil, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { Dispatch, SetStateAction } from "react";
import SSmallMenu from "../../design-system/SSmallMenu";

export interface ILibraryOptionsButton {
  hasOptions: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  dropdownRef: any;
  handleRename: (e: any) => void;
  handleDelete: (e: any) => void;
  handleDownload: (e: any, url: string, name: string) => void;
  cardTitle: string;
  imageSrc?: string;
  cardType: string;
}

export const LibraryOptionsButton = ({
  hasOptions,
  setOpen,
  open,
  dropdownRef,
  handleRename,
  handleDelete,
  handleDownload,
  cardType,
  imageSrc,
  cardTitle,
}: ILibraryOptionsButton) => {
  let buttonId = `library-menu-opener-${Math.random()}`;
  return (
    <>
      {hasOptions && (
        <button
          id={buttonId}
          ref={dropdownRef}
          onDoubleClick={(e) => e.stopPropagation()}
          onClick={(e) => {
            setOpen((value) => !value);
            e.stopPropagation();
          }}
          className={`${open ? "inline" : "hidden"} bg-transparent border-0 p-[8px]`}
        >
          <FontAwesomeIcon
            icon={faEllipsisVertical}
            className="text-[16px] text-gray-600"
          />
          {open && cardType !== 'Folder' && (
            <SSmallMenu
              isOpen={open}
              setIsOpen={(p) => setOpen(p)}
              excludedId={buttonId}
              className="right-[16px] left-[unset] top-[calc(100%-24px)]"
              data={[
                [
                  {
                    text: "Download",
                    icon: faDownload,
                    handleClick: (e) => handleDownload(e, imageSrc ? imageSrc : '', cardTitle),
                  },
                  {
                    text: "Rename",
                    icon: faPencil,
                    handleClick: (e) => handleRename(e),
                  },
                  {
                    text: "Delete",
                    icon: faTrash,
                    handleClick: (e) => handleDelete(e),
                    color: "text-error-600",
                  },
                ],
              ]}
            />
          )}
          {open && cardType === 'Folder' && (
            <SSmallMenu
              isOpen={open}
              setIsOpen={(p) => setOpen(p)}
              excludedId={buttonId}
              className="right-[16px] left-[unset] top-[calc(100%-24px)]"
              data={[
                [
                  {
                    text: "Rename",
                    icon: faPencil,
                    handleClick: (e) => handleRename(e),
                  },
                  {
                    text: "Delete",
                    icon: faTrash,
                    handleClick: (e) => handleDelete(e),
                    color: "text-error-600",
                  },
                ],
              ]}
            />
          )}
        </button>
      )}
    </>
  );
};
// onClick={(e) => handleRename(e)}
