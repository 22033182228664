import React from "react";
import PageHeading from "../components/PageHeading";
import IntegrationCard from "../components/integrations/IntegrationCard";
//@ts-ignore
import dropboxLogo from "../assets/images/integrations-logo/dropbox.svg";
//@ts-ignore
import jiraLogo from "../assets/images/integrations-logo/jira.svg";
//@ts-ignore
import linearLogo from "../assets/images/integrations-logo/linear.svg";
//@ts-ignore
import slackLogo from "../assets/images/integrations-logo/slack.svg";
//@ts-ignore
import githubLogo from "../assets/images/integrations-logo/github.svg";
//@ts-ignore
import zendeskLogo from "../assets/images/integrations-logo/zendesk.svg";
//@ts-ignore
import notionLogo from "../assets/images/integrations-logo/notion.svg";
//@ts-ignore
import zapierLogo from "../assets/images/integrations-logo/zapier.svg";
function Integrations() {
  return (
    <div className="w-full py-[24px]">
      <PageHeading
        title="Integrations and connected apps"
        subtitle="Supercharge your workflow and connect the tool you use every day."
      />
      <div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-[12px] mt-[32px] max-md:grid-cols-1">
        <IntegrationCard
          name="Dropbox"
          logo={dropboxLogo}
          description="Upload your Dropbox marketing materials to STORI easily."
          isToggled={false}
        />
        <IntegrationCard
          name="Slack"
          logo={slackLogo}
          description="Manage your team on STORI from slack, talk to simulated customers."
          isToggled={false}
        />
        <IntegrationCard
          name="Atalassian JIRA"
          logo={jiraLogo}
          description="Tell more of your STORI by looking at your accomplished goals on JIRA."
          isToggled={false}
        />
        {/*<IntegrationCard*/}
        {/*  name="Zendesk"*/}
        {/*  logo={zendeskLogo}*/}
        {/*  description="Link and automate Zendesk tickets."*/}
        {/*  isToggled={false}*/}
        {/*/>*/}
        <IntegrationCard
          name="Github"
          logo={githubLogo}
          description="Track updates and don't forget to post about them."
          isToggled={false}
        />
        <IntegrationCard
          name="Notion"
          logo={notionLogo}
          description="Manage Notion marketing campaigns easier."
          isToggled={false}
        />
        <IntegrationCard
          name="Zapier"
          logo={zapierLogo}
          description="Automate STORI workflows with Zapier."
          isToggled={false}
        />
        <IntegrationCard
          name="Linear"
          logo={linearLogo}
          description="Helps you create narrative from your product progress and tasks."
          isToggled={false}
        />
      </div>
    </div>
  );
}

export default Integrations;
