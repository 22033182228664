import React, { useState, useEffect, useRef } from "react";

const TypewriterEffect = () => {
  const words = [
    "I'm your copilot",
    "How can I help you?",
    "I'm here to assist you",
    "What is my brand tagline?",
  ];
  const [text, setText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(150);
  const [isPaused, setIsPaused] = useState(false);
  const typingRef = useRef<any>(null);

  const typingDelay = 10000;

  useEffect(() => {
    typingRef.current = typeNow;
    if (!isPaused) {
      const handle = setTimeout(() => {
        typingRef.current();
      }, typingSpeed);

      return () => clearTimeout(handle);
    }
  }, [text, isDeleting, typingSpeed, isPaused]);

  const typeNow = () => {
    const i = loopNum % words.length;
    const fullText = words[i];

    if (!isPaused) {
      setText(
        isDeleting
          ? fullText.substring(0, text.length - 1)
          : fullText.substring(0, text.length + 1),
      );

      setTypingSpeed(isDeleting ? 50 : 150);

      if (!isDeleting && text === fullText) {
        setIsPaused(true);
        setTimeout(() => {
          setIsPaused(false);
          setIsDeleting(true);
        }, typingDelay);
      } else if (isDeleting && text === "") {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
        setTypingSpeed(150);
      }
    }
  };

  return (
    <div className="bg-transparent">
      <style>
        {`
          @keyframes type {
            to { opacity: .0; }
          }
        `}
      </style>
      <div className="flex">
        <p className="text-sm text-gray-500 md:block max-md:hidden">{text}</p>
        <p className="text-sm text-gray-500 md:hidden max-md:block">
          Ask Assistant
        </p>
        <p className="text-sm text-gray-500 max-md:hidden">|</p>
      </div>
    </div>
  );
};

export default TypewriterEffect;
