import React, { useEffect, useRef, useState } from "react";
import SInput from "../../../design-system/SInput";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import STertiaryTabs from "../../../design-system/STertiaryTabs";
import LibraryTab from "./Media/LibraryTab";
import { useCreationStore } from "../../../store/creationStore";
import HelperInput from "./Media/HelperInput/HelperInput";
import { LibraryDropdown } from "../../Library/LibraryDropdown";
// @ts-ignore
import DropboxChooser from "react-dropbox-chooser";
import { useUploadAssets } from "../../Library/hooks/useUploadAssets";
import { useLibraryDropbox } from "../../Library/hooks/useLibraryDropbox";
import { useFolderHistory } from "../../Library/hooks/useFolderHistory";
import { LibraryCreateFolderModal } from "../../Library/LibraryCreateFolderModal";
import { useCreationLibraryHelperFunctions } from "./Media/hooks/useCreationLibraryHelperFunctions";
import { toast } from "../../../hooks/toast";
function Media() {
  const wrapperRef = useRef<any>();
  const { librarySearchValue } = useCreationStore();
  const {
    folderHistory,
    foldersAndFiles,
    setPage,
    setFoldersAndFiles,
    setFolderHistory,
    parentFolderId,
    setParentFolder,
    isFetching,
  } = useFolderHistory({
    wrapperRef,
    searchValue: librarySearchValue.length > 0 ? librarySearchValue : undefined,
  });
  const [folderName, setFolderName] = useState("");
  const { selectUploadedMedia } = useCreationLibraryHelperFunctions();
  const { selectFile, createFolder, isPending, files, createFile } =
    useUploadAssets({
      setFoldersAndFiles,
      setPage,
      parentFolderId,
      uploadFileAdditionalFunction: (res) => selectUploadedMedia(res),
    });
  const { dropboxRef, onDropboxSelectSubmited, dropboxFiles } =
    useLibraryDropbox({
      createFile,
      parentFolderId,
      isPending,
      setFoldersAndFiles,
      setPage,
      uploadFileAdditionalFunction: (res) => selectUploadedMedia(res),
    });

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <LibraryCreateFolderModal
        openModal={openModal}
        action="Create"
        type="Folder"
        setOpenModal={() => setOpenModal(false)}
        name={folderName}
        setName={(e) => setFolderName(e.target!.value)}
        onSave={() => {
          createFolder({ name: folderName, parentFolderId }).catch((err) => {
            toast(
              err?.response?.data?.message ||
                "An error occurred while creating the folder"
            );
          });
          setOpenModal(false);
          setFolderName("");
        }}
      />
      <div style={{ height: "inherit" }} className="relative">
        <div className="min-h-[calc(46px+17px)] flex justify-end items-center border-b-[1px] border-b-graycool-100 pb-[16px]">
          <HelperInput />
          <LibraryDropdown
            btnClassName="relative z-[10]"
            handleFiles={() => selectFile({ multiple: true })}
            handleFolder={() => setOpenModal(true)}
            handleDropbox={() => dropboxRef.current?.click()}
          />
          <DropboxChooser
            appKey="js3i72s02ssirnl"
            success={(files: any) => {
              onDropboxSelectSubmited(files);
            }}
            extensions={[
              ".jpg",
              ".jpeg",
              ".png",
              ".mp4",
              ".mov",
              ".avi",
              ".wmv",
              ".flv",
              ".webm",
              ".pdf",
              ".docx",
              ".doc",
              ".xlsx",
              ".xls",
              ".txt",
            ]}
            multiselect={true}
          >
            <input ref={dropboxRef} className="hidden" />
          </DropboxChooser>
        </div>
        <LibraryTab
          wrapperRef={wrapperRef}
          isFetching={isFetching}
          setParentFolder={setParentFolder}
          setFolderHistory={setFolderHistory}
          setFoldersAndFiles={setFoldersAndFiles}
          setPage={setPage}
          folderHistory={folderHistory}
          foldersAndFiles={foldersAndFiles}
        />
      </div>
    </>
  );
}

export default Media;
