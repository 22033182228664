import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ITokenVM } from "../../../utils/interfaces/IToken";
import { makeRequest } from "../../auth/axios";
import { useContentStore } from "../../../store/contentStore";
import { useCopilotStore } from "../../../store/copilotStore";
import { toast } from "react-toastify";
import { useFolderHistory } from "../../../components/Library/hooks/useFolderHistory";

export const usePutSwitchBrand = () => {
  const queryClient = useQueryClient();
  const { reset: resetCopilotStore } = useCopilotStore();
  const { reset: resetPosts, setPosts } = useContentStore();

  return useMutation<IBaseData<ITokenVM>, Error, { id?: number | null }>({
    mutationFn: ({ id }) =>
      makeRequest.put(
        `/Auth/switch-organization${id ? `?organizationId=${id}` : ""}`
      ),
    onSuccess: async (res) => {
      localStorage.setItem("token", res.data.accessToken);
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentBrand"] });
      resetCopilotStore();
      resetPosts();
      setPosts([]);
      queryClient.invalidateQueries({ queryKey: ["useGetAllCreation"] });
      queryClient.invalidateQueries({ queryKey: ["useGetActiveMembers"] });
      queryClient.invalidateQueries({ queryKey: ["useGetInvitationPending"] });
      queryClient.invalidateQueries({ queryKey: ["useGetAllConnections"] });
      queryClient.invalidateQueries({ queryKey: ["useGetFoldersAndFiles"] });
      queryClient.invalidateQueries({ queryKey: ["useGetCalendarData"] });
      queryClient.invalidateQueries({ queryKey: ["useGetOnboardingInfo"] });
      await queryClient.invalidateQueries({
        queryKey: ["useGetCopilotSessions"],
      });
      //   queryClient.invalidateQueries({ queryKey: ['useGetCurrentBrand'] }).then((r) => null);
      //   queryClient.invalidateQueries({ queryKey: ['useGetAllCreation'] });
      //   queryClient.invalidateQueries({ queryKey: ['useGetUser'] });
      //   queryClient.invalidateQueries({ queryKey: ['useGetAllConnections'] }).then((r) => null);
      //   queryClient.invalidateQueries({ queryKey: ['useGetAnalytic'] }).then((r) => null);
      //   queryClient.invalidateQueries({ queryKey: ['useGetFolders'] }).then((r) => null);
      //   queryClient.invalidateQueries({ queryKey: ['useGetCopilotSessions'] }).then((r) => null);
      //   queryClient.invalidateQueries({ queryKey: ['useGetScheduledPosts'] }).then((r) => null);
      //   queryClient.invalidateQueries({ queryKey: ['useGetBrandingPost'] }).then((r) => null);
      //   queryClient.invalidateQueries({ queryKey: ['useGetTeamComments'] }).then((r) => null);
    },
  });
};
