import { useEffect } from "react";
import { useGetCopilotChat } from "../../../api/copilot/chat/get";
import { useCopilotStore } from "../../../store/copilotStore";
import { ICopilotMessage } from "../../../utils/interfaces/ICopilot";
import { useGetCopilotSession } from "../../../api/copilot/session/get";
import { CopilotType } from "../../../utils/enums/Enums";

export const useFillChat = () => {
  const {
    data: sessions,
    refetch,
    isFetched,
  } = useGetCopilotSession({
    type: CopilotType.DEFAULT,
  });

  const {
    messages,
    setMessages,
    activeSession,
    chatResponse,
    setActiveSession,
    setChatResponse,
  } = useCopilotStore();
  const { refetch: refetchCopilotMessages } = useGetCopilotChat({
    sessionId: activeSession?.id,
  });
  useEffect(() => {
    if (!activeSession) {
      refetch().then((res) => {
        setChatResponse("");
        //@ts-ignore
        setActiveSession(res.data?.data[0]);
      });
    }
    // if (!activeSession && sessions && sessions.data.length > 0 && isFetched) {
    //   setActiveSession(sessions.data[0]);
    // }
  }, [sessions, activeSession, isFetched]);

  useEffect(() => {
    if (activeSession) {
      refetchCopilotMessages().then((res) => {
        let tmp: ICopilotMessage[] = [];
        // @ts-ignore
        res!.data!.data.map((item) => {
          tmp.push({
            role: item.role,
            content: item.content,
            isActive: true,
            isSuccess: false,
          });
        });
        setMessages(tmp);
      });
    }
  }, [activeSession]);
  useEffect(() => {
    if (
      chatResponse &&
      chatResponse.length > 0 &&
      messages.length > 0 &&
      messages[messages.length - 1].role === 2
    ) {
      let tmp = [...messages];
      tmp[tmp.length - 1].content = chatResponse;
      setMessages(tmp);
    }
  }, [chatResponse]);
};
