import React from "react";

function UserMessage({ text }: { text: string }) {
  return (
    <div className="flex justify-end">
      <div className="flex flex-col gap-[6px]">
        <p className="text-sm-m text-gray-700 text-right">You</p>
        <p className="max-w-[390px] w-full text-md text-base-white rounded-[8px] rounded-tr-none py-[10px] px-[14px] bg-brand-600">
          {text}
        </p>
      </div>
    </div>
  );
}

export default UserMessage;
