import React, { useEffect, useState } from "react";
import Platform, {
  IPlaformPageItem,
} from "../../components/platforms/Platform";
import { SocialNetworkType } from "../../utils/enums/Enums";
import { useGetAllConnections } from "../../api/connections/get";
import { useChannelsHelper } from "../../components/creation/Tabs/Channels/functions/useChannelsHelper";
import { usePostConnectPage } from "../../api/connections/connect/post";
import { idText } from "typescript";
import { useDeleteConnection } from "../../api/connections/delete";
import PageHeading from "../../components/PageHeading";
import { toast } from "../../hooks/toast";

function BrandConnections() {
  const { data: connections } = useGetAllConnections();
  const [connectPageLoading, setConnectPageLoading] = useState<null | number>(
    null
  );
  const { mutateAsync: connectPage, isPending: isConnectPageLoading } =
    usePostConnectPage();
  const { mutateAsync: deleteConnection } = useDeleteConnection();
  let mappedData = connections
    ? connections?.data.map(
        (item) =>
          ({
            id: item.id,
            logo: item.logoUrl,
            socialNetworkType: item.socialNetworkType,
            title: item.name,
          }) as IPlaformPageItem
      )
    : undefined;

  const handleConnect = (socialNetworkType: SocialNetworkType) => {
    setConnectPageLoading(socialNetworkType);
    connectPage({ socialNetworkType })
      .then((res) => {
        window.location.href = res.data;
        // window.open(res.data, "_blank");
      })
      .catch((err) => toast(err?.response?.data?.message))
      .finally(() => {
        setConnectPageLoading(null);
      });
  };
  const handleDelete = (id: number) => {
    deleteConnection({ id });
  };

  return (
    <>
      <div className="grid grid-cols-2 mt-[20px] gap-[16px] mb-[40px] max-md:grid-cols-1">
        {mappedData && (
          <>
            <Platform
              handleDelete={handleDelete}
              isLoading={connectPageLoading}
              pages={mappedData.filter(
                (item) => item.socialNetworkType === SocialNetworkType.TWITTER
              )}
              handleConnect={() => handleConnect(SocialNetworkType.TWITTER)}
              socialNetworkType={SocialNetworkType.TWITTER}
            />{" "}
            <Platform
              handleDelete={handleDelete}
              isLoading={connectPageLoading}
              pages={mappedData.filter(
                (item) => item.socialNetworkType === SocialNetworkType.LINKEDIN
              )}
              handleConnect={() => handleConnect(SocialNetworkType.LINKEDIN)}
              socialNetworkType={SocialNetworkType.LINKEDIN}
            />{" "}
            <Platform
              handleDelete={handleDelete}
              isLoading={connectPageLoading}
              handleConnect={() => handleConnect(SocialNetworkType.INSTAGRAM)}
              pages={mappedData.filter(
                (item) => item.socialNetworkType === SocialNetworkType.INSTAGRAM
              )}
              socialNetworkType={SocialNetworkType.INSTAGRAM}
            />{" "}
            <Platform
              handleDelete={handleDelete}
              isLoading={connectPageLoading}
              pages={mappedData.filter(
                (item) => item.socialNetworkType === SocialNetworkType.PINTEREST
              )}
              handleConnect={() => handleConnect(SocialNetworkType.PINTEREST)}
              socialNetworkType={SocialNetworkType.PINTEREST}
            />
            <Platform
              handleDelete={handleDelete}
              isLoading={connectPageLoading}
              handleConnect={() => handleConnect(SocialNetworkType.FACEBOOK)}
              pages={mappedData.filter(
                (item) => item.socialNetworkType === SocialNetworkType.FACEBOOK
              )}
              socialNetworkType={SocialNetworkType.FACEBOOK}
            />
            <Platform
              handleDelete={handleDelete}
              isLoading={connectPageLoading}
              handleConnect={() => handleConnect(SocialNetworkType.REDIT)}
              pages={mappedData.filter(
                (item) => item.socialNetworkType === SocialNetworkType.REDIT
              )}
              socialNetworkType={SocialNetworkType.REDIT}
            />
          </>
        )}
      </div>
    </>
  );
}

export default BrandConnections;
