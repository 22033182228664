import React, { useState } from "react";
import { faBell } from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../design-system/SButton";
import NotificationsTabInHeader from "./NotificationsTabInHeader";
import { useValueByType } from "../notifications/hooks/useValueByType";

const NotificationsInHeader = ({ ...props }) => {
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const { getValue } = useValueByType();
  return (
    <>
      <SButton
        onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
        icon={faBell}
        size="sm"
        type="secondaryGray"
        id={"header__notifications-opener"}
      />
      {isNotificationsOpen && (
        <NotificationsTabInHeader
          isNotificationsOpen={isNotificationsOpen}
          setIsNotificationsOpen={setIsNotificationsOpen}
          getValue={getValue}
        />
      )}
    </>
  );
};

export default NotificationsInHeader;
