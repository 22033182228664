import React from "react";
import { isImage } from "../../../../Library/hooks/getFileType";

interface IImageView extends React.HTMLAttributes<HTMLDivElement> {
  source: string;
  isSelected: boolean;
}

function ImageView({ source, isSelected, ...props }: IImageView) {
  return (
    <div
      className={`
        ${isSelected && "border-[4px] border-brand-600"}
        w-full cursor-pointer h-[240px] lg:h-[260px] 2xl:h-[180px] rounded-[8px]`}
      {...props}
    >
      {isImage(source) ? (
        <img
          className="w-full h-full object-cover rounded-[8px]"
          src={source}
          alt=""
        />
      ) : (
        <video className="w-full h-full object-contain">
          <source src={source} />
        </video>
      )}
    </div>
  );
}

export default ImageView;
