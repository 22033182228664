import React from "react";
//@ts-ignore
import signInImage from "../assets/images/sign-in-image.jpg";
import Form from "../components/auth/login/Form";
function Signin() {
  return (
    <div className="flex h-[100dvh] justify-center items-center max-md:p-[16px]">
      <Form />
    </div>
  );
}

export default Signin;
