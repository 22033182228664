import { ILibraryProps } from "../../../pages/Library";
import { ISingleFoldersAndFilesVM } from "../../../utils/interfaces/IAssets";
import { determineCardType } from "./determineCardType";
import { removeExtension } from "./removeExtension";
import { truncateName } from "./truncateName";

export const renderItems = (foldersAndFiles?: ISingleFoldersAndFilesVM[]) => {
  const items: ILibraryProps[] = foldersAndFiles
    ? foldersAndFiles.map((item: ISingleFoldersAndFilesVM) => ({
        id: item.id,
        cardType: item.extension
          ? determineCardType(item?.extension)
          : "Folder",
        cardTitle: item.extension
          ? truncateName(item.name, item.extension)
          : truncateName(item.name),
        rawTitle:  item.extension ? removeExtension(item.name, item.extension) : item.name,
        author: item.createdBy,
        authorImg: item.url,
        imageSrc: item.url,
        createdAt: item.created,
        object: { ...item },
        fileSize: item.size,
        filesCount: item.extension ? undefined : item.filesCount,
        extension: item.extension ? item.extension : undefined,
        pathInStorage: item.pathInStorage ? item.pathInStorage : undefined,
        onDelete: () => console.log("Delete button clicked for Document.pdf"),
        onEdit: () => console.log("Edit button clicked for Document.pdf"),
      }))
    : [];
  return items;
};
