import { useQuery } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";
import { IFoldersAndFilesVM } from "../../../utils/interfaces/IAssets";

export const useGetFoldersAndFiles = ({
  page,
  pageSize,
  keyword,
  fileType,
  generatedOnly = false,
  uploadedOnly = false,
  parentFolderId,
  enabled = false,
}: {
  page: number;
  pageSize: number;
  keyword?: string;
  fileType?: string;
  generatedOnly?: boolean;
  uploadedOnly?: boolean;
  parentFolderId?: null | number;
  enabled?: boolean;
}) => {
  return useQuery<IBaseData<IFoldersAndFilesVM>>({
    queryKey: [
      "useGetFoldersAndFiles",
      page,
      keyword,
      fileType,
      generatedOnly,
      uploadedOnly,
      parentFolderId,
    ],
    queryFn: async () => {
      let endpoint = `/FoldersAndFiles?Page=${page}&PageSize=${pageSize}`;

      if (keyword) endpoint += `&Keyword=${keyword}`;
      if (uploadedOnly) endpoint += `&UploadedOnly=${uploadedOnly}`;
      if (generatedOnly) endpoint += `&GeneratedOnly=${generatedOnly}`;
      if (fileType) endpoint += `&FileType=${fileType}`;
      if (parentFolderId) endpoint += `&ParentFolderId=${parentFolderId}`;

      const response = await makeRequest.get(endpoint);
      return response as IBaseData<IFoldersAndFilesVM>;
    },
    // enabled: enabled,
  });
};
