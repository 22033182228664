import { useEffect } from "react";
import { usePostCopilotSession } from "../../../api/copilot/session/post";
import { toast } from "../../../hooks/toast";
import { useCopilotStore } from "../../../store/copilotStore";
import { CopilotType } from "../../../utils/enums/Enums";
import { IBaseData } from "../../../utils/interfaces/IBase";
import {
  ICopilotMessage,
  ICopilotSessionVM,
} from "../../../utils/interfaces/ICopilot";
import { copilotChatMessageResponse } from "../../creation/Tabs/Brief/hooks/copilotChatMessageResponse";

export const useCopilotHelper = () => {
  const {
    messages,
    setMessages,
    setChatResponse,
    activeSession,
    setIsInputDisabled,
    setActiveSession,
    setIsWriting,
  } = useCopilotStore();
  const { mutateAsync: createCopilotSession, isPending } =
    usePostCopilotSession();

  const fetchStream = async ({ tmpValue }: { tmpValue: string }) => {
    setIsWriting(true);
    const response = await copilotChatMessageResponse(
      tmpValue,
      activeSession!.id
    );
    const reader = response.body?.getReader();
    let buffer = "";

    const sleep = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    const typeText = async (text: string) => {
      const groupSize = getGroupSize(text.length);
      const typingDelay = buffer.includes("```json") ? 0 : 75;

      for (let i = 0; i < text.length; i += groupSize) {
        buffer += text.slice(i, i + groupSize);
        await setChatResponseAsync(buffer);
        await sleep(typingDelay);
      }
    };

    const setChatResponseAsync = (data: string) => {
      return new Promise((resolve) => {
        setChatResponse(data);
        resolve(data);
      });
    };

    const getGroupSize = (length: number) => {
      if (length <= 100) return 3;
      if (length <= 200) return 10;
      if (length <= 300) return 20;
      return 30;
    };

    while (reader) {
      const { value, done } = await reader.read();

      if (done) {
        setIsWriting(false);
        setIsInputDisabled(false);
        break;
      }

      const chunkText = new TextDecoder("utf-8")
        .decode(value)
        .replace(/undefined/g, "");
      await typeText(chunkText);
    }
  };

  const sendMessage = async ({
    value,
    setValue,
  }: {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
  }) => {
    let msgsArr: ICopilotMessage[] = [...messages];
    if (value && value.length > 0) {
      setIsInputDisabled(true);
      //   setCurrentRows(minRows);
      let tmpValue = value;
      setChatResponse("");
      let tmpUser: ICopilotMessage = {
        role: 0,
        content: tmpValue,
        isActive: true,
        isSuccess: false,
      };
      let tmpAi: ICopilotMessage = {
        role: 2,
        content: "",
        isActive: true,
        isSuccess: false,
      };
      msgsArr.push(tmpUser);
      msgsArr.push(tmpAi);
      setMessages(msgsArr);
      await fetchStream({ tmpValue });
    }
  };

  const handleCreateCopilotSession =
    async (): Promise<IBaseData<ICopilotSessionVM> | void> => {
      try {
        return await createCopilotSession({ type: CopilotType.DEFAULT }).then(
          (res) => {
            setMessages([]);
            setActiveSession(res.data);
          }
        );
      } catch (err) {
        //@ts-ignore
        toast(err?.response?.data?.message);
        throw err;
      }
    };
  return { sendMessage, handleCreateCopilotSession };
};
