import React, { useEffect, useRef, useState } from "react";
//@ts-ignore
import bgSvg from "../../../assets/images/creation-background.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { useCreationStore } from "../../../store/creationStore";
import { SocialNetworkType } from "../../../utils/enums/Enums";
import DefaultPreview from "./DefaultPreview";
import SocMediaSwitcher from "./SocMediaSwitcher";
import { useCheckHasScroll } from "../../../hooks/useCheckHasScroll";
import { useIsHovered } from "../../../hooks/useIsHovered";
import { useUpdatePost } from "../hooks/useUpdatePost";
import CommentsModal from "../CommentsModal";
function PreviewSide({
  isCommentsOpen,
  setIsCommentsOpen,
}: {
  isCommentsOpen: boolean;
  setIsCommentsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const previewSideRef = useRef<any>();
  const { hasScroll } = useCheckHasScroll(previewSideRef);
  const { isHovered } = useIsHovered(previewSideRef);
  return (
    <div
      ref={previewSideRef}
      className="
      w-[40%]
      h-full block bg-graycool-50 relative rounded-tr-[24px] items-center justify-center hide__scroll overflow-auto max-md:w-full max-md:rounded-t-[24px]"
      style={{
        boxShadow: "2px 4px 12px 0px rgba(0, 0, 0, 0.04) inset",
        backgroundImage: `url(${bgSvg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className={`
          ${!hasScroll && "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-full z-10"} 
          py-[62px]
          flex items-center flex-col`}
      >
        <SocMediaSwitcher />
        <DefaultPreview isHovered={isHovered} />
      </div>
    </div>
  );
}

export default PreviewSide;
