import { faAdd, faUserPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import SButton from "../../../design-system/SButton";

function InviteMemberTexts({ handleInvite }: { handleInvite: () => void }) {
  return (
    <div className="flex flex-col items-center absolute top-[50%] translate-y-[-50%] w-[300px]">
      <div className="flex justify-center bg-base-white items-center rounded-full shadow-sm min-w-[64px] min-h-[64px] max-w-[64px] max-h-[64px]">
        <FontAwesomeIcon
          icon={faUserPlus}
          className="text-[18px] text-gray-400"
        />
      </div>
      <p className="mt-[20px] text-md-sb text-gray-900">
        Invite your first user
      </p>
      <p className="mt-[4px] text-sm text-gray-500 text-center">
        Add team members and external users
      </p>
      <SButton
        onClick={() => handleInvite()}
        lIcon={faAdd}
        className="mt-[16px]"
      >
        Invite user
      </SButton>
    </div>
  );
}

export default InviteMemberTexts;
