import React, { useEffect, useState } from "react";
import PageHeading from "../components/PageHeading";
import { useNavigate } from "react-router-dom";
import { ITabItem } from "../design-system/STertiaryTabs";
import PostList from "../components/content/PostList";
import useScrollStatus from "../hooks/useScrollStatus";
import { ISinglePostVM } from "../utils/interfaces/IPost";
import { useGetAllPosts } from "../api/posts/getAll";
import { useContentStore } from "../store/contentStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/pro-regular-svg-icons";
import SButton from "../design-system/SButton";
import { useCreationHelperFunctions } from "../components/creation/hooks/useCreationHelperFunctions";

function Content() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(0);
  const tabItems: ITabItem[] = [
    {
      index: 0,
      text: "All",
    },
    {
      index: 1,
      text: "Drafts",
    },
    {
      index: 2,
      text: "Published",
    },
    {
      index: 4,
      text: "Scheduled",
    },
    {
      index: 3,
      text: "Reminders",
    },
  ];
  const [searchValue, setSearchValue] = useState("");
  const { posts, setPosts, page, pageSize, setPage } = useContentStore();
  const { data, isFetching } = useGetAllPosts({
    page,
    pageSize: 30,
    postStatus: selected === 0 ? undefined : selected,
    keyword: searchValue.length === 0 ? undefined : searchValue,
  });
  const { isScrolledToBottom, isFullyOnScreen } = useScrollStatus(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  useEffect(() => {
    if (initialLoadComplete && !isFetching && data && data.data.showNext) {
      if (isScrolledToBottom || isFullyOnScreen) {
        setPage(page + 1);
      }
    }
  }, [isScrolledToBottom, initialLoadComplete, data, isFullyOnScreen]);

  useEffect(() => {
    if (data && !isFetching) {
      setPosts([
        ...posts.sort(
          (a, b) =>
            new Date(b.created).getTime() - new Date(a.created).getTime(),
        ),
        ...data.data.posts,
      ]);
      if (!initialLoadComplete) {
        setInitialLoadComplete(true);
      }
    }
  }, [data]);

  useEffect(() => {
    setPosts([]);
    setPage(1);
  }, [searchValue]);
  const { handleOpenPost } = useCreationHelperFunctions();

  return (
    <>
      <div className="w-full py-[24px]">
        <PageHeading
          title="Content"
          subtitle="Manage your posts and content."
          withInput={true}
          inputProps={{
            placeholder: "Search...",
            value: searchValue,
            onChange: (e) => setSearchValue(e.target.value),
            rightAdditionalContent: (
              <div
                className={`${searchValue.length > 0 ? "block" : "hidden"}`}
                onClick={() => setSearchValue("")}
              >
                <FontAwesomeIcon
                  icon={faX}
                  fontSize={"14px"}
                  className="text-gray-700 cursor-pointer"
                />
              </div>
            ),
          }}
          rightAdditionalContent={
            <SButton
              className="min-w-[104px]"
              onClick={() => {
                handleOpenPost({});
              }}
            >
              Create new
            </SButton>
          }
          tabsProps={{
            items: tabItems,
            selected: selected,
            setSelected: (i) => {
              setPosts([]);
              setPage(1);
              setSelected(i);
            },
          }}
        />
        <PostList
          handleOpenPost={(p: ISinglePostVM) => {
            handleOpenPost({
              post: p,
            });
          }}
          posts={posts}
          isLoading={isFetching}
        />
      </div>
    </>
  );
}

export default Content;
