import React, { useEffect, useState } from "react";
import SCheckbox from "../../../../design-system/SCheckbox";
import SButton from "../../../../design-system/SButton";
import {
  CopilotJsonContent,
  CopilotJsonContentBrief,
  ICreateTaskMessage,
} from "./hooks/IBriefInterfaces";

export interface IBriefSelectorProp {
  summaryPoint: string;
}
function BriefSelector({
  msg,
  json,
  sendMessage,
  messages,
  index,
  handleGetStory,
}: {
  handleGetStory: (str: string) => void;
  messages: ICreateTaskMessage[];
  index: number;
  sendMessage: (t: string) => void;
  msg: ICreateTaskMessage;
  json: CopilotJsonContentBrief;
}) {
  const [selecteds, setSelecteds] = useState(
    json!.Data!.SummaryPoints!.map((_, index) => index)
  );

  useEffect(() => {
    console.log("selecteds", selecteds);
  }, [selecteds]);

  const handleSubmit = () => {
    let story = "";
    selecteds.map((item, i) => {
      story =
        //@ts-ignore
        story + `\n ${i + 1}. ${json.Data.SummaryPoints[item].SummaryPoint}`;
    });
    if (story.length > 0) {
      handleGetStory(story);
    }
  };

  return (
    <div className="relative">
      {messages.length - 1 !== index && (
        <div
          className="w-full h-full  absolute top-0 left-0 flex justify-center items-center"
          style={{
            background: "rgba(234, 236, 240, 0.8)",
          }}
        >
          <p className="text-md-sb text-gray-900">Continued</p>
        </div>
      )}
      <div className="flex gap-[12px] p-[12px] flex-col bg-base-white mt-[12px]">
        {json!.Data!.SummaryPoints!.map((item, i) => (
          <div
            className="flex gap-[12px] items-center"
            onClick={() => {
              if (selecteds.includes(i)) {
                setSelecteds(selecteds.filter((s) => s !== i));
              } else {
                setSelecteds([...selecteds, i]);
              }
            }}
          >
            <SCheckbox checked={selecteds.includes(i)} size="md" />
            <p className="text-sm-m">{item.SummaryPoint}</p>
          </div>
        ))}
      </div>
      <div className="flex gap-[12px] justify-end mt-[12px] mb-[12px]">
        <SButton
          type="secondaryGray"
          onClick={() => sendMessage("Continue Brief")}
        >
          Continue Brief
        </SButton>
        <SButton disabled={selecteds.length === 0} onClick={handleSubmit}>
          Generate Post
        </SButton>
      </div>
    </div>
  );
}

export default BriefSelector;
