import { CopilotRole } from "../../../utils/enums/Enums";
import { copilotChatMessageResponse } from "../../creation/Tabs/Brief/hooks/copilotChatMessageResponse";
import { ICreateBrandMessage } from "./ICreateBrandChatInterface";

export const useCreateBrandChatHelper = ({
  setDisable,
  setChatResponse,
  setValue,
}: {
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  setChatResponse: React.Dispatch<React.SetStateAction<string>>;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const fetchStream = async ({
    tmpValue,
    copilotSessionId,
  }: {
    copilotSessionId: string;
    tmpValue: string;
  }) => {
    const response = await copilotChatMessageResponse(
      tmpValue,
      copilotSessionId
    );
    const reader = response.body?.getReader();
    let buffer = "";

    const sleep = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    const typeText = async (text: string) => {
      const groupSize = getGroupSize(text.length);
      const typingDelay = 0;

      for (let i = 0; i < text.length; i += groupSize) {
        buffer += text.slice(i, i + groupSize);
        // await setChatResponseAsync(buffer);
        await sleep(typingDelay);
      }
    };

    const setChatResponseAsync = (data: string) => {
      return new Promise((resolve) => {
        setChatResponse(data);
        resolve(data);
      });
    };

    const getGroupSize = (length: number) => {
      if (length <= 100) return 3;
      if (length <= 200) return 10;
      if (length <= 300) return 20;
      return 30;
    };

    while (reader) {
      const { value, done } = await reader.read();

      if (done) {
        setChatResponseAsync(buffer);
        setDisable(false);
        break;
      }

      const chunkText = new TextDecoder("utf-8")
        .decode(value)
        .replace(/undefined/g, "");
      await typeText(chunkText);
    }
  };

  const sendMessage = async ({
    text,
    setMessages,
    messages,
    copilotSessionId,
  }: {
    copilotSessionId: string;
    messages: ICreateBrandMessage[];
    setMessages: React.Dispatch<React.SetStateAction<ICreateBrandMessage[]>>;
    text: string;
  }) => {
    setDisable(true);
    setChatResponse("");
    setValue("");
    const userMessage: ICreateBrandMessage = {
      role: CopilotRole.USER,
      content: text,
      json: {
        Type: "UserMessage",
        Data: {
          UserMessage: text,
        },
      },
      isSuccess: true,
    };
    const aiMessage: ICreateBrandMessage = {
      role: CopilotRole.ASSISTANT,
      content: "",
      isSuccess: false,
    };

    setMessages([...messages, userMessage, aiMessage]);
    await fetchStream({
      copilotSessionId,
      tmpValue: text,
    });
  };

  return { sendMessage };
};
