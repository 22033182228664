import React from "react";
import SButton from "../../../design-system/SButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

function CreateCustomFieldButton({ openModal }: { openModal: () => void }) {
  return (
    <div
      onClick={openModal}
      className="hover:bg-brand-50 transition-all w-full h-[120px] cursor-pointer bg-brand-25 rounded-[12px] border-dashed border-[1px] border-graycool-300 flex flex-col justify-center gap-[12px] items-center"
    >
      <SButton
        size="sm"
        type="secondaryColor"
        icon={faPlus}
        className="rounded-full"
      />
      <p className="text-xs-sb text-graycool-800">Add new field</p>
    </div>
  );
}

export default CreateCustomFieldButton;
