import React from "react";
import SButton from "../../../design-system/SButton";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import { useGetAllPosts } from "../../../api/posts/getAll";
import PostCard from "../../content/PostCard";
import ContentSkeleton from "../../Skeletons/ContentSkeleton";

function UpcomingPosts() {
  const navigate = useNavigate();
  const { data, isLoading } = useGetAllPosts({
    page: 1,
    pageSize: 4,
  });
  return (
    <>
      {data && data.data.posts.length > 0 && (
        <div className="mt-[40px]">
          <div className="flex justify-between items-center">
            <p className="display-xs-sb text-gray-700">
              Upcoming <span className="text-gray-400">posts</span>
            </p>
            <SButton
              rIcon={faArrowRight}
              type="linkColor"
              onClick={() => navigate("/content")}
            >
              View all
            </SButton>
          </div>
          <div className="grid grid-cols-4 gap-[24px] mt-[24px] max-md:grid-cols-1">
            {isLoading ? (
              <>
                <ContentSkeleton />
                <ContentSkeleton />
                <ContentSkeleton />
              </>
            ) : (
              data &&
              data.data.posts.map((item) => (
                <PostCard className="h-fit" post={item} />
              ))
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default UpcomingPosts;
