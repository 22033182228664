import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IPublishPostCOE } from "../../../utils/interfaces/IPost";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";

export const usePostPublish = () => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<string>, any, IPublishPostCOE[]>({
    mutationFn: (postData) => makeRequest.post("/Posts/Publish", postData),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetPost"] });
    },
  });
};
