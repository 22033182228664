import React, { useEffect, useRef, useState } from "react";
import { useCreationStore } from "../../../../../store/creationStore";
import useScrollStatus from "../../../../../hooks/useScrollStatus";
import { usePostStockImages } from "../../../../../api/creation/stocks/post";
import { useStocksInfiniteScroll } from "./hooks/useStocksInfiniteScroll";
import { useCreationLibraryHelperFunctions } from "../hooks/useCreationLibraryHelperFunctions";
import SButton from "../../../../../design-system/SButton";
import {
  faExpand,
  faPlus,
  faRefresh,
} from "@fortawesome/pro-regular-svg-icons";
import ExpandedImageModal from "../../../../utils/ExpandedImageModal";

const ImageSkeleton = () => (
  <div className="w-full pb-[100%] rounded-[12px] bg-gray-200" />
);

function StocksView({
  inputValue,
  setInputValue,
  activeTab,
}: {
  activeTab: "generator" | "stocks" | "search" | "selector";
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  inputValue: string;
}) {
  const { stocks, isStocksFetching } = useCreationStore();
  const [expandedImage, setExpandedImage] = useState<string | null>(null);
  const wrapperRef = useRef<any>(null);
  useStocksInfiniteScroll({
    ref: wrapperRef,
    inputValue,
  });
  const { selectStockImage } = useCreationLibraryHelperFunctions();
  const [loadingList, setLoadingList] = useState<string[]>([]);

  return (
    <>
      {expandedImage && (
        <ExpandedImageModal
          isOpen={!!expandedImage}
          onClose={() => setExpandedImage(null)}
          source={expandedImage}
        />
      )}
      <div className="w-full h-[1px] bg-gray-100 mt-[16px] mb-[12px]" />
      <div ref={wrapperRef} className="px-[24px] max-h-[400px] overflow-y-auto">
        <div className="grid grid-cols-4 gap-[20px]">
          {stocks.map((item) => (
            <div
              key={item.url}
              className="relative w-full pb-[100%] rounded-[12px] overflow-hidden group"
            >
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={item.thumbnailMediumUrl}
                alt=""
              />
              <div className="absolute top-[8px] right-[8px] flex gap-[6px] opacity-0 group-hover:opacity-100 transition-opacity">
                <SButton
                  icon={faExpand}
                  type="secondaryGray"
                  size="sm"
                  onClick={() => setExpandedImage(item.url)}
                />
                <SButton
                  icon={loadingList.includes(item.url) ? faRefresh : faPlus}
                  iconProps={{
                    spin: loadingList.includes(item.url),
                  }}
                  onClick={() => {
                    setLoadingList((prev) => [...prev, item.url]);
                    selectStockImage(item).finally(() => {
                      setLoadingList((prev) =>
                        prev.filter((fItem) => fItem !== item.url),
                      );
                    });
                  }}
                  isLoading={loadingList.includes(item.url)}
                  type="secondaryGray"
                  size="sm"
                />
              </div>
            </div>
          ))}
          {isStocksFetching && (
            <>
              <ImageSkeleton />
              <ImageSkeleton />
              <ImageSkeleton />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default StocksView;
