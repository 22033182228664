import React, { useState } from "react";
//@ts-ignore
import bottom from "../../../assets/images/previews/bottom.svg";
import { useCreationStore } from "../../../store/creationStore";
import SBadge from "../../../design-system/SBadge";
import { faSparkles } from "@fortawesome/pro-regular-svg-icons";
import { useCreationHelperFunctions } from "../hooks/useCreationHelperFunctions";
import { useUpdatePost } from "../hooks/useUpdatePost";
import ImagesGrid from "../Tabs/Media/ImagesGrid";
import { isVideo } from "../../Library/hooks/getFileType";
import ExpandedImages from "../Tabs/Media/ExpandedImages";
import { useGetCurrentBrand } from "../../../api/brand/current/get";
import CopilotStateChangeAgreement from "../modals/CopilotStateChangeAgreement";

interface ICreationPreview {
  className?: string;
  isHovered: boolean;
}

function DefaultPreview({ isHovered, className }: ICreationPreview) {
  const { getActiveSocialNetworkPost, isPostPublishedOrScheduled } =
    useCreationHelperFunctions();
  const { updateTextUI } = useUpdatePost();
  const { data: currentBrand } = useGetCurrentBrand();
  const [isTextEditing, setIsTextEditing] = useState(false);
  const [isExpandedImagesOpen, setIsExpandedImagesOpen] = useState(false);
  const [
    isCopilotStateChangeAgreementOpen,
    setIsCopilotStateChangeAgreementOpen,
  ] = useState(false);
  return (
    <>
      {isCopilotStateChangeAgreementOpen && (
        <CopilotStateChangeAgreement
          isOpen={isCopilotStateChangeAgreementOpen}
          setIsOpen={(p) => setIsCopilotStateChangeAgreementOpen(p)}
        />
      )}
      {isExpandedImagesOpen && (
        <ExpandedImages
          isOpen={isExpandedImagesOpen}
          onClose={() => setIsExpandedImagesOpen(false)}
        />
      )}
      <div
        className={`${className} max-w-[393px] w-full relative rounded-[16px] bg-transparent`}
        style={{ boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.04)" }}
      >
        <div className="flex items-center gap-[12px] pt-[16px] pb-[16px] px-[20px] rounded-t-[16px] bg-base-white border-t border-l border-r border-gray-200">
          <img
            className={`w-[40px] h-[40px] border-1 border-gray-50 rounded-full object-contain`}
            src={currentBrand?.data?.logo}
            alt="brand-logo"
          />
          <div>
            <p className="text-sm-sb text-gray-900">
              {currentBrand?.data?.name}
            </p>
            <p className="text-xs text-gray-600">10h</p>
          </div>
        </div>
        <pre
          onClick={() => {
            if (!getActiveSocialNetworkPost()?.text) {
              setIsTextEditing(true);
              if (getActiveSocialNetworkPost()!.text === null) {
                setIsCopilotStateChangeAgreementOpen(true);
              }
            }
          }}
          onBlur={(e) => {
            if (
              e.target.innerText.length === 0 &&
              !isCopilotStateChangeAgreementOpen
            ) {
              setIsTextEditing(false);
            }
            if (getActiveSocialNetworkPost()?.text !== e.target.innerText) {
              updateTextUI({
                text: e.target.innerText,
                sendRequest: true,
              });
            }
          }}
          onPaste={(event) => {
            event.preventDefault();
            const text = event.clipboardData.getData("text/plain");
            document.execCommand("insertText", false, text);
          }}
          contentEditable={true}
          className={`${isHovered && "my-[12px] border-t border-b"} 
        ${isPostPublishedOrScheduled() && "pointer-events-none"}
        relative 
        outline-none
        transition-all pt-[16px] bg-base-white border-l border-r border-gray-200  text-sm text-gray-700 px-[20px] 
        hover:border-success-300 hover:border-[1px]
        pb-[16px] min-h-[56px] h-fit w-full whitespace-pre-wrap break-words`}
        >
          {getActiveSocialNetworkPost()?.text || isTextEditing ? (
            getActiveSocialNetworkPost()?.text
          ) : (
            <div className={"flex gap-[8px]"}>
              <p className="text-sm underline text-graymodern-400">
                Click here to write or
              </p>
              <SBadge color="purple" size="sm" icon={faSparkles}>
                Use the AI Assistant
              </SBadge>
            </div>
          )}
        </pre>

        {/* </div> */}
        {getActiveSocialNetworkPost() &&
          getActiveSocialNetworkPost()!.files.length > 0 && (
            <div
              className={`
          ${isPostPublishedOrScheduled() && "pointer-events-none"}
          transition-all w-full aspect-square border-[2px] cursor-pointer border-transparent hover:border-success-300`}
            >
              {getActiveSocialNetworkPost() &&
                getActiveSocialNetworkPost()!.files.length > 0 &&
                (isVideo(getActiveSocialNetworkPost()!.files[0].url) ? (
                  <video
                    className="w-full h-full object-contain"
                    controls={true}
                  >
                    <source src={getActiveSocialNetworkPost()!.files[0].url} />
                  </video>
                ) : (
                  <ImagesGrid
                    onClick={() => setIsExpandedImagesOpen(true)}
                    images={getActiveSocialNetworkPost()!.files.map(
                      (item) => item.url,
                    )}
                  />
                ))}
            </div>
          )}
        {/* <img
        onClick={() => setActiveTab("media")}
        className={`transition-all w-full aspect-square border-[2px] cursor-pointer border-transparent hover:border-success-300`}
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRem_c2UVBTZckm9rh1AFynK8zXsEGpEsCaZg&s"
        alt=""
      /> */}
        <img
          src={bottom}
          className={`${isHovered && "mt-[12px]"} transition-all`}
        />
      </div>
    </>
  );
}

export default DefaultPreview;
