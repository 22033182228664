import { Dispatch, SetStateAction } from "react";
import { handleFolderChange } from "./handleFolderChange";
import { IBreadcrumbsItem } from "../../../design-system/SBreadcrumbs";
import { ISingleFoldersAndFilesVM } from "../../../utils/interfaces/IAssets";
import { ILibraryProps } from "../../../pages/Library";

export interface ISelectDoubleClick
  extends Omit<ILibraryProps, "createdAt" | "author"> {
  clickTimeout?: number | null;
  setClickTimeout?: Dispatch<SetStateAction<number | null>>;
  setSelectedItems?: ([{id, isFolder}]: {id: number; isFolder: boolean}[]) => void;
  setParentFolder?: Dispatch<
    SetStateAction<null | { id: number | null; title: string }>
  >;
  setFolderHistory?: Dispatch<SetStateAction<IBreadcrumbsItem[]>>;
  setFoldersAndFiles: Dispatch<SetStateAction<ISingleFoldersAndFilesVM[]>>;
  setPage: Dispatch<SetStateAction<number>>;
  setExpandedImage?: Dispatch<SetStateAction<string | null>>;
}

export const handleDoubleClick = ({
  clickTimeout,
  setClickTimeout,
  setSelectedItems,
  cardType,
  cardTitle,
  id,
  setFolderHistory,
  setFoldersAndFiles,
  setParentFolder,
  setPage,
  setExpandedImage,
  imageSrc,
}: ISelectDoubleClick) => {
  if (clickTimeout) {
    clearTimeout(clickTimeout);
    if (setClickTimeout) setClickTimeout(null);
  }
  if (cardType === "Folder") {
    setPage(1);
    setFoldersAndFiles([]);
    handleFolderChange({
      cardType,
      setParentFolder,
      setPage,
      setFoldersAndFiles,
      setFolderHistory,
      id,
      cardTitle,
    });
    setSelectedItems && setSelectedItems([]);
  }
  if (cardType === "Image" || cardType === 'Video') {
    setExpandedImage && setExpandedImage(imageSrc ? imageSrc : '');
  }
  if (cardType === "PDF" || cardType === "Word" || cardType === 'Text') {
    const link = document.createElement("a");
    link.href = imageSrc ? imageSrc : '';
    link.download = cardTitle;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
