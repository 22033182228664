import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetCurrentWorkspace } from "../../../api/workspaces/current/get";
import SModalBase from "../../../design-system/SModalBase";
import { useGetUser } from "../../../hooks/useUserInfo";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faBadgeCheck } from "@fortawesome/pro-solid-svg-icons";
import SButton from "../../../design-system/SButton";

export interface IGlobalInviteMessageModalProps {
  inviteModal: boolean;
  setInviteModal: (value: boolean) => void;
}

export const GlobalInviteMessageModal = ({
  inviteModal,
  setInviteModal,
}: IGlobalInviteMessageModalProps) => {
  const { data } = useGetCurrentWorkspace();
  const { data: userData } = useGetUser();
  return (
    <SModalBase
      dialogPanelClassName="w-[400px]"
      isOpen={inviteModal}
      onClose={() => setInviteModal(false)}
    >
      <div className="flex w-full gap-3 items-center">
        <FontAwesomeIcon
          className=""
          icon={faBadgeCheck}
          size="lg"
          color="#8a47e0"
        />
        <h1 className="text-gray-700 capitalize text-lg-m">
          Congratulations, {userData?.data.data.name}!
        </h1>
      </div>
      <p className="text-gray-500 mt-3 text-md-m">
        {" "}
        You've been added to the {data?.data.name}!
      </p>
      <SButton
        className="mt-6"
        onClick={() => setInviteModal(false)}
        children="Let's go!"
      />
    </SModalBase>
  );
};
