import { useEffect, useState } from "react";
import PageHeading from "../components/PageHeading";
import useScrollStatus from "../hooks/useScrollStatus";
import useNotificationSocketStore from "../store/notificationsStore";
import NotificationItem from "../components/notifications/NotificationItem";
import { NotificationType } from "../utils/enums/Enums";
import NotificationSkeleton from "../components/notifications/NotificationSkeleton";
import { useRespondFromNotification } from "../components/notifications/hooks/useRespondFromNotification";
import { useGetNotifications } from "../api/notifications/get";
import { useDeleteNotification } from "../api/notifications/delete";
import { toast } from "../hooks/toast";
import { useValueByType } from "../components/notifications/hooks/useValueByType";

export const notificationValueByType = (
  type: NotificationType,
  arr: string[]
): string => {
  switch (type) {
    case NotificationType.COMMENT:
      return arr[0];
    case NotificationType.INVITATION:
      return arr[1];
    case NotificationType.ExamplePostCreated:
      return arr[2];
    case NotificationType.ReminderPostReadyForPublishing:
      return arr[3];
    case NotificationType.PostDraftWasCreated:
      return arr[4];
    case NotificationType.PostWasPublished:
      return arr[5];
    case NotificationType.PostWasScheduled:
      return arr[6];
    case NotificationType.ScheduledPostWasPublished:
      return arr[7];
    case NotificationType.UserJoinedTheBrand:
      return arr[8];
    default:
      return "";
  }
};

function Notifications() {
  const {
    liveMessages,
    removeLiveMessage,
    page,
    setPage,
    notifications,
    setNotifications,
  } = useNotificationSocketStore();
  const { isScrolledToBottom, isFullyOnScreen } = useScrollStatus(null);
  const { data, isLoading, isFetching } = useGetNotifications({
    page: page,
    pageSize: 20,
    isRead: true,
  });
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  useEffect(() => {
    if (
      !isFetching &&
      initialLoadComplete &&
      !isLoading &&
      data &&
      data.data.showNext
    ) {
      if (isScrolledToBottom || isFullyOnScreen) {
        setPage(page + 1);
      }
    }
  }, [isScrolledToBottom, isFullyOnScreen]);

  useEffect(() => {
    if (data && !isLoading && !isFetching) {
      setNotifications([...notifications, ...data.data.notifications]);
      if (!initialLoadComplete) {
        setInitialLoadComplete(true);
      }
    }
  }, [data]);
  useEffect(() => {
    if (liveMessages.length > 0) {
      const lastMessage = liveMessages[liveMessages.length - 1];
      setNotifications([lastMessage, ...notifications]);
      removeLiveMessage(lastMessage.id);
    }
  }, [liveMessages]);

  const { handleClick } = useRespondFromNotification();

  const { mutateAsync: deleteNotification, isPending } =
    useDeleteNotification();
  const handleDeleteNotification = (id: number) => {
    setNotifications([]);
    deleteNotification({ id: id })
      .then(() => {
        toast("Notification deleted successfully", "success");
      })
      .catch((res) => {
        toast(res.message, "error");
      });
  };
  const { getValue } = useValueByType();
  return (
    <div className="w-full py-[24px]">
      <PageHeading
        title="Notifications"
        subtitle="Keep up with your team activities."
      />
      <div className="mt-[28px] flex flex-col gap-[8px] mb-[40px]">
        {notifications.map((item) => {
          const organizationName =
            item.customProperty.organizations &&
            item.customProperty.organizations[0]?.name;
          const organizationOrWorkspace = organizationName
            ? organizationName + " brand "
            : item.customProperty.workspace?.name;

          return (
            <NotificationItem
              title={getValue(item, item.type, organizationOrWorkspace)}
              systemNotification={
                item.type === NotificationType.SystemNotification
                  ? item
                  : undefined
              }
              message={notificationValueByType(item.type, [
                "Check it out and join the conversation!",
                `You've received an invitation to join a new workspace. Don't miss out!`,
                "An example post has been created for your reference. Check it out for inspiration!",
                `Your post is ready for publishing. Review it and hit publish when you're ready`,
                `Draft post has been successfully created. Feel free to continue editing!`,
                `Your post is now live! Share it with your network and engage with your audience.`,
                `Your post has been scheduled for future publication. Stay tuned!`,
                `Your post is now live! Share it with your network and engage with your audience.`,
                `A new member has joined our workspace. Welcome them aboard!`,
              ])}
              type={
                item.type === NotificationType.INVITATION ? "warning" : "info"
              }
              notificationType={item.type}
              date={item.created}
              handleCancel={() =>
                item.type === NotificationType.INVITATION &&
                handleClick(
                  item.id,
                  item.customProperty.id,
                  3,
                  !!organizationName
                )
              }
              handleSubmit={() =>
                item.type === NotificationType.INVITATION &&
                handleClick(
                  item.id,
                  item.customProperty.id,
                  2,
                  !!organizationName
                )
              }
              handleDeleteNotification={() => handleDeleteNotification(item.id)}
            />
          );
        })}
        {(isLoading || isFetching || isPending) && (
          <>
            <NotificationSkeleton />
            {notifications.length === 0 && (
              <>
                <NotificationSkeleton />
                <NotificationSkeleton />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Notifications;
