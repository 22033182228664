import React, { useState } from "react";
import SModalBase from "../../design-system/SModalBase";
import SSelectOpener from "../../design-system/SSelectOpener";
import { TeamRole } from "../../utils/enums/Enums";
import SButton from "../../design-system/SButton";
import { MemberRolesDropdown } from "./MemberRolesDropdown";
import { useRemoveTeamMember } from "../../api/brand/activeMembers/delete";
import { useUpdateMemberRole } from "../../api/brand/activeMembers/put";
import { useRespondInvitation } from "../../api/invitations/invitationPending/put";
import { toast } from "../../hooks/toast";
import { ConfirmDelete } from "../Library/ConfirmDelete";

export interface IBrandEditMemberModalProps {
  setEditModal: (open: boolean) => void;
  editModal: boolean;
  chosenUser?: {
    userId: string;
    name: string;
    email: string;
    invitationId?: number | null;
    isPending: boolean;
    role: TeamRole
  };
}

export const BrandEditMemberModal = ({
  editModal,
  setEditModal,
  chosenUser,
}: IBrandEditMemberModalProps) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selected, setSelected] = useState<TeamRole>(chosenUser ? chosenUser?.role : TeamRole.MEMBER);
  const [isOpen, setIsOpen] = useState(false);
  const { mutateAsync: deleteMember } = useRemoveTeamMember();
  const { mutateAsync: updateRole } = useUpdateMemberRole();
  const { mutateAsync: cancelMember } = useRespondInvitation();
  return (
    <>
      <ConfirmDelete
        title="Are you sure you want to remove this member from brand?"
        openModal={openConfirmation}
        setOpenModal={() => setOpenConfirmation(true)}
        onConfirm={() => {
          if (chosenUser?.isPending) {
            cancelMember({
              id: chosenUser.invitationId ? chosenUser.invitationId : 0,
              statusId: 4,
            })
              .then(() => {
                toast("User removed from brand", "success");
              })
              .catch((e) => {
                toast(e.response.data.message);
              });
          } else {
            deleteMember({ userId: chosenUser?.userId })
              .then(() => {
                toast("User removed from brand", "success");
              })
              .catch((e) => {
                toast(e.response.data.message);
              });
          }
          setEditModal(false);
        }}
        onCancel={() => setOpenConfirmation(false)}
      />
      <SModalBase
        dialogPanelClassName="min-w-[544px] z-10 py-4 text-gray-600"
        isOpen={editModal}
        onClose={setEditModal}
      >
        <h1 className="text-md-m mb-3">
          <span className="capitalize">{chosenUser?.name}</span> settings
        </h1>
        <hr className="w-auto mx-[-24px]" />
        <div className="flex w-full flex-col gap-[8px] my-6">
          <div className="w-full relative flex flex-col gap-[6px]">
            <h1 className="text-gray-700 text-sm-m">Brand Role</h1>
            <SSelectOpener
              onOpen={() => setIsOpen((val) => !val)}
              text={TeamRole[selected][0] +
                TeamRole[selected].toLowerCase().substring(1) }
            />
            {isOpen && (
              <MemberRolesDropdown
                className="w-full top-[80%]"
                setSelected={setSelected}
                setIsOpen={setIsOpen}
                selected={selected}
              />
            )}
          </div>
          <h1 className="text-gray-600 text-sm">
            More about STORI roles.{" "}
            <span className="text-sm-m text-brand-600">
              <a href="https://develop.storiai.com" target="__blank">
                See Here
              </a>
            </span>
          </h1>
          <div className="flex items-center mt-[18px] justify-between">
            <SButton
              onClick={() => {
                setOpenConfirmation(true);
              }}
              className="bg-error-600 border-error-600 hover:bg-error-700 hover:border-error-700"
              size="sm"
              children="Remove from brand"
            />
            <div className="flex gap-3 items-center">
              <SButton
                onClick={() => setEditModal(false)}
                type="secondaryGray"
                size="md"
                children="Cancel"
              />
              <SButton
                onClick={() => {
                  updateRole({
                    userId: chosenUser?.userId ? chosenUser?.userId : "",
                    role: selected as TeamRole,
                  })
                    .then(() => {
                      toast("User role updated", "success");
                    })
                    .catch((e) => {
                      toast(e.response.data.message, "error");
                    });
                  setEditModal(false);
                }}
                type="primary"
                size="md"
                children="Save"
              />
            </div>
          </div>
        </div>
      </SModalBase>
    </>
  );
};
