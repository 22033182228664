import { ILibraryDropdownProps, LibraryDropdown } from "./LibraryDropdown";
//@ts-ignore
import Search from "../../assets/icons/search.svg";
//@ts-ignore
import BGPattern from "../../assets/icons/bg-pattern.svg";

export interface ILibraryEmptyFolderProps extends ILibraryDropdownProps {
  searchValue?: string;
}

export const LibraryEmptyFolder = ({
  handleFolder,
  handleFiles,
  handleDropbox,
  searchValue,
}: ILibraryEmptyFolderProps) => {
  return (
    <div className="absolute top-1/2 -translate-y-1/2 right-1/2 translate-x-1/2 flex-col gap-1 flex justify-center items-center">
      <div className="mb-3">
        <img src={Search} className="w-12" alt="Search Assets" />
        <img
          src={BGPattern}
          className="w-[480px] absolute left-1/2 -translate-x-1/2 z-[-10] -bottom-1/2"
          alt="Search Assets Pattern"
        />
      </div>
      <h1 className="text-gray-900 text-md-sb">No Assets Found</h1>

      <p className="text-sm px-[12%] mb-4 text-center text-gray-600">
        {searchValue
          ? `Your search “${searchValue}” did not match any projects. Please try again.`
          : "Change folders or create one"}
      </p>
      <LibraryDropdown
        handleFiles={handleFiles}
        handleFolder={handleFolder}
        handleDropbox={handleDropbox}
        className="right-1/2 translate-x-1/2"
      />
    </div>
  );
};
