import React, { useEffect } from "react";
import BriefSelector from "./BriefSelector";
import { ICreateTaskMessage } from "./hooks/IBriefInterfaces";
import { useCreationStore } from "../../../../store/creationStore";
//@ts-ignore
import logo from "../.../../../../../assets/images/logos/stori-s-square.svg";
function AIMessage({
  handleGetStory,
  disable,
  index,
  msg,
  messages,
  sendMessage,
}: {
  disable: boolean;
  messages: ICreateTaskMessage[];
  index: number;
  sendMessage: (t: string) => void;
  handleGetStory: (str: string) => void;
  msg: ICreateTaskMessage;
}) {
  let isLoading = msg.json === undefined;

  return (
    <div className="h-fit flex gap-[12px] relative">
      <img src={logo} alt="" className="w-[40px] h-[40px] rounded-full" />
      <div
        className={`bg-gray-50 flex flex-col justify-center items-start border rounded-[8px] rounded-tl-[0] border-gray-200 py-[10px] px-[14px] max-w-[456px] 
          ${!isLoading ? "w-fit" : "w-full"}`}
      >
        {isLoading ? (
          <div className="typing__animation">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        ) : (
          <>
            {msg.json &&
              (msg.json.Type === "CopilotMessage" ||
                msg.json?.Type === "TextVersions" ||
                msg.json?.Type === "Brief") &&
              msg.json.Data.CopilotMessage.length > 0 && (
                <pre className="whitespace-pre-wrap break-words text-md text-gray-900">
                  {msg.json.Data.CopilotMessage.trim()}
                </pre>
              )}
            {msg.json && msg.json.Type === "Brief" && (
              <BriefSelector
                handleGetStory={handleGetStory}
                index={index}
                messages={messages}
                sendMessage={sendMessage}
                msg={msg}
                json={msg.json}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default AIMessage;
