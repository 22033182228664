import { faX } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useCopilotStore } from "../../store/copilotStore";

function Head() {
  const { setIsCopilotOpen } = useCopilotStore();
  return (
    <div className="w-full h-[56px] flex justify-between items-center px-[24px] py-[16px] bg-base-white border-b border-b-gray-200">
      <p className="text-md-m text-gray-600">STORI Assistant</p>
      <FontAwesomeIcon
        icon={faX}
        onClick={() => setIsCopilotOpen(false)}
        className="text-[12px] text-gray-500 cursor-pointer"
      />
    </div>
  );
}

export default Head;
