import SBadge from "../../design-system/SBadge";
import React from "react";
import TextsSectionSkeleton from "./TextsSectionSkeleton";
import { useBrandStore } from "../../store/brandStore";
import SButton from "../../design-system/SButton";
import { faSparkles } from "@fortawesome/pro-regular-svg-icons";
import { useBrandHelperFunctions } from "./hooks/useBrandHelperFunctions";
import { BrandingSectionName } from "../../utils/interfaces/IBrand";

interface ITextsSectionProps {
  title: string;
  subtitle: string;
  texts: IBrandSectionTextsProps[];
  isLoading: boolean;
  loadingCount: number;
  fieldName: BrandingSectionName;
}

export interface IBrandSectionTextsProps {
  title: string;
  content?: string;
  tags?: string[] | string[][];
  key: string;
  showBorder?: boolean;
  isTitleEditable?: boolean;
  handleChangeText?: (text: string) => void;
  handleChangeTitle?: ({ text, sKey }: { text: string; sKey: string }) => void;
  handleChangeTag?: ({
    parentIndex,
    text,
    index,
    sKey,
  }: {
    parentIndex?: number;
    text: string;
    index: number;
    sKey: string;
  }) => void;
}

function TextsSection({
  isLoading,
  title,
  subtitle,
  texts,
  loadingCount,
  fieldName,
}: ITextsSectionProps) {
  const { handleRegenerateSection } = useBrandHelperFunctions();
  const { currentBrand } = useBrandStore();
  return (
    <div className="group bg-base-white border-[1px] rounded-[8px] border-gray-200">
      <div className="bg-gray-50 rounded-t-[8px] px-[32px] py-[20px] gap-[5px] flex justify-between items-center max-md:py-[16px] max-md:px-[20px] max-md:gap-[15px]">
        <div>
          <p className="display-xs-sb text-graycool-800">{title}</p>
          <p className="text-md text-graycool-600 mt-[2px]">{subtitle}</p>
        </div>
        <SButton
          onClick={() => handleRegenerateSection(fieldName)}
          lIcon={faSparkles}
          type="secondaryColor"
          className="group-hover:opacity-100 group-hover:pointer-events-auto opacity-0 pointer-events-none max-md:opacity-100 max-md:pointer-events-auto"
        >
          <span className="max-md:hidden">Regenerate</span>
        </SButton>
      </div>
      {!currentBrand?.isBrandDataGenerated || isLoading ? (
        <TextsSectionSkeleton count={loadingCount} />
      ) : (
        <div className="px-[32px] rounded-b-[8px] bg-base-white max-md:px-[20px]">
          {texts.map((item, index) => (
            <div
              key={index}
              className={`${item.showBorder && "border-b-[1px]"} border-b-graycool-100 py-[24px] max-md:py-[16px]`}
            >
              <p
                className="text-lg-sb text-gray-900"
                contentEditable={item.isTitleEditable}
                onBlur={(e) =>
                  item.handleChangeTitle &&
                  item.handleChangeTitle({
                    text: e.target.innerText,
                    sKey: item.key,
                  })
                }
                onPaste={(event) => {
                  event.preventDefault();
                  const text = event.clipboardData.getData("text/plain");
                  document.execCommand("insertText", false, text);
                }}
              >
                {item.title}
              </p>
              <p
                className="outline-none text-md text-graycool-600 mt-[4px] focus:underline"
                contentEditable={true}
                onBlur={(e) =>
                  item.handleChangeText &&
                  item.handleChangeText(e.target.innerText)
                }
                onPaste={(event) => {
                  event.preventDefault();
                  const text = event.clipboardData.getData("text/plain");
                  document.execCommand("insertText", false, text);
                }}
              >
                {item.content}
              </p>
              {item.tags && (
                <div
                  className={`${
                    Array.isArray(item.tags[0]) && "flex-col"
                  } flex flex-wrap gap-[8px] mt-[16px]`}
                >
                  {Array.isArray(item.tags[0])
                    ? (item.tags as string[][]).map((group, groupIndex) => (
                        <div
                          key={groupIndex}
                          className="flex flex-wrap gap-[8px]"
                        >
                          {group.map((tag, index) => (
                            <p
                              key={index}
                              className="outline-none bg-base-white border-[1px] border-gray-300 rounded-[6px] text-sm-m text-gray-700 px-[12px] py-[4px] shadow-none"
                              contentEditable={true}
                              onPaste={(event) => {
                                event.preventDefault();
                                const text =
                                  event.clipboardData.getData("text/plain");
                                document.execCommand("insertText", false, text);
                              }}
                              onBlur={(e) =>
                                item.handleChangeTag &&
                                item.handleChangeTag({
                                  sKey: item.key,
                                  text: e.target.innerText,
                                  index: index,
                                  parentIndex: groupIndex,
                                })
                              }
                            >
                              {tag}
                            </p>
                          ))}
                        </div>
                      ))
                    : (item.tags as string[]).map((tag, index) => (
                        <p
                          key={index}
                          className="outline-none bg-base-white border-[1px] border-gray-300 rounded-[6px] text-sm-m text-gray-700 px-[12px] py-[4px] shadow-none"
                          contentEditable={true}
                          onPaste={(event) => {
                            event.preventDefault();
                            const text =
                              event.clipboardData.getData("text/plain");
                            document.execCommand("insertText", false, text);
                          }}
                          onBlur={(e) =>
                            item.handleChangeTag &&
                            item.handleChangeTag({
                              sKey: item.key,
                              text: e.target.innerText,
                              index: index,
                            })
                          }
                        >
                          {tag}
                        </p>
                      ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default TextsSection;
