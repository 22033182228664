import React, { useEffect, useState } from "react";

import PreviewSide from "../components/creation/previews/PreviewSide";
import SButton from "../design-system/SButton";
import { faComment, faDownload, faX } from "@fortawesome/pro-regular-svg-icons";
import CreationHeader from "../components/creation/CreationHeader";
import Brief from "../components/creation/Tabs/Brief";
import { useInitializePost } from "../components/creation/hooks/useInitializePost";
import { useCreationStore } from "../store/creationStore";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import useLockBodyScroll from "../hooks/useLockBodyScroll";
import Channels from "../components/creation/Tabs/Channels";
import Media from "../components/creation/Tabs/Media";
import { useCreationLibraryHelperFunctions } from "../components/creation/Tabs/Media/hooks/useCreationLibraryHelperFunctions";
import { useCreationHelperFunctions } from "../components/creation/hooks/useCreationHelperFunctions";
import { PostStatus } from "../utils/enums/Enums";
import CommentsModal from "../components/creation/CommentsModal";
import VideoSelectionAgreement from "../components/creation/modals/VideoSelectionAgreement";
import PublishedTab from "../components/creation/Tabs/PublishedTab";
import { getQuery } from "../hooks/functions/queries";

const Creation = ({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  useInitializePost();
  const { activeTab, setActiveTab, activeSoc, tabsOverlay, postData } =
    useCreationStore();
  useLockBodyScroll(isOpen);
  const { downloadMediasFromActiveSocialNetworkType } =
    useCreationLibraryHelperFunctions();
  const { isPostPublishedOrScheduled } = useCreationHelperFunctions();

  const [isCommentsOpen, setIsCommentsOpen] = useState(getQuery('open_comments') === 'true' ? true : false);

  useEffect(() => {
    if (isPostPublishedOrScheduled()) {
      setActiveTab("published");
    } else {
      if (activeTab === "published") setActiveTab("brief");
    }
  }, [postData, activeSoc]);
  return (
    <>
      <VideoSelectionAgreement />
      <Drawer
        open={isOpen}
        onClose={onClose}
        direction="bottom"
        className="min-h-[95dvh] rounded-[24px]"
        lockBackgroundScroll={true}
      >
        <div className="flex flex-col gap-[58px] absolute top-[20px] right-[20px] z-[1000]">
          <SButton
            icon={faX}
            size="md"
            type="secondaryGray"
            onClick={onClose}
          />
          <div className="relative flex flex-col gap-[6px]">
            {isCommentsOpen && (
              <CommentsModal
                isOpen={isCommentsOpen}
                onClose={() => setIsCommentsOpen(false)}
              />
            )}
            <SButton
              id="post__comments__chat-opener"
              onClick={() => setIsCommentsOpen((p) => !p)}
              icon={faComment}
              size="md"
              type="secondaryGray"
            />
            <SButton
              icon={faDownload}
              onClick={downloadMediasFromActiveSocialNetworkType}
              size="md"
              type="secondaryGray"
            />
          </div>
        </div>
        <div className="flex h-full w-full rounded-t-[24px] max-md:flex-col-reverse">
          <div className="w-[60%] h-[100%] bg-white border border-gray-200 rounded-tl-[24px] flex flex-col mb-[20px] max-md:w-full max-md:rounded-tl-[0px] max-md:border-0 max-md:m-0">
            <CreationHeader />
            <div
              className={`${tabsOverlay ? "py-[20px]" : "py-[20px]"} px-[36px] h-full overflow-hidden relative max-md:p-0`}
            >
              {tabsOverlay && (
                <div
                  className={`absolute w-full h-[calc(100%+20px)] top-[-20px] left-0 bg-[rgba(152,152,152,0.8)] z-[100]`}
                />
              )}
              {activeTab === "brief" && <Brief />}
              {activeTab === "accounts" && <Channels />}
              {activeTab === "media" && <Media />}
              {activeTab === "published" && <PublishedTab />}
            </div>
          </div>
          <PreviewSide
            isCommentsOpen={isCommentsOpen}
            setIsCommentsOpen={setIsCommentsOpen}
          />
        </div>
      </Drawer>
    </>
  );
};
export default Creation;
