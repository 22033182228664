import { useQuery } from "@tanstack/react-query";
import { ISinglePostVM } from "../../utils/interfaces/IPost";
import { IBaseData } from "../../utils/interfaces/IBase";
import { makeRequest } from "../auth/axios";

export const useGetPostById = ({ postId }: { postId: number | null }) => {
  return useQuery<IBaseData<ISinglePostVM>>({
    queryKey: ["useGetPostById"],
    queryFn: async () => {
      const response = await makeRequest.get(`/Posts/${postId}`);
      return response as IBaseData<ISinglePostVM>;
    },
    enabled: false,
  });
};
