import React, { useEffect, useState } from "react";
import { IBrandCreationData } from "../../../pages/CreateBrand";
import SUploader from "../../../design-system/SUploader";
import Colors from "../Colors";
import STextarea from "../../../design-system/STextarea";
import { usePutBrandLogo } from "../../../api/brand/logo/put";
import { closeAllToast, toast } from "../../../hooks/toast";
import { OrganizationType } from "../../../utils/enums/Enums";

function Visual({
  setData,
  data,
}: {
  setData: React.Dispatch<React.SetStateAction<IBrandCreationData>>;
  data: IBrandCreationData;
}) {
  const { mutateAsync: uploadLogo } = usePutBrandLogo();
  const [tagline, setTagline] = useState<string>("");
  useEffect(() => {
    if (tagline.length > 0) {
      setData((prev) => ({
        ...prev,
        tagline,
      }));
    } else {
      setData((prev) => ({
        ...prev,
        tagline: undefined,
      }));
    }
  }, [tagline]);
  const handleLogoUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      toast("Please wait while we upload your logo", "loading");
      const response = await uploadLogo({
        file: formData,
        isNewOrganization: true,
      });
      setData((prev) => ({
        ...prev,
        logo: "https://cdn.storiai.com/" + response.data.data,
      }));
      closeAllToast();
      toast("Logo uploaded successfully", "success");
    } catch (err) {
      //@ts-ignore
      toast(err!.response!.data!.message, "error");
    }
  };

  return (
    <div className="max-w-[762px] w-full border border-gray-200 bg-base-white rounded-[8px] p-[36px]">
      <div className="flex gap-[36px] max-md:flex-col">
        <div className="w-[52%] max-md:w-full">
          <p className="text-sm-m text-gray-700">
            {data.type === OrganizationType.COMPANY
              ? "Logo"
              : "Profile Picture"}
            <span className="text-sm !font-normal text-gray-400">
              {" "}
              (Optional)
            </span>
          </p>
          {data.logo ? (
            <div className="flex justify-center items-center mt-[6px] max-h-[126px] h-full w-full">
              <img
                className="w-[50%] h-full object-cover"
                src={data.logo}
                alt="logo"
              />
            </div>
          ) : (
            <SUploader
              className="border-gray-200 w-full h-[126px] mt-[6px] !border-[1px] bg-base-white"
              showIcon={false}
              accept="SVG, PNG, JPG or GIF (min. 300x300px)"
              onDrop={handleLogoUpload}
            />
          )}
        </div>

        <Colors data={data} setData={setData} />
      </div>
      {data.type === OrganizationType.COMPANY && (
        <STextarea
          value={data.tagline}
          onChange={(e) =>
            setData((prev) => ({ ...prev, tagline: e.target.value }))
          }
          isOptional={true}
          className="mt-[36px]"
          textareaClassname={"max-h-[76px] resize-none max-md:max-h-[100px]"}
          label="Tagline"
          placeholder="Tagline is a powerful short phrase that can make your brand standout among its competitors"
        />
      )}
    </div>
  );
}

export default Visual;
