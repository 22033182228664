import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IAccountCOE, IAccountVM } from "../utils/interfaces/IAccount";
import { IBase } from "../utils/interfaces/IBase";
import { makeRequest } from "../axios";
import { toast } from "./toast";

export const usePutAccount = () => {
  const queryClient = useQueryClient();

  return useMutation<IBase<IAccountVM>, any, IAccountCOE>({
    mutationFn: (userInfo: IAccountCOE) =>
      makeRequest.put("/Account", { ...userInfo }),
    onSuccess: (res) => {
      toast("User info updated successfully", "success");
      queryClient.invalidateQueries({ queryKey: ["useGetUser"] });
    },
    onError: (error) => {
      toast(error?.response?.data?.message);
    },
  });
};
