import React, { useState } from "react";
import SButton from "../../design-system/SButton";
import { useCreationHelperFunctions } from "./hooks/useCreationHelperFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faSave,
  faSquareList,
  faStar,
  faText,
} from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useCreationStore } from "../../store/creationStore";
import SOptionButon from "../../design-system/SOptionButton";
import { usePublishing } from "./hooks/usePublishing";
import ScheduleModal from "./modals/ScheduleModal";
import { toast } from "../../hooks/toast";
import { Tooltip } from "react-tooltip";

const NavItem = ({
  icon,
  isActive,
  text,
  onClick,
  disabled,
  ...props
}: {
  icon: IconProp;
  text: string;
  onClick: () => void;
  isActive: boolean;
  disabled?: boolean;
}) => {
  return (
    <div
      {...props}
      onClick={!disabled ? onClick : undefined}
      className={`cursor-pointer flex gap-[6px] px-[12px] py-[8px] ${isActive && "bg-graycool-100"} rounded-[6px] ${disabled && "opacity-20"} `}
    >
      <FontAwesomeIcon
        className="text-gray-600"
        fontSize={"20px"}
        icon={icon}
      />
      <p
        className={`hidden lg:block ${isActive ? "text-sm-sb" : "text-sm-m"} text-gray-600`}
      >
        {text}
      </p>
    </div>
  );
};

function CreationHeader() {
  const { handlePublishNow } = usePublishing();
  const { handleSavePost, isPostPublishedOrScheduled } =
    useCreationHelperFunctions();
  const { activeTab, setActiveTab, isPublishing, selectedSocialNetworks } =
    useCreationStore();
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  return (
    <>
      <ScheduleModal
        isOpen={isScheduleModalOpen}
        onClose={() => setIsScheduleModalOpen(false)}
      />
      <Tooltip id="creation-header-frames" place="top" disableStyleInjection>
        We're working to bring you better frames. Stay tuned!
      </Tooltip>
      <div className="px-[36px] py-[16px] border-b-[1px] border-b-graycool-200 flex justify-between items-center">
        <p className="display-xs-sb text-graycool-600 max-md:hidden">Creator</p>
        <div className="flex-1 flex justify-center">
          <div
            className={`${isPostPublishedOrScheduled() && "opacity-50 pointer-events-none"} flex gap-[2px]`}
          >
            <NavItem
              icon={faText}
              text="Brief"
              isActive={!isPostPublishedOrScheduled() && activeTab === "brief"}
              onClick={() => {
                if (activeTab !== "brief") setActiveTab("brief");
              }}
            />
            <NavItem
              icon={faImage}
              text="Assets"
              isActive={activeTab === "media"}
              onClick={() => {
                if (activeTab !== "media") setActiveTab("media");
              }}
            />
            <NavItem
              icon={faSquareList}
              text="Accounts"
              isActive={activeTab === "accounts"}
              onClick={() => {
                if (activeTab !== "accounts") setActiveTab("accounts");
              }}
            />
            <NavItem
              data-tooltip-id="creation-header-frames"
              disabled
              icon={faStar}
              text="Frames"
              isActive={activeTab === "elements"}
              onClick={() => {
                if (activeTab !== "elements") setActiveTab("elements");
              }}
            />
          </div>
        </div>
        <div className="flex gap-[6px]">
          <SButton
            type="secondaryGray"
            icon={faSave}
            onClick={handleSavePost}
          />
          <SOptionButon
            isLoading={!!isPublishing}
            type="secondaryColor"
            disabled={!!isPublishing || selectedSocialNetworks.length === 0}
            className="min-w-[130px]"
            label={
              isPublishing
                ? isPublishing === "publishing"
                  ? "Publishing"
                  : isPublishing === "scheduling"
                    ? "Scheduling"
                    : "Planning"
                : "Schedule"
            }
            onClick={() => setIsScheduleModalOpen(true)}
            items={[
              {
                label: "Publish Now",
                buttonProps: {
                  type: "secondaryColor",
                },
                onClick: () => {
                  handlePublishNow().catch((err) => {
                    toast(err?.response?.data?.message);
                  });
                },
              },
              {
                label: "Set Reminder",
                buttonProps: {
                  type: "secondaryGray",
                  disabled: true,
                },
                onClick: () => {
                  alert("adsa");
                },
              },
            ]}
          />
        </div>
      </div>
    </>
  );
}

export default CreationHeader;
