import { getBearerToken } from "../../../../../axios";

export const copilotChatMessageResponse = (
  tmpValue: string,
  copilotSessionId: string | null,
  socName?: string
) => {
  const response = fetch(`${process.env.REACT_APP_API_ENDPOINT}Copilot/chat`, {
    headers: {
      Authorization: getBearerToken(),
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      message: tmpValue,
      sessionId: copilotSessionId,
      socialNetworkType: socName,
    }),
  });
  return response;
};
