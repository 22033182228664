import React from "react";
import SModal from "../../../design-system/SModal";
import { useHandleDeleteBrand } from "../hooks/useHandleDeleteBrand";
import { useBrandStore } from "../../../store/brandStore";

function DeleteAgreementModal({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (p: boolean) => void;
}) {
  const { handleDelete, isLoading } = useHandleDeleteBrand();
  const { currentBrand } = useBrandStore();
  return (
    <SModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleCancel={() => setIsOpen(false)}
      handleSubmit={() => alert("asd")}
      title="Are you sure you want to delete the brand?"
      type="error"
      actionButtonText="Delete"
      actionButtonProps={{
        onClick: () => handleDelete(currentBrand!.id),
        isLoading: isLoading,
      }}
    />
  );
}

export default DeleteAgreementModal;
