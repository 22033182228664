import { faCheck, faCopy } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
//@ts-ignore
import logo from "../../assets/images/logos/stori-s-square.svg";
function AIMessage({ text }: { text: string }) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);
  return (
    <div className="h-fit flex gap-[12px] items-center relative group">
      <div className="flex gap-[12px]">
        <img src={logo} alt="" className="w-[40px] h-[40px] rounded-full" />
        <div
          className={`bg-base-white flex flex-col justify-center items-start border rounded-[8px] rounded-tl-[0] border-gray-200 py-[10px] px-[14px] max-w-[556px] 
        w-fit`}
        >
          {/* <div className="typing__animation">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div> */}
          <>
            <pre className="whitespace-pre-wrap break-words text-md text-gray-900">
              {text}
            </pre>
          </>
        </div>
      </div>
      <FontAwesomeIcon
        icon={copied ? faCheck : faCopy}
        onClick={() => {
          if (!copied) {
            navigator.clipboard.writeText(text);
            setCopied(true);
          }
        }}
        className={`${copied ? "text-success-600 cursor-default" : "text-gray-600 cursor-pointer"} hidden group-hover:block`}
      />
    </div>
  );
}

export default AIMessage;
