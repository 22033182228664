import { useEffect } from "react";
import {
  JSONFromString,
  nthIndex,
  removeAdditionalCharsFromString,
  stringBeforeJson,
} from "./jsonHelpers";
import { useUpdateBriefMessages } from "./useUpdateBriefMessages";
import { useCreationStore } from "../../../../../store/creationStore";
import { CopilotRole } from "../../../../../utils/enums/Enums";

export const useRenderAiMessage = ({
  chatResponse,
  disable,
  setDisable,
}: {
  chatResponse: string;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { briefMessages, setBriefMessages, postData } = useCreationStore();
  const { updateLastMessage } = useUpdateBriefMessages();

  useEffect(() => {
    if (
      chatResponse &&
      chatResponse.length > 0 &&
      briefMessages[briefMessages.length - 1].role === CopilotRole.ASSISTANT
    ) {
      let lastMessage = briefMessages[briefMessages.length - 1];
      if (chatResponse)
        updateLastMessage({
          content: chatResponse,
          json: JSON.parse(chatResponse),
          isSuccess: true,
          role: lastMessage.role,
        });
    }
  }, [chatResponse, disable]);
};
