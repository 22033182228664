import { useCreationStore } from "../../../store/creationStore";
import { PostStatus, SocialNetworkType } from "../../../utils/enums/Enums";
import _ from "lodash";
import { ISinglePostVM } from "../../../utils/interfaces/IPost";
import { usePutRemoveSchedule } from "../../../api/creation/removeSchedule/put";
import { useGetCurrentBrand } from "../../../api/brand/current/get";
import { toast } from "../../../hooks/toast";

export const useCreationHelperFunctions = () => {
  const { isError: isCurrentBrandError, data: currentBrand } =
    useGetCurrentBrand();
  const {
    reset,
    setPostData,
    setPostIdea,
    activeSoc,
    setIsPostOpen,
    postData,
  } = useCreationStore();
  const handleOpenPost = ({
    post,
    shouldReset = true,
    postIdea,
  }: {
    post?: ISinglePostVM;
    shouldReset?: boolean;
    postIdea?: string | null;
  }) => {
    if (
      isCurrentBrandError ||
      !currentBrand?.data.isBrandDataGenerated ||
      currentBrand?.data?.hasErrors
    ) {
      toast("Your brand data is not generated yet", "loading", {
        autoClose: 3000,
      });
      return;
    }
    if (shouldReset) reset();
    if (postIdea) setPostIdea(postIdea);
    if (post) setPostData(post);
    setIsPostOpen(true);
  };
  const getActiveSocialNetworkPost = () => {
    if (postData)
      return postData!.socialNetworkPosts.find(
        (post) => post.socialNetworkType === activeSoc
      );
  };

  const isPostPublishedOrScheduled = () => {
    return (
      getActiveSocialNetworkPost()?.postStatus === PostStatus.PUBLISHED ||
      getActiveSocialNetworkPost()?.postStatus === PostStatus.SCHEDULED
    );
  };
  const isPostPublished = () => {
    return getActiveSocialNetworkPost()?.postStatus === PostStatus.PUBLISHED;
  };
  const isPostScheduled = () => {
    return getActiveSocialNetworkPost()?.postStatus === PostStatus.SCHEDULED;
  };
  const getSocialNetworkTypeByName = (type: string) => {
    let lwrCase = type.toLowerCase();
    switch (lwrCase) {
      case "facebook":
        return SocialNetworkType.FACEBOOK;
      case "twitter":
        return SocialNetworkType.TWITTER;
      case "x":
        return SocialNetworkType.TWITTER;
      case "linkedin":
        return SocialNetworkType.LINKEDIN;
      case "instagram":
        return SocialNetworkType.INSTAGRAM;
      case "pinterest":
        return SocialNetworkType.PINTEREST;
    }
  };
  const getSocialNetworkNameByType = (type: SocialNetworkType) => {
    switch (type) {
      case SocialNetworkType.FACEBOOK:
        return "Facebook";
      case SocialNetworkType.TWITTER:
        return "Twitter";
      case SocialNetworkType.LINKEDIN:
        return "LinkedIn";
      case SocialNetworkType.INSTAGRAM:
        return "Instagram";
      case SocialNetworkType.PINTEREST:
        return "Pinterest";
    }
  };

  const getSocialNetworkPostWithSocialNetworkType = (
    soc: SocialNetworkType
  ) => {
    if (postData)
      return postData.socialNetworkPosts.find(
        (post) => post.socialNetworkType === soc
      );
  };

  const handleSavePost = () => {
    // just save images
    let tmpPostData = _.cloneDeep(postData);
  };

  return {
    isPostPublishedOrScheduled,
    isPostPublished,
    isPostScheduled,
    handleSavePost,
    getSocialNetworkPostWithSocialNetworkType,
    getActiveSocialNetworkPost,
    getSocialNetworkTypeByName,
    handleOpenPost,
    getSocialNetworkNameByType,
  };
};
