import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IFlowItem, IFlowItemStatus } from "./Flow";
import SBadge from "../../../design-system/SBadge";

function valueByStatus(status: IFlowItemStatus, arr: string[]) {
  switch (status) {
    case "todo":
      return arr[0];
    case "active":
      return arr[1];
    case "completed":
      return arr[2];
    default:
      return arr[0];
  }
}

function FlowItem({
  flowItem,
  borderBottom = true,
}: {
  flowItem: IFlowItem;
  borderBottom?: boolean;
}) {
  return (
    <div
      onClick={() => {
        if (flowItem.status === "active") {
          flowItem.callBack();
        }
      }}
      className={`${flowItem.status === "todo" && "opacity-75"} ${flowItem.status === "active" && "cursor-pointer"} w-full`}
    >
      <div className="flex gap-[12px]">
        <div className="flex flex-col items-center">
          <div
            className={`
          min-w-[48px] min-h-[48px] max-w-[48px] max-h-[48px] flex justify-center items-center rounded-[10px] bg-base-white border border-gray-200
          ${valueByStatus(flowItem.status, ["bg-base-white", "bg-base-white", "bg-gray-100"])}
          ${valueByStatus(flowItem.status, ["border-gray-200", "border-gray-200", "border-transparent"])}
          `}
          >
            <FontAwesomeIcon
              beat={flowItem.status === "active"}
              icon={flowItem.icon}
              className={`
                ${valueByStatus(flowItem.status, ["text-gray-400", "text-gray-700", "text-gray-600"])}
                text-[18px]`}
            />
          </div>
          <div
            className={`w-[2px] h-[12px] bg-gray-200 my-[4px] ${borderBottom ? "opacity-100" : "hidden"}`}
          />
        </div>
        <div>
          <div className="flex gap-[8px] items-center">
            <p
              className={`
              ${valueByStatus(flowItem.status, ["text-gray-500", "text-gray-700", "text-gray-500"])}
              text-sm-sb`}
            >
              {flowItem.title}
            </p>
            {flowItem.status === "completed" && (
              <SBadge color="success">Completed</SBadge>
            )}
          </div>
          <p
            className={`
            ${valueByStatus(flowItem.status, ["text-gray-300", "text-gray-600", "text-gray-500"])}
            text-sm text-gray-600 mt-[2px]`}
          >
            {flowItem.description}
          </p>
        </div>
      </div>
    </div>
  );
}

export default FlowItem;
