import React, { useEffect } from "react";
import SCheckbox from "../../../design-system/SCheckbox";
import TableTrItemChannel from "./Channels/TableTrItemChannel";
import SButton from "../../../design-system/SButton";
import { faAdd } from "@fortawesome/pro-regular-svg-icons";
import TableTrItem from "./Channels/TableTrItem";
import { SocialNetworkType } from "../../../utils/enums/Enums";
import { useGetAllConnections } from "../../../api/connections/get";
import { useChannelsHelper } from "./Channels/functions/useChannelsHelper";
import { useCreationStore } from "../../../store/creationStore";
import TableTrItemSkeleton from "./Channels/TableTrItemSkeleton";

function Channels() {
  const { data: connections } = useGetAllConnections();
  const { socChannels } = useChannelsHelper();

  return (
    <>
      <div className="overflow-hidden rounded-[12px] border-[1px] border-gray-200">
        <table className="min-w-full text-xs rounded-[12px]">
          <thead className="rounded-t-[12px] border-b-[1px] border-b-gray-200">
            <tr className="text-left">
              <th className="text-sm-m text-gray-600 px-[24px] py-[20px] w-[35%]">
                Networks
              </th>
              <th className="text-sm-m text-gray-600 px-[24px] py-[20px] w-[100%]">
                Accounts
              </th>
            </tr>
          </thead>
          <tbody>
            {socChannels.map((item, i) =>
              connections ? (
                <TableTrItem
                  image={item.image}
                  title={item.name}
                  website={item.website}
                  socialNetworkType={item.socialNetworkType}
                  index={i}
                  hasBackground={i % 2 === 0}
                  pages={connections.data.filter(
                    (cItem) =>
                      cItem.socialNetworkType === item.socialNetworkType,
                  )}
                />
              ) : (
                <>
                  <TableTrItemSkeleton index={1} />
                  <TableTrItemSkeleton index={1} />
                </>
              ),
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Channels;
