import React from "react";
import SBadge from "../../../../../../design-system/SBadge";
import { ImageGenerationStyle } from "../../../../../../utils/enums/Enums";
import { useHelperInputHelperFunctions } from "../useHelperInputHelperFunctions";

function Tags({
  activeStyleTag,
  setActiveStyleTag,
}: {
  activeStyleTag: ImageGenerationStyle;
  setActiveStyleTag: React.Dispatch<React.SetStateAction<ImageGenerationStyle>>;
}) {
  const { imageGenerationStyles, getGenerationStyleNameById } =
    useHelperInputHelperFunctions();
  return (
    <>
      <div className="w-full h-[1px] bg-gray-100 mt-[16px] mb-[12px]" />
      <div className="px-[24px]">
        <p className="text-xs-m text-gray-400">Predefine Style</p>
        <div className="flex flex-wrap gap-[8px] mt-[8px]">
          {imageGenerationStyles.map((styleId) => (
            <SBadge
              onClick={() => {
                if (activeStyleTag !== styleId) {
                  setActiveStyleTag(styleId);
                }
              }}
              className={`${styleId === activeStyleTag && "bg-gray-600 border-gray-600 !text-base-white"} cursor-pointer`}
              size="lg"
              key={styleId}
            >
              {getGenerationStyleNameById(styleId)}
            </SBadge>
          ))}
        </div>
      </div>
    </>
  );
}

export default Tags;
