import { ISingleFoldersAndFilesVM } from "../../../utils/interfaces/IAssets";

export const downloadSelected = (
  selected: { id: number; isFolder: boolean }[],
  assets: ISingleFoldersAndFilesVM[]
) => {
  const fileIds = selected
    .filter((item) => !item.isFolder)
    .map((item) => item.id);
  const files = assets.filter((asset) => fileIds.includes(asset.id));

  files.forEach((file, index) => {
    setTimeout(() => {
      const link = document.createElement("a");
      link.href = file.url;
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up after the download
    }, index * 100); // Delay of 100ms between downloads
  });
};
