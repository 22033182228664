import PageHeading from "../PageHeading";
import { LibraryGridCard } from "./LibraryGridCard";
import { renderItems } from "./functions/renderItems";
import { useFolderHistory } from "./hooks/useFolderHistory";
import { LibrarySkeleton } from "./LibrarySkeleton";
import { useSelectableItems } from "../../hooks/useSelectableItems";
import { LibrarySelectNavbar } from "./LibrarySelectNavbar";
import { handleDoubleClick } from "./functions/handleDoubleClick";
import { useEffect, useState } from "react";
import { useUploadAssets } from "./hooks/useUploadAssets";
import { LibraryCreateFolderModal } from "./LibraryCreateFolderModal";
import { LibraryLoadingFilesModal } from "./LibraryLoadingFilesModal";
import { LibraryLoadingFile } from "./LibraryLoadingFile";
import { LibraryDropdown } from "./LibraryDropdown";
// @ts-ignore
import DropboxChooser from "react-dropbox-chooser";
import { useLibraryDropbox } from "./hooks/useLibraryDropbox";
import { usePutFoldersAndFiles } from "../../api/library/foldersAndFiles/put";
import { LibraryEmptyFolder } from "./LibraryEmptyFolder";
import SBreadcrumbs from "../../design-system/SBreadcrumbs";
import { toast } from "../../hooks/toast";
import ExpandedImageModal from "../utils/ExpandedImageModal";

export default function LibraryView() {
  //const [selected, setSelected] = useState(0);

  const [searchValue, setSearchValue] = useState("");
  const {
    folderHistory,
    foldersAndFiles,
    setPage,
    setFoldersAndFiles,
    setFolderHistory,
    setParentFolder,
    isFetching,
    parentFolderId,
  } = useFolderHistory({ wrapperRef: null, searchValue });
  const [clickTimeout, setClickTimeout] = useState<number | null>(null);
  const items = renderItems(foldersAndFiles);

  const { selectFile, createFolder, isPending, files, createFile, setFiles } =
    useUploadAssets({
      setFoldersAndFiles,
      setPage,
      parentFolderId,
    });

  const { selectedItems, handleSelectItem, setSelectedItems } =
    useSelectableItems(items);

  const [openModal, setOpenModal] = useState(false);
  const [openCreatePutModal, setOpenCreatePutModal] = useState(false);
  const [name, setName] = useState("");

  const { dropboxRef, onDropboxSelectSubmited, dropboxFiles, setDropboxFiles } =
    useLibraryDropbox({
      createFile,
      parentFolderId,
      isPending,
      setFoldersAndFiles,
      setPage,
    });

  useEffect(() => {
    setSelectedItems([]);
  }, [foldersAndFiles, setSelectedItems]);

  // const tabs: ITabItem[] = [
  //   {
  //     index: 0,
  //     icon: faTableCellsLarge,
  //   },
  //   {
  //     index: 1,
  //     icon: faBars,
  //   },
  // ];
  const { mutateAsync: putAssets } = usePutFoldersAndFiles({
    setFoldersAndFiles,
    setPage,
  });

  const handleCreatePutSave = async () => {
    const response = await createFolder({ name: name, parentFolderId });
    const folderIds = selectedItems
      .filter((item) => item.isFolder)
      .map((item) => item.id);
    const fileIds = selectedItems
      .filter((item) => !item.isFolder)
      .map((item) => item.id);
    putAssets({
      folderIds: folderIds,
      fileIds: fileIds,
      parentFolderId: response.data.id,
    })
      .then(() => {
        toast("Folder created successfully", "success");
        setOpenCreatePutModal(false);
        setName("");
      })
      .catch((err) => {
        toast(err.response.data.message, "error");
      });
  };

  const [expandedImage, setExpandedImage] = useState<string | null>(null);
  return (
    <div className="py-[24px] w-full">
      <LibraryLoadingFilesModal
        setDropboxFiles={setDropboxFiles}
        setFiles={setFiles}
        isPending={isPending}
      >
        {files?.map((file) => {
          return (
            <LibraryLoadingFile
              url={URL.createObjectURL(file)}
              type={file.type}
              name={file.name}
              size={file.size}
              isPending={isPending}
            />
          );
        })}
        {dropboxFiles?.map((file: any) => {
          return (
            <LibraryLoadingFile
              url={file.thumbnailLink}
              type="image/jpeg"
              icon={file.icon}
              name={file.name}
              size={file.bytes}
              isPending={isPending}
            />
          );
        })}
      </LibraryLoadingFilesModal>
      {expandedImage && (
        <ExpandedImageModal
          isOpen={!!expandedImage}
          onClose={() => setExpandedImage(null)}
          source={expandedImage}
          isVideo={
            expandedImage.includes(".mp4") ||
            expandedImage.includes(".mov") ||
            expandedImage.includes(".avi")
          }
        />
      )}
      <LibraryCreateFolderModal
        openModal={openModal}
        action="Create"
        type="Folder"
        setOpenModal={() => setOpenModal(false)}
        name={name}
        setName={(e) => setName(e.target!.value)}
        onSave={() => {
          createFolder({ name: name, parentFolderId })
            .then(() => {
              setOpenModal(false);
              setName("");
              toast("Folder created successfully", "success");
            })
            .catch((err) => {
              toast(err.response.data.message, "error");
            });
        }}
      />
      <LibraryCreateFolderModal
        openModal={openCreatePutModal}
        action="Create"
        type="Folder"
        setOpenModal={() => setOpenCreatePutModal(false)}
        name={name}
        setName={(e) => setName(e.target!.value)}
        onSave={handleCreatePutSave}
      />
      <PageHeading
        title="Brand Library"
        subtitle={"Upload and manage your marketing materials."}
        withInput={true}
        inputProps={{
          placeholder: "Search...",
          value: searchValue,
          onChange: (e) => setSearchValue(e.target.value),
        }}
        rightAdditionalContent={
          <>
            {/* <STertiaryTabs
              items={tabs}
              selected={selected}
              setSelected={setSelected}
              /> */}
            <LibraryDropdown
              handleFiles={() => selectFile({ multiple: true })}
              handleFolder={() => setOpenModal(true)}
              handleDropbox={() => dropboxRef.current?.click()}
            />
            <DropboxChooser
              appKey="js3i72s02ssirnl"
              success={(files: any) => {
                onDropboxSelectSubmited(files);
              }}
              extensions={[
                ".jpg",
                ".jpeg",
                ".png",
                ".mp4",
                ".mov",
                ".avi",
                ".wmv",
                ".flv",
                ".webm",
                ".pdf",
                ".docx",
                ".doc",
                ".xlsx",
                ".xls",
                ".txt",
              ]}
              multiselect={true}
            >
              <input ref={dropboxRef} className="hidden" />
            </DropboxChooser>
          </>
        }
      />
      <div className="flex items-center justify-between my-[20px] max-md:flex-col max-md:items-start max-md:gap-[12px]">
        <SBreadcrumbs
          needHome={false}
          needGoBack={folderHistory.length >= 2}
          onGoBack={() => {
            const prevItem = folderHistory[folderHistory.length - 2];
            folderHistory.length > 2 &&
              setFolderHistory((prev) => prev.slice(0, -1));
            setFoldersAndFiles([]);
            setPage(1);
            setParentFolder({
              id: prevItem?.id ? prevItem?.id : null,
              title: prevItem.title,
            });
          }}
          pages={folderHistory}
        />
        <LibrarySelectNavbar
          foldersAndFiles={foldersAndFiles}
          className={`${selectedItems.length > 0 ? "opacity-100" : "opacity-0 pointer-events-none"} transition-all`}
          setSelectedItems={setSelectedItems}
          selectedItems={selectedItems}
          setFoldersAndFiles={setFoldersAndFiles}
          setOpenCreatePutModal={setOpenCreatePutModal}
        />
      </div>
      <div className="">
        {items.length === 0 && !isFetching && (
          <LibraryEmptyFolder
            handleFiles={() => selectFile({ multiple: true })}
            handleFolder={() => setOpenModal(true)}
            handleDropbox={() => dropboxRef.current?.click()}
            searchValue={searchValue}
          />
        )}
        <div className="grid grid-cols-1 md2:grid-cols-[repeat(auto-fill,_minmax(275px,_1fr))] gap-4 max-md:pb-[50px]">
          {items.length > 0 &&
            items.map((item, index: number) => (
              <LibraryGridCard
                rawTitle={item.rawTitle}
                setPage={setPage}
                setFoldersAndFiles={setFoldersAndFiles}
                isSelected={selectedItems.some(
                  (selectedItem) =>
                    selectedItem.id === item.id &&
                    selectedItem.isFolder === !item.extension,
                )}
                filesCount={item.filesCount}
                onClick={() =>
                  handleSelectItem && handleSelectItem(item.id, !item.extension)
                }
                onDoubleClick={() => {
                  handleDoubleClick({
                    setPage,
                    setFoldersAndFiles,
                    clickTimeout,
                    setClickTimeout,
                    setSelectedItems,
                    cardTitle: item.cardTitle,
                    cardType: item.cardType,
                    setFolderHistory,
                    setParentFolder,
                    id: item.id,
                    setExpandedImage: setExpandedImage,
                    imageSrc: item.imageSrc,
                  });
                }}
                key={index}
                {...item}
              />
            ))}
          {isFetching && (
            <>
              <LibrarySkeleton />
              <LibrarySkeleton />
              <LibrarySkeleton />
              <LibrarySkeleton />
            </>
          )}
        </div>
        {/* {selected === 0 ? (
            <div className="flex flex-row flex-wrap gap-[16px]">
              {items.map((item, index: number) => (
                <LibraryGridCard
                  setFolderHistory={setFolderHistory}
                  setParentFolder={setParentFolder} 
                key={index} 
                  {...item} 
                />
              ))}
            </div>
          ) : (
            <LibraryList
              items={items}
              selectAll={selectAll}
              handleSelectAll={handleSelectAll}
              selectedItems={selectedItems}
              handleSelectItem={handleSelectItem}
            />
          )} */}
      </div>
    </div>
  );
}
