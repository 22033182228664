import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faGrid2, faHome } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { getLastSegment } from "../../hooks/functions/queries";
import { useLocation, useNavigate } from "react-router-dom";
import NavigationGroupItem from "./NavigationGroupItem";

export interface IPrePage {
  title: string;
  icon: IconProp;
  handleClick: () => void;
}
export interface ISidebarPage {
  title: string;
  icon: IconProp;
  path?: string;
  prePages?: IPrePage[];
  handleClick?: () => void;
  alwaysActive?: boolean;
  isSoon?: boolean;
}

function NavigationGroup({
  title,
  borderBottom = true,
  pages,
}: {
  pages: ISidebarPage[];
  title: string;
  borderBottom?: boolean;
}) {
  return (
    <div>
      <p className="text-xs-m text-gray-400 py-[6px] px-[12px]">{title}</p>
      <div className="flex flex-col gap-[4px]">
        {pages.map((item) => (
          <NavigationGroupItem {...item} />
        ))}
      </div>
    </div>
  );
}

export default NavigationGroup;
