import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IStockGroupVM } from "../../../utils/interfaces/IPost";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";
import { useCreationStore } from "../../../store/creationStore";
import { ImageGenerationStyle } from "../../../utils/enums/Enums";
import { ISingleFIleVM } from "../../../utils/interfaces/IAssets";
import { toast } from "../../../hooks/toast";

export const usePostGenerateImage = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IBaseData<ISingleFIleVM>,
    any,
    {
      story?: string;
      prompt: string;
      imageGenerationStyle: ImageGenerationStyle;
      postId: number;
    }
  >({
    mutationFn: ({ story, prompt, imageGenerationStyle, postId }) =>
      makeRequest.post("/SuggestedImages/GenerateImage", {
        story,
        prompt,
        imageGenerationStyle: imageGenerationStyle,
        postId,
      }),

    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["useGetFiles"] });
    },
  });
};
