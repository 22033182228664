import React from "react";
import TextsSection from "../TextsSection";
import { useBrandStore } from "../../../store/brandStore";
import { usePutBrand } from "../../../api/brand/put";
import _ from "lodash";
import { toast } from "../../../hooks/toast";
function Strategy() {
  const { currentBrand } = useBrandStore();
  const { mutateAsync: updateBrand } = usePutBrand();
  return (
    <>
      <TextsSection
        loadingCount={1}
        title="🎯 Strategy"
        fieldName="BrandPromise"
        subtitle="How and who we communicate with"
        isLoading={!currentBrand?.brandingData?.BrandPromise?.Description}
        texts={[
          {
            key: "strategy",
            content: currentBrand?.brandingData?.BrandPromise?.Description,
            title: "Brand Promise",
            showBorder: false,
            handleChangeText: (text: string) => {
              if (
                currentBrand &&
                currentBrand?.brandingData?.BrandPromise.Description !== text
              ) {
                let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
                tmpBrandingData.BrandPromise.Description = text;
                updateBrand({
                  brandId: currentBrand.id,
                  brandingData: tmpBrandingData,
                })
                  .then(() => {
                    toast("Brand Promise updated successfully", "success");
                  })
                  .catch((err) => {
                    toast(
                      `Failed to update Brand Promise, ${err.response.data.message}`,
                      "error",
                    );
                  });
              }
            },
          },
        ]}
      />
    </>
  );
}

export default Strategy;
