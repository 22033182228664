import React, { useEffect, useState } from "react";
import AIMessage from "./Brief/AIMessage";
import UserMessage from "./Brief/UserMessage";
import Textarea from "./Brief/Textarea";
import { useCreationStore } from "../../../store/creationStore";
import MessageLoader from "./Brief/MessageLoader";
import { useUpdatePost } from "../hooks/useUpdatePost";
import { useFillChat } from "./Brief/hooks/useFIllChat";
import { useRenderAiMessage } from "./Brief/hooks/useRenderAiMessage";
import { useBriefHelperFunctions } from "./Brief/hooks/useBriefHelperFunctions";
import { useReturnedTextVersions } from "./Brief/hooks/useReturnedTextVersions";
import { CopilotRole } from "../../../utils/enums/Enums";
import { CopilotJsonContentUserMessage } from "./Brief/hooks/IBriefInterfaces";

function Brief() {
  const { briefMessages } = useCreationStore();
  const { updateStory } = useUpdatePost();
  const [chatResponse, setChatResponse] = useState("");
  const [disable, setDisable] = useState(false);
  const [value, setValue] = useState("");
  const { sendMessage } = useBriefHelperFunctions({
    setDisable,
    setChatResponse,
    setValue,
  });
  useFillChat({ setChatResponse, setDisable, setValue });

  useRenderAiMessage({
    chatResponse,
    disable,
    setDisable,
  });

  useReturnedTextVersions();

  useEffect(() => {
    document.getElementById("copilotPostBriefChat")?.scrollTo({
      top: 1000000,
      left: 0,
      behavior: "smooth",
    });
  }, [briefMessages]);

  const handleGetStory = async (story: string) => {
    await updateStory(story);
    await sendMessage(`Generate post:${story}`, true);
  };

  return (
    <div className="bg-base-white border-[1px] px-[24px] py-[32px] rounded-[12px] h-full border-gray-200 shadow-sm flex flex-col max-md:rounded-[0px] max-md:border-0">
      <div
        id="copilotPostBriefChat"
        className="flex flex-col gap-[16px] overflow-y-auto flex-grow hide__scroll"
      >
        {briefMessages.length === 0 && (
          <>
            <MessageLoader />
          </>
        )}
        {briefMessages.map((item, i) => (
          <>
            {item.role === CopilotRole.ASSISTANT && (
              <AIMessage
                disable={disable}
                handleGetStory={handleGetStory}
                messages={briefMessages}
                index={i}
                sendMessage={sendMessage}
                msg={item}
              />
            )}
            {item.role === CopilotRole.USER && (
              <UserMessage json={item.json as CopilotJsonContentUserMessage} />
            )}
          </>
        ))}
      </div>
      <Textarea
        disable={disable}
        value={value}
        setValue={setValue}
        sendMessage={sendMessage}
      />
    </div>
  );
}

export default Brief;
