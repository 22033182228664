import { useGetAllBrands } from "../../../api/brand/getAll";
import { useDeleteBrand } from "../../../api/brand/delete";
import { usePutSwitchBrand } from "../../../api/brand/switch/put";
import { toast } from "../../../hooks/toast";
import { useGetCurrentBrand } from "../../../api/brand/current/get";
export const useHandleDeleteBrand = () => {
  const { mutateAsync: deleteBrand, isPending } = useDeleteBrand();
  const { mutateAsync: switchBrand } = usePutSwitchBrand();
  const { refetch: refetchBrands } = useGetAllBrands({includeAllWorkspaces: false});
  const { data: currentBrand } = useGetCurrentBrand();

  const handleDelete = (brandId: number) => {
    deleteBrand({ brandId })
      .then((res) => {
        localStorage.setItem("token", res.data.data.accessToken);
        refetchBrands().then(res => {
          if (brandId === currentBrand?.data.id || res.data!.data.length === 0) {
            window.location.reload();
            return;
          }
          switchBrand({ id: res.data!.data[0].id })
            .then(() => window.location.reload());
        });
      })
      .catch((err) => {
        toast("Only a team owner can delete a brand.");
      });
  };

  return { handleDelete, isLoading: isPending };
};
