import { useEffect } from "react";
import { CopilotJsonContent, ICreateTaskMessage } from "./IBriefInterfaces";
import { JSONFromString, nthIndex, stringBeforeJson } from "./jsonHelpers";
import { useCreationStore } from "../../../../../store/creationStore";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { IBaseData } from "../../../../../utils/interfaces/IBase";
import { ICopilotMessage } from "../../../../../utils/interfaces/ICopilot";
import { useGetCopilotChat } from "../../../../../api/copilot/chat/get";
import { useGetCopilotSession } from "../../../../../api/copilot/session/get";
import { CopilotRole, CopilotType } from "../../../../../utils/enums/Enums";
import { parseJSON } from "date-fns";
import { useBriefHelperFunctions } from "./useBriefHelperFunctions";

export const useFillChat = ({
  setDisable,
  setChatResponse,
  setValue,
}: {
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  setChatResponse: React.Dispatch<React.SetStateAction<string>>;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const {
    postData,
    setCopilotSessionId,
    postIdea,
    setPostIdea,
    copilotSessionId,
  } = useCreationStore();
  const { refetch: refetchCopilotMessages } = useGetCopilotChat({
    sessionId: copilotSessionId,
  });
  const { sendMessage } = useBriefHelperFunctions({
    setDisable,
    setChatResponse,
    setValue,
  });
  const { setBriefMessages, briefMessages } = useCreationStore();
  const { refetch: refetchCopilotSession } = useGetCopilotSession({
    enabled: false,
    type: CopilotType.POST,
    postId: postData?.id,
  });
  useEffect(() => {
    if (!copilotSessionId && postData?.id) {
      refetchCopilotSession().then((res) => {
        //@ts-ignore
        setCopilotSessionId(res.data.data[0].id);
      });
    }
  }, [postData]);

  useEffect(() => {
    if (postIdea && briefMessages.length > 0) {
      let isThereUserMessage = briefMessages.find(
        (item) => item.role === CopilotRole.USER
      );
      if (!isThereUserMessage) {
        sendMessage(postIdea);
        setPostIdea(null);
      }
    }
  }, [postIdea, briefMessages]);

  useEffect(() => {
    if (copilotSessionId) {
      refetchCopilotMessages().then((res) => {
        let tmp: ICreateTaskMessage[] = [];
        res!.data!.data.map((item) => {
          let tmpJson = JSON.parse(item.content);
          tmp.push({
            role: item.role,
            json: tmpJson,
            isSuccess: true,
          });
        });
        setBriefMessages(tmp);
      });
    }
  }, [copilotSessionId]);
};
