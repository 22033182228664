import React, { useEffect, useState } from "react";
import { useGetNotifications } from "../../../api/notifications/get";
import { ISingleNotification } from "../../../utils/interfaces/INotifications";
import useNotificationSocketStore from "../../../store/notificationsStore";
import NotificationItem from "../../notifications/NotificationItem";
import { notificationValueByType } from "../../../pages/Notifications";
import { NotificationType } from "../../../utils/enums/Enums";
import { useRespondFromNotification } from "../../notifications/hooks/useRespondFromNotification";
import NotificationSkeleton from "../../notifications/NotificationSkeleton";
import SButton from "../../../design-system/SButton";
import { BrandInviteMemberModal } from "../../brand/BrandInviteMemberModal";
import { useCreationHelperFunctions } from "../../creation/hooks/useCreationHelperFunctions";

function TeamActivity() {
  const [isBrandInviteModalOpen, setIsBrandInviteModalOpen] = useState(false);
  const { liveMessages, removeLiveMessage } = useNotificationSocketStore();
  const { handleOpenPost } = useCreationHelperFunctions();
  const [notifications, setNotifications] = useState<ISingleNotification[]>([]);
  const { handleClick } = useRespondFromNotification();
  const { data, isLoading, isFetching } = useGetNotifications({
    page: 1,
    pageSize: 15,
    isRead: true,
  });
  useEffect(() => {
    if (data && !isLoading && !isFetching) {
      setNotifications([...notifications, ...data.data.notifications]);
    }
  }, [data]);
  useEffect(() => {
    if (liveMessages.length > 0) {
      const lastMessage = liveMessages[liveMessages.length - 1];
      setNotifications([lastMessage, ...notifications]);
      removeLiveMessage(lastMessage.id);
    }
  }, [liveMessages]);
  return (
    <>
      {isBrandInviteModalOpen && (
        <BrandInviteMemberModal
          inviteModal={isBrandInviteModalOpen}
          setInviteModal={setIsBrandInviteModalOpen}
          isWorkspaceOnly={true}
        />
      )}
      <div className="w-full h-auto bg-base-white border border-gray-200 shadow-xs rounded-[12px] overflow-hidden">
        <div className="max-h-[64px] px-[16px] py-[20px] bg-gray-25 flex justify-between items-center border-b border-b-gray-200 relative z-[1]">
          <p className="text-md-m text-gray-900">Team Activity</p>
        </div>
        <div
          className={`
        ${data && notifications.length === 0 && "flex justify-center items-center"}
        w-full h-[calc(438px-64px)] bg-base-white p-[12px] flex flex-col gap-[6px]  overflow-auto`}
        >
          {data && notifications.length === 0 && (
            <div className="flex justify-center items-center flex-col">
              <p className="text-md-m">There is no team activity</p>
              {/* <SButton onClick={() => handleOpenPost({})} className="mt-[12px]">
                Create post
              </SButton>
              <p className="text-xs text-gray-600 my-[4px]">or</p> */}
              <SButton
                className="mt-[12px]"
                onClick={() => setIsBrandInviteModalOpen(true)}
                type="secondaryColor"
              >
                Invite user
              </SButton>
            </div>
          )}
          {notifications.map((item) => {
            const organizationName =
              item.customProperty.organizations &&
              item.customProperty.organizations[0]?.name;
            const organizationOrWorkspace = organizationName
              ? organizationName + " brand "
              : item.customProperty.workspace?.name;
            return (
              item.type !== NotificationType.INVITATION && (
                <NotificationItem
                  date={item.created}
                  title={`
                ${notificationValueByType(item.type, [
                  `${item.customProperty.userName} commented on post`,
                  `You are invited in ${organizationOrWorkspace}`,
                  `Example post ${item.customProperty.name} created`,
                  `Reminder post ${item.customProperty.name} is ready for publish`,
                  `Post ${item.customProperty.name} was created`,
                  `Post ${item.customProperty.name} was published`,
                  `Post ${item.customProperty.name} was scheduled`,
                  `Post ${item.customProperty.name} was published`,
                  `New user joined the brand`,
                ])}
              `}
                  systemNotification={
                    item.type === NotificationType.SystemNotification
                      ? item
                      : undefined
                  }
                  message={notificationValueByType(item.type, [
                    "Check it out and join the conversation!",
                    `You've received an invitation to join a new workspace. Don't miss out!`,
                    "An example post has been created for your reference. Check it out for inspiration!",
                    `Your post is ready for publishing. Review it and hit publish when you're ready`,
                    `Draft post has been successfully created. Feel free to continue editing!`,
                    `Your post is now live! Share it with your network and engage with your audience.`,
                    `Your post has been scheduled for future publication. Stay tuned!`,
                    `Your post is now live! Share it with your network and engage with your audience.`,
                    `A new member has joined our workspace. Welcome them aboard!`,
                  ])}
                  type={"info"}
                  notificationType={item.type}
                  handleCancel={() =>
                    item.type === NotificationType.INVITATION &&
                    handleClick(
                      item.id,
                      item.customProperty.id,
                      3,
                      !!organizationName
                    )
                  }
                  handleSubmit={() =>
                    item.type === NotificationType.INVITATION &&
                    handleClick(
                      item.id,
                      item.customProperty.id,
                      2,
                      !!organizationName
                    )
                  }
                />
              )
            );
          })}
          {isLoading && (
            <>
              <NotificationSkeleton />
              <NotificationSkeleton />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default TeamActivity;
