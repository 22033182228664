import PageHeading from "../components/PageHeading";
import CurrentPlan from "../components/billing/CurrentPlan";
import BillingListView from "../components/tables/BillingListView";
import BillingListSkeleton from "../components/Skeletons/BillingListSkeleton";
import CurrentPlanSkeleton from "../components/Skeletons/CurrentPlanSkeleton";
import { BillingInfo } from "../components/billing/BillingInfo";
import { useSubscriptionActions } from "../hooks/useSubscriptionActions";
import { TeamPackageStatus } from "../utils/enums/Enums";
import { faArrowUpRight, faGearComplex } from "@fortawesome/pro-regular-svg-icons";

const Billing = () => {
  const {
    upgradePlan,
    managePlan,
    activatePlan,
    currentPlan,
    isCurrentPlanLoading,
    invoices,
    isWorkspaceInvoicesLoading,
    limits,
  } = useSubscriptionActions();
  const handleClick = () => {
    if (currentPlan?.data.status === TeamPackageStatus.ACTIVE && !currentPlan?.data.isTrial) {
      return upgradePlan();
    }
    if (currentPlan?.data.status === TeamPackageStatus.PAUSED || currentPlan?.data.isTrial) {
      return managePlan();
    }
    if (currentPlan?.data.status === TeamPackageStatus.CANCELED) {
      return activatePlan();
    }
  }
  return (
    <div className="flex flex-col gap-[32px] w-full">
      {currentPlan && <BillingInfo handleClick={handleClick} type={currentPlan?.data.isTrial ? TeamPackageStatus.PAUSED : currentPlan?.data.status} />}
      {isCurrentPlanLoading ? (
        <CurrentPlanSkeleton />
      ) : (
        <CurrentPlan
          plan={currentPlan?.data.package}
          currentPrice={currentPlan?.data.currentPrice}
          limits={limits}
          buttons={[
            {
              buttonText: "Manage",
              buttonCallback:  managePlan,
              icon: faGearComplex,
            },
            {
              buttonText: "Upgrade",
              buttonCallback: upgradePlan,
              icon: faArrowUpRight,
            },
          ]}
        />
      )}
      {isWorkspaceInvoicesLoading ? (
        <BillingListSkeleton />
      ) : (
        <BillingListView items={invoices} />
      )}
    </div>
  );
};

export default Billing;
