import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../../axios";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ICopilotSessionVM } from "../../../utils/interfaces/ICopilot";
import { CopilotType } from "../../../utils/enums/Enums";

export const usePostCopilotSession = () => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<ICopilotSessionVM>, any, { type: CopilotType }>({
    mutationFn: ({ type = CopilotType.DEFAULT }: { type: CopilotType }) =>
      makeRequest.post(`/Copilot?${type ? `type=${type}` : ""}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["useGetCopilotSessions"] });
    },
  });
};
