import React, { useEffect } from "react";
import SModalBase from "../../design-system/SModalBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../design-system/SButton";

function ExpandedImageModal({
  isOpen,
  onClose,
  source,
  isVideo = false,
}: {
  isOpen: boolean;
  onClose: () => void;
  source: string;
  isVideo?: boolean;
}) {
  return (
    <SModalBase
      showX={false}
      className="relative"
      dialogPanelClassName="max-w-[80dvh]"
      id="expanded-image-modal"
      isOpen={isOpen}
      onClose={onClose}
    >
      <SButton
        onClick={onClose}
        icon={faX}
        size="sm"
        type="secondaryGray"
        className="absolute left-[calc(100%+6px)] top-0"
      />
      {!isVideo && (
        <img src={source} alt="" className={"max-h-[80dvh] object-cover"} />
      )}
      {isVideo && (
        <video autoPlay controls>
          <source src={source} type="video/mp4" />
        </video>
      )}
    </SModalBase>
  );
}

export default ExpandedImageModal;
