import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const GlobalInviteRedirectPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = !!localStorage.getItem("token");

  useEffect(() => {
    if (isAuthenticated) {
      navigate(`/${location.search}`);
    } else {
      navigate(`/signup${location.search}&isGlobal=true`);
    }
  }, []);

  return (
    <div></div>
  )
}
