import React, { useEffect, useState } from "react";
import SBadge from "../../../design-system/SBadge";
import { useBrandStore } from "../../../store/brandStore";
// @ts-ignore
import WebFont from "webfontloader";
function Typography() {
  const { currentBrand } = useBrandStore();

  const [primaryFont, setPrimaryFont] = useState<string | null>(null);
  const [secondaryFont, setSecondaryFont] = useState<string | null>(null);

  useEffect(() => {
    if (currentBrand?.brandingData?.BrandTypography?.PrimaryFont) {
      WebFont.load({
        google: {
          families: [
            currentBrand?.brandingData?.BrandTypography?.PrimaryFont,
            currentBrand?.brandingData?.BrandTypography?.SecondaryFont,
          ],
        },
        active: () => {
          setPrimaryFont(
            currentBrand?.brandingData?.BrandTypography?.PrimaryFont,
          );
          setSecondaryFont(
            currentBrand?.brandingData?.BrandTypography?.SecondaryFont,
          );
        },
      });
    }
  }, [currentBrand]);
  return (
    <div className="flex px-[32px] bg-base-white max-md:flex-col max-md:py-[16px] max-md:px-[20px]">
      <div className="flex flex-col py-[20px] gap-[12px] w-[50%] items-center pb-[12px] max-md:w-full">
        <p className="text-md-sb text-gray-800 w-full text-left mb-[14px]">
          Typography
        </p>
        <div className="mb-[30px] flex flex-col items-center">
          <SBadge dotted={true}>Primary</SBadge>
          {currentBrand?.isBrandDataGenerated &&
          currentBrand?.brandingData?.BrandTypography?.PrimaryFont ? (
            <p
              className="mt-[4px] text-[36px] font-medium text-center"
              style={{ fontFamily: primaryFont || "Inter" }}
            >
              {currentBrand?.brandingData?.BrandTypography?.PrimaryFont}
            </p>
          ) : (
            <div className="animate-pulse mt-[12px]">
              <div className="w-[120px] h-[26px] rounded-full bg-gray-200" />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col py-[20px] gap-[12px] w-[50%] items-center pb-[12px] border-l-[1px] border-l-graycool-100  max-md:w-full max-md:border-l-0">
        <p className="h-[24px] text-md-sb text-gray-800 w-full text-left mb-[14px] max-md:hidden"></p>
        <div className="mb-[30px] flex flex-col items-center">
          <SBadge dotted={false}>Secondary</SBadge>
          {currentBrand?.isBrandDataGenerated &&
          currentBrand?.brandingData?.BrandTypography?.PrimaryFont ? (
            <p
              className="mt-[4px] text-[36px] font-medium text-center"
              style={{ fontFamily: secondaryFont || "Inter" }}
            >
              {currentBrand?.brandingData?.BrandTypography?.SecondaryFont}
            </p>
          ) : (
            <div className="animate-pulse mt-[12px]">
              <div className="w-[120px] h-[26px] rounded-full bg-gray-200" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Typography;
