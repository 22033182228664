import React from "react";

function SuggestedPrompts({
  setInputValue,
}: {
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}) {
  const prompts = [
    "Generate a photograph of a Viking warlord holding an axe",
    "Generate a cityscape image featuring a bustling, futuristic metropolis with towering skyscrapers with unique, intricate designs.",
    "Generate an image bursting with vibrant colors and gradients, forming an abstract and visually striking composition.",
    "Generate a surreal landscape image featuring floating islands, upside-down mountains, and unconventional flora.",
  ];
  return (
    <>
      <div className="w-full h-[1px] bg-gray-100 mt-[12px] mb-[12px]" />
      <div className="px-[24px]">
        <p className="text-xs-m text-gray-400">Suggested prompts</p>
        <div className="mt-[8px]">
          {prompts.map((item) => (
            <p
              onClick={() => setInputValue(item)}
              className="cursor-pointer text-sm text-gray-600 px-[12px] py-[6px]"
              key={item}
            >
              {item}
            </p>
          ))}
        </div>
      </div>
    </>
  );
}

export default SuggestedPrompts;
