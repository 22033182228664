import React from "react";
//@ts-ignore
import logo from "../../../assets/images/logos/stori-s-square.svg";
import { ICreateBrandMessage } from "../hooks/ICreateBrandChatInterface";
import SButton from "../../../design-system/SButton";
function AiMessage({
  messages,
  msg,
  index,
  sendMessage,
  handleNextStep,
}: {
  handleNextStep: ({
    summery,
    website,
    name,
  }: {
    name: string;
    summery?: string;
    website?: string;
  }) => void;
  sendMessage: (t?: string) => void;
  messages: ICreateBrandMessage[];
  index: number;
  msg: ICreateBrandMessage;
}) {
  let isLoading = msg.json === undefined;

  return (
    <div className="h-fit flex gap-[12px] items-center relative group">
      <div className="flex gap-[12px]">
        <img src={logo} alt="" className="w-[40px] h-[40px] rounded-full" />
        <div
          className={`bg-base-white flex flex-col justify-center items-start border rounded-[8px] rounded-tl-[0] border-gray-200 py-[10px] px-[14px] max-w-[556px] 
        w-fit`}
        >
          {/* <div className="typing__animation">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div> */}
          {isLoading ? (
            <div className="typing__animation">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          ) : (
            <>
              <pre className="whitespace-pre-wrap break-words text-md text-gray-900">
                {msg.json &&
                  (msg.json.Type === "CopilotMessage" ||
                    msg.json?.Type === "ScrapeWebsite" ||
                    msg.json?.Type === "Summary") &&
                  msg.json.Data.CopilotMessage.length > 0 && (
                    <pre className="whitespace-pre-wrap break-words text-md text-gray-900">
                      {msg.json.Data.CopilotMessage.trim()}
                      {msg.json.Type === "Summary" && (
                        <pre className="whitespace-pre-wrap break-words text-md-m text-gray-900">
                          {msg.json.Data.SummerizedBrandInformation}
                        </pre>
                      )}
                    </pre>
                  )}
              </pre>
              {messages.length - 1 === index &&
                msg.json &&
                (msg.json.Type === "Summary" ||
                  msg.json?.Type === "ScrapeWebsite") && (
                  <div className="flex gap-[12px] justify-end mt-[12px] mb-[12px] max-md:flex-col">
                    <SButton
                      type="secondaryGray"
                      onClick={() => {
                        if (msg!.json!.Type === "Summary") {
                          sendMessage("Continue conversation");
                        } else if (msg!.json!.Type === "ScrapeWebsite") {
                          sendMessage("Change website");
                        }
                      }}
                    >
                      {msg.json.Type === "Summary"
                        ? "Continue conversation"
                        : "Change website"}
                    </SButton>
                    <SButton
                      onClick={() => {
                        if (msg!.json!.Type === "Summary") {
                          handleNextStep({
                            //@ts-ignore
                            summery: msg!.json!.Data.SummerizedBrandInformation,
                            //@ts-ignore
                            name: msg!.json!.Data.BrandName,
                          });
                        }
                        if (msg!.json!.Type === "ScrapeWebsite") {
                          handleNextStep({
                            //@ts-ignore
                            website: msg!.json!.Data.WebsiteToScrape,
                            //@ts-ignore
                            name: msg!.json!.Data.BrandName,
                          });
                        }
                      }}
                    >
                      Go to next step
                    </SButton>
                  </div>
                )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AiMessage;
