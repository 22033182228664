import { useCreationStore } from "../../../../../store/creationStore";
import { ICreateTaskMessage } from "./IBriefInterfaces";

export const useUpdateBriefMessages = () => {
  const { briefMessages, setBriefMessages } = useCreationStore();
  const updateLastMessage = (obj: ICreateTaskMessage) => {
    const tmp = [...briefMessages];
    const lastMessage = { ...tmp[tmp.length - 1] };
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        (lastMessage as any)[key] = obj[key as keyof ICreateTaskMessage];
      }
    }
    tmp[tmp.length - 1] = lastMessage;
    setBriefMessages(tmp);
  };
  return { updateLastMessage };
};
