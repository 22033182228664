import React from "react";
import CopilotInput from "./CopilotInput";
import AIMessage from "./AIMessage";
import UserMessage from "./UserMessage";
import { useCopilotStore } from "../../store/copilotStore";
import MessageSkeleton from "./MessageSkeleton";

function CopilotChat() {
  const { messages } = useCopilotStore();
  return (
    <div className="w-full h-auto flex flex-col">
      <div
        id="copilotChat"
        className="h-full w-full bg-gray-50 flex flex-col gap-[12px] p-[24px] overflow-auto"
      >
        {messages.length === 0 && (
          <>
            <MessageSkeleton />
            <MessageSkeleton isUser={true} />
            <MessageSkeleton />
          </>
        )}
        {messages.map((item) => (
          <>
            {item.role === 2 && <AIMessage text={item.content} />}
            {item.role === 0 && <UserMessage text={item.content} />}
          </>
        ))}
      </div>
      <CopilotInput />
    </div>
  );
}

export default CopilotChat;
