import React, { useEffect, useState } from "react";
import { useGetCurrentBrand } from "../../../api/brand/current/get";
import LettersAvatar from "../../utils/LettersAvatar";
import { useGetCurrentWorkspace } from "../../../api/workspaces/current/get";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faUserPlus } from "@fortawesome/pro-regular-svg-icons";
import { useGetWorkspaceUsers } from "../../../api/workspaces/users/get";
import SButton from "../../../design-system/SButton";
import InviteMemberTexts from "./InviteMemberTexts";
import { BrandInviteMemberModal } from "../../brand/BrandInviteMemberModal";
import { useDisplayMembers } from "../../brand/hooks/useDisplayMembers";
import { Tooltip } from "react-tooltip";
import WithoutBrand from "./WithoutBrand";

function MembersMap() {
  const { data: currentBrand, isError: isCurrentBrandError } =
    useGetCurrentBrand();
  const [isBrandInviteModalOpen, setIsBrandInviteModalOpen] = useState(false);
  const [isForWorkspace, setIsForWorkspace] = useState(false);
  const { activeMembers } = useDisplayMembers();
  let positions = [
    "top-[10%] right-[30%]",
    "bottom-[30%] left-[22%]",
    "top-[14%] left-[34%]",
    "bottom-[30%] right-[20%]",
    "top-[10%] left-[10%]",
    "top-[20%] right-[10%]",
    "top-[40%] left-[10%]",
    "top-[50%] right-[8%]",
  ];

  return (
    <>
      {isBrandInviteModalOpen && (
        <BrandInviteMemberModal
          inviteModal={isBrandInviteModalOpen}
          setInviteModal={setIsBrandInviteModalOpen}
          isWorkspaceOnly={isForWorkspace}
        />
      )}
      <div className="w-full h-[438px] bg-base-white border border-gray-200 shadow-xs rounded-[12px] overflow-hidden">
        <div className="max-h-[64px] px-[16px] py-[20px] bg-gray-25 flex justify-between items-center border-b border-b-gray-200 relative z-[1]">
          <p className="text-md-m text-gray-900">Brand members</p>
          <div
            onClick={() => {
              if (isCurrentBrandError) {
                setIsForWorkspace(true);
              } else {
                setIsForWorkspace(false);
              }
              setIsBrandInviteModalOpen(true);
            }}
            className="cursor-pointer flex justify-center bg-base-white items-center rounded-full border-dashed border-[1px] border-gray-300 min-w-[40px] min-h-[40px] max-w-[40px] max-h-[40px]"
          >
            {/* // */}
            <FontAwesomeIcon
              icon={faAdd}
              className="text-[16px] text-gray-400"
            />
          </div>
        </div>
        <div className="w-full h-full relative">
          <div
            className="
        w-[100%] aspect-square absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]
        shadow-sm bg-[rgba(0,0,0,0.01)] rounded-full
        "
          />
          <div
            className="
        w-[70%] aspect-square absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]
        shadow-sm bg-gray-25 rounded-full
        "
          />
          <div
            className="
        w-[200px] aspect-square absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]
        shadow-sm bg-gray-50 rounded-full flex justify-center items-center
        "
          >
            {!isCurrentBrandError &&
              currentBrand &&
              activeMembers &&
              activeMembers.data.length > 1 && (
                <div className="flex gap-[8px] items-center px-[28px]">
                  <img
                    src={currentBrand?.data?.logo}
                    className="max-w-[48px] max-h-[48px] h-fit"
                  />
                  <div>
                    <p className="text-xs text-gray-400">Brand</p>
                    <p className="text-sm-sb text-gray-700">
                      {currentBrand?.data?.name}
                    </p>
                  </div>
                </div>
              )}
            {activeMembers && activeMembers?.data.length === 1 && (
              <InviteMemberTexts
                handleInvite={() => {
                  setIsBrandInviteModalOpen(true);
                }}
              />
            )}
          </div>
          {!isCurrentBrandError &&
            activeMembers &&
            activeMembers.data.length > 1 &&
            activeMembers.data.slice(0, 8).map((item, index) => (
              <>
                <Tooltip
                  style={{
                    fontSize: "14px",
                    zIndex: "10",
                  }}
                  id={`dashboard-member-avatar-${item.email}`}
                  place="top"
                  disableStyleInjection
                >
                  {item.name}
                </Tooltip>
                <div
                  data-tooltip-id={`dashboard-member-avatar-${item.email}`}
                  className={`absolute ${positions[index]} shadow-lg border border-base-white rounded-full`}
                >
                  <img
                    src={item.photoUrl}
                    alt="avatar"
                    className="max-w-[56px] max-h-[56px] min-w-[56px] min-h-[56px] rounded-full object-contain"
                  />
                </div>
              </>
            ))}
          {isCurrentBrandError && (
            <WithoutBrand
              handleClick={() => {
                if (isCurrentBrandError) {
                  setIsForWorkspace(true);
                } else {
                  setIsForWorkspace(false);
                }
                setIsBrandInviteModalOpen(true);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default MembersMap;
