import React, { act, useEffect, useState } from "react";
//@ts-ignore
import logo from "../../../assets/images/logos/stori-s-square.svg";
import SButton from "../../../design-system/SButton";
import {
  faChevronRight,
  faFlag,
  faPlayCircle,
  faPlusCircle,
  faShareFromSquare,
  faTableRows,
  faUserPlus,
  faVideo,
} from "@fortawesome/pro-regular-svg-icons";
import { BrandInviteMemberModal } from "../../brand/BrandInviteMemberModal";
import { useNavigate } from "react-router-dom";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { IOnboardingInfo } from "../../../utils/interfaces/IWorkspace";
import { IFlowItem } from "../../dashboard/flow/Flow";
import { useCreationHelperFunctions } from "../../creation/hooks/useCreationHelperFunctions";
import _ from "lodash";
function NextStep({
  progressBarPercentage,
  onboardingInfo,
  isOnboardingInfoLoading,
}: {
  progressBarPercentage: number;
  onboardingInfo?: IBaseData<IOnboardingInfo>;
  isOnboardingInfoLoading: boolean;
}) {
  const [inviteModal, setInviteModal] = useState(false);
  const navigate = useNavigate();
  const { handleOpenPost } = useCreationHelperFunctions();
  const [data, setData] = useState<IFlowItem[]>([
    {
      key: "isBrandCreated",
      title: "Create Brand",
      description: "A few details about your company",
      status: "todo",
      icon: faFlag,
      callBack: () => {
        navigate("/create-brand");
      },
    },
    {
      key: "isPostCreated",
      title: "Create Post",
      description: "Create your first post",
      status: "todo",
      icon: faTableRows,
      callBack: () => {
        handleOpenPost({});
      },
    },
    {
      key: "workspaceHasOtherMembers",
      title: "Invite Team",
      description: "Start collaborating with your team",
      status: "todo",
      icon: faUserPlus,
      callBack: () => {
        navigate("/brand?section=members");
      },
    },
    {
      key: "isSocialAccountConnected",
      title: "Add your socials",
      description: "Share posts to your social accounts",
      status: "todo",
      icon: faShareFromSquare,
      callBack: () => {
        navigate("/brand?section=accounts");
      },
    },
  ]);

  useEffect(() => {
    if (onboardingInfo) {
      const updatedData: IFlowItem[] = _.cloneDeep(data).map((item, index) => {
        if (onboardingInfo.data[item.key]) {
          return {
            ...item,
            status: "completed",
          };
        } else if (
          index === data.findIndex((i) => !onboardingInfo.data[i.key])
        ) {
          return {
            ...item,
            status: "active",
          };
        }
        return item;
      });
      setData(updatedData);
    }
  }, [onboardingInfo]);
  const activeItem = data.find((item) => item.status === "active");
  return (
    <>
      {inviteModal && (
        <BrandInviteMemberModal
          inviteModal={inviteModal}
          setInviteModal={setInviteModal}
        />
      )}
      <div className="flex flex-col items-center justify-center w-full bg-transparent h-[224px] rounded-[8px] px-[20px] py-[16px] max-md:h-full">
        <img
          className="w-[46px] h-[46px] mb-[12px] max-w-[46px] max-h-[46px]"
          src={logo}
          alt=""
        />
        <p className="text-sm-b text-gray-900 text-center">
          {activeItem?.title}
        </p>
        <div className="w-full mt-[8px]">
          <p className="text-xs text-gray-600 text-center">
            {activeItem?.description}
          </p>
          <div className="relative mt-[12px] w-full h-[10px] rounded-full bg-brand-100">
            <div
              className={`absolute top-0 left-0 rounded-full bg-brand-600 h-[10px] w-[${progressBarPercentage}%]`}
            />
          </div>
          <div className="flex gap-[12px] mt-[16px] max-md:flex-col">
            {activeItem?.key === "isBrandCreated" && (
              <SButton
                type="secondaryColor"
                lIcon={faPlusCircle}
                size="md"
                className="w-full"
                onClick={() => activeItem?.callBack()}
              >
                Create Brand
              </SButton>
            )}
            {activeItem?.key === "isPostCreated" && (
              <SButton
                type="secondaryColor"
                lIcon={faPlusCircle}
                size="md"
                className="w-full"
                onClick={() => activeItem?.callBack()}
              >
                Create Post
              </SButton>
            )}
            {activeItem?.key === "isSocialAccountConnected" && (
              <SButton
                type="secondaryColor"
                lIcon={faPlusCircle}
                size="md"
                className="w-full"
                onClick={() => activeItem?.callBack()}
              >
                Connect Socials
              </SButton>
            )}
            {activeItem?.key === "workspaceHasOtherMembers" && (
              <SButton
                type="secondaryColor"
                lIcon={faPlusCircle}
                size="md"
                className="w-full"
                onClick={() => activeItem?.callBack()}
              >
                Invite Member
              </SButton>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NextStep;
