import React, { useEffect, useState } from "react";
import {
  faChevronLeft,
  faChevronRight,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { format, startOfWeek } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useCalendarStore } from "../store/calendarStore";
import { useCalendarHelper } from "../components/Calendar/hooks/useCalendarHelper";
import PageHeading from "../components/PageHeading";
import SButton from "../design-system/SButton";
import STertiaryTabs from "../design-system/STertiaryTabs";
import MonthView from "../components/Calendar/MonthView";
import WeekView from "../components/Calendar/WeekView";
import DayView from "../components/Calendar/DayView";
import { useCreationHelperFunctions } from "../components/creation/hooks/useCreationHelperFunctions";

export interface Event {
  date: Date;
  time: string;
  title: string;
  status: number;
}

function Calendar() {
  const navigate = useNavigate();
  const { activeView, setActiveView, currentDate, setCurrentDate } =
    useCalendarStore();
  const { handleNext, handlePrev } = useCalendarHelper();

  useEffect(() => {
    if (activeView === "week") {
      setCurrentDate(startOfWeek(new Date(), { weekStartsOn: 1 }));
    }
    if (activeView === "day" || activeView === "month") {
      setCurrentDate(new Date());
    }
  }, [activeView]);

  const { handleOpenPost } = useCreationHelperFunctions();

  return (
    <div className="w-full py-[24px]">
      <PageHeading
        title="Calendar"
        subtitle="Schedule new posts or review upcoming activity."
        withInput={false}
        rightAdditionalContent={
          <SButton
            type={"primary"}
            rIcon={faPlus}
            onClick={() => handleOpenPost({})}
          >
            Create New
          </SButton>
        }
      />
      <div className="flex items-center justify-between pt-[28px] pb-[16px] max-md:flex-wrap max-md:gap-[20px]">
        <STertiaryTabs
          size="sm"
          items={[
            {
              text: "Monthly",
              index: 0,
            },
            {
              text: "Weekly",
              index: 1,
            },
            {
              text: "Daily",
              index: 2,
            },
          ]}
          selected={activeView === "month" ? 0 : activeView === "week" ? 1 : 2}
          setSelected={(viewOption) => {
            if (viewOption === 0) {
              setActiveView("month");
            } else if (viewOption === 1) {
              setActiveView("week");
            } else if (viewOption === 2) {
              setActiveView("day");
            }
          }}
        />
        <div className="flex justify-between items-center gap-[16px]">
          <div className="flex gap-[12px]">
            <SButton
              icon={faChevronLeft}
              type="secondaryGray"
              size="sm"
              onClick={handlePrev}
            />
            <SButton
              icon={faChevronRight}
              type="secondaryGray"
              size="sm"
              onClick={handleNext}
            />
          </div>
          <h1 className="text-xl-sb text-gray-700 min-w-[160px] text-right">
            <time dateTime={format(currentDate, "yyyy-MM")}>
              {(activeView === "month" || activeView === "week") &&
                format(currentDate, "MMMM yyyy")}
              {activeView === "day" && format(currentDate, "d MMMM yyyy")}
            </time>
          </h1>
        </div>
      </div>
      {activeView === "month" && <MonthView />}
      {activeView === "week" && <WeekView />}
      {activeView === "day" && <DayView />}
      {/* {!isLoading && data && renderViewComponent()} */}
    </div>
  );
}
export default Calendar;
