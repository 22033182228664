import React from "react";
import SCheckbox from "../../../../design-system/SCheckbox";
import { ISocialNetworkVM } from "../../../../utils/interfaces/ISocialNetworks";

interface ITableTrItemChannel {
  page: ISocialNetworkVM;
  isChecked: boolean;
  handleSelect: (socNetwork: ISocialNetworkVM) => void;
}

function TableTrItemChannel({
  isChecked,
  handleSelect,
  page,
}: ITableTrItemChannel) {
  const initial = page?.name ? page.name.charAt(0).toUpperCase() : "";

  return (
    <div
      onClick={() => handleSelect(page)}
      className="flex items-center gap-[12px] px-[16px] rounded-[8px] 
          py-[8px] w-fit border-[1px] border-graymodern-300 bg-base-white"
    >
      <SCheckbox checked={isChecked} />
      {page?.logoUrl ? (
        <img
          src={page.logoUrl}
          alt="logo"
          className="w-[28px] h-[28px] rounded-full"
        />
      ) : (
        <div className="w-[28px] h-[28px] rounded-full bg-gray-300 flex items-center justify-center text-white">
          {initial}
        </div>
      )}
      <p className="text-sm-m text-gray-900">{page?.name}</p>
    </div>
  );
}

export default TableTrItemChannel;
