import { useQuery } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { IOnboardingInfo } from "../../../utils/interfaces/IWorkspace";
import { makeRequest } from "../../auth/axios";

export const useGetOnboardingInfo = () => {
  return useQuery<IBaseData<IOnboardingInfo>>({
    queryKey: ["useGetOnboardingInfo"],
    queryFn: async () => {
      const response = await makeRequest.get("/Workspaces/OnboardingInfo");
      return response as IBaseData<IOnboardingInfo>;
    },
  });
};
