import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { ISinglePostVM } from "../utils/interfaces/IPost";

export interface IContentStoreInitialInterface {
  posts: ISinglePostVM[];
  page: number;
  pageSize: number;
}

const initialState: IContentStoreInitialInterface = {
  posts: [],
  page: 1,
  pageSize: 20,
};

const store = (set: any) => ({
  ...initialState,
  setPosts: (posts: ISinglePostVM[]) => set({ posts }),
  setPage: (page: number) => set({ page }),
  reset: () => set(initialState),
});
export const useContentStore = create(devtools(store));
