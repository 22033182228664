import React, { useEffect, useState } from "react";
import MessageLoader from "../../creation/Tabs/Brief/MessageLoader";
import { IBrandCreationData } from "../../../pages/CreateBrand";
import Textarea from "../brand-chat/Textarea";
import AiMessage from "../brand-chat/AiMessage";
import UserMessage from "../brand-chat/UserMessage";

import { CopilotRole } from "../../../utils/enums/Enums";
import { useFillChat } from "../hooks/useFillChat";
import { useCreateBrandChatHelper } from "../hooks/useCreateBrandChatHelper";
import { ICopilotSessionVM } from "../../../utils/interfaces/ICopilot";
import {
  BrandChatUserMessage,
  ICreateBrandMessage,
} from "../hooks/ICreateBrandChatInterface";
import { useUpdateCreateBrandMessages } from "../hooks/useUpdateCreateBrandMessages";

function CreateBrandChat({
  setData,
  setCurrentStep,
  data,
  activeSession,
  setActiveSession,
}: {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  activeSession: ICopilotSessionVM | null;
  setActiveSession: React.Dispatch<
    React.SetStateAction<ICopilotSessionVM | null>
  >;
  setData: React.Dispatch<React.SetStateAction<IBrandCreationData>>;
  data: IBrandCreationData;
}) {
  const [value, setValue] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [messages, setMessages] = useState<ICreateBrandMessage[]>([]);
  const [chatResponse, setChatResponse] = useState("");
  const { sendMessage } = useCreateBrandChatHelper({
    setDisable: setDisabled,
    setChatResponse,
    setValue,
  });
  const handleSendMessage = (str?: string) => {
    if (activeSession)
      sendMessage({
        copilotSessionId: activeSession.id,
        messages,
        setMessages,
        text: str || value,
      });
  };
  useFillChat({
    disabled,
    setActiveSession,
    activeSession,
    chatResponse,
    setChatResponse,
    setMessages,
    messages,
  });
  useEffect(() => {
    document.getElementById("copilotCreateBrandChat")?.scrollTo({
      top: 1000000,
      left: 0,
      behavior: "smooth",
    });
  }, [messages]);

  const handleNextStep = ({
    summery,
    website,
    name,
  }: {
    name: string;
    summery?: string;
    website?: string;
  }) => {
    if (summery) {
      setData({ ...data, name, about: summery });
      setCurrentStep(2);
    } else if (website) {
      setData({ ...data, name, website });
      setCurrentStep(2);
    } else {
      handleSendMessage("Something went wrong, please try again");
    }
  };

  useEffect(() => {
    if (!disabled) {
      document.getElementById("create-brand-chat-textarea")?.focus();
    }
  }, [disabled]);

  return (
    <div className=" max-w-[770px] w-full h-[60dvh] max-md:h-[70dvh] bg-base-white border-[1px] px-[24px] py-[32px] rounded-[12px] border-gray-200 flex flex-col">
      <div
        id="copilotCreateBrandChat"
        className="flex flex-col gap-[16px] overflow-y-auto flex-grow hide__scroll"
      >
        {messages.length === 0 && <MessageLoader />}
        {messages.map((item, index) => (
          <>
            {item.role === CopilotRole.ASSISTANT && (
              <AiMessage
                handleNextStep={handleNextStep}
                sendMessage={handleSendMessage}
                index={index}
                messages={messages}
                msg={item}
              />
            )}
            {item.role === CopilotRole.USER && (
              <UserMessage json={item.json as BrandChatUserMessage} />
            )}
          </>
        ))}
      </div>
      <Textarea
        disable={disabled}
        sendMessage={handleSendMessage}
        value={value}
        setValue={setValue}
      />
    </div>
  );
}

export default CreateBrandChat;
