import { useEffect } from "react";
import { useGetCopilotChat } from "../../../api/copilot/chat/get";
import {
  ICopilotMessage,
  ICopilotSessionVM,
} from "../../../utils/interfaces/ICopilot";
import { ICreateBrandMessage } from "./ICreateBrandChatInterface";
import { CopilotRole } from "../../../utils/enums/Enums";
import { useUpdateCreateBrandMessages } from "./useUpdateCreateBrandMessages";

export const useFillChat = ({
  activeSession,
  setChatResponse,
  chatResponse,
  setMessages,
  messages,
  disabled,
  setActiveSession,
}: {
  disabled: boolean;
  setActiveSession: React.Dispatch<
    React.SetStateAction<ICopilotSessionVM | null>
  >;
  activeSession: ICopilotSessionVM | null;
  messages: ICreateBrandMessage[];
  chatResponse: string;
  setChatResponse: React.Dispatch<React.SetStateAction<string>>;
  setMessages: React.Dispatch<React.SetStateAction<ICreateBrandMessage[]>>;
}) => {
  const { updateLastMessage } = useUpdateCreateBrandMessages({
    messages,
    setMessages,
  });
  const { refetch: refetchCopilotMessages, isLoading } = useGetCopilotChat({
    sessionId: activeSession?.id,
  });

  useEffect(() => {
    if (activeSession) {
      refetchCopilotMessages().then((res) => {
        let tmp: ICreateBrandMessage[] = [];
        res!.data!.data.map((item) => {
          let tmpJson = JSON.parse(item.content);
          tmp.push({
            role: item.role,
            json: tmpJson,
            isSuccess: true,
          });
        });
        setMessages(tmp);
      });
    }
  }, [activeSession]);
  useEffect(() => {
    if (
      chatResponse &&
      chatResponse.length > 0 &&
      messages.length > 0 &&
      messages[messages.length - 1].role === 2
    ) {
      let tmp = [...messages];
      tmp[tmp.length - 1].content = chatResponse;
      setMessages(tmp);
    }
  }, [chatResponse]);

  // render AI Message
  useEffect(() => {
    if (
      chatResponse &&
      chatResponse.length > 0 &&
      messages[messages.length - 1].role === CopilotRole.ASSISTANT
    ) {
      let lastMessage = messages[messages.length - 1];
      if (chatResponse)
        updateLastMessage({
          content: chatResponse,
          json: JSON.parse(chatResponse),
          isSuccess: true,
          role: lastMessage.role,
        });
    }
  }, [chatResponse, disabled]);
};
