import React, { useState } from "react";
//@ts-ignore
import personalIcon from "../../../assets/icons/brand-creation-personal.png";
//@ts-ignore
import companyIcon from "../../../assets/icons/brand-creation-company.png";
import { CopilotType, OrganizationType } from "../../../utils/enums/Enums";
import SInput from "../../../design-system/SInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { CSSTransition } from "react-transition-group";
import SButton from "../../../design-system/SButton";
import { IBrandCreationData } from "../../../pages/CreateBrand";
import { Tooltip } from "react-tooltip";

const TypeCard = ({
  onClick,
  type,
  data,
}: {
  onClick: () => void;
  type: OrganizationType;
  data: IBrandCreationData;
}) => {
  return (
    <div
      onClick={onClick}
      className={`${data.type === type ? "border-brand-600" : "border-gray-200"} 
      group cursor-pointer max-w-[280px] w-full border flex flex-col items-center  bg-gray-50 rounded-[8px] pt-[12px] pb-[24px] px-[12px]`}
    >
      <img
        className="w-[62px] h-[62px] group-hover:animate-pulse"
        src={type === OrganizationType.PERSONAL ? personalIcon : companyIcon}
        alt=""
      />
      <p className="text-xl-sb text-gray-800 mt-[12px] mb-[8px]">
        {type === OrganizationType.PERSONAL ? "Personal" : "Company"}
      </p>
      <p className="text-md text-gray-600 text-center">
        {type === OrganizationType.PERSONAL
          ? "For individuals looking to stand out and connect deeply with their audience."
          : "For organizations aiming for a consistent brand identity across all platforms."}
      </p>
    </div>
  );
};

function BrandType({
  setIsChangeBrandTypeAgreementOpen,
  setData,
  data,
  setCurrentStep,
  handleCreateCopilotSession,
}: {
  setIsChangeBrandTypeAgreementOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  handleCreateCopilotSession: (t: CopilotType) => void;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setData: React.Dispatch<React.SetStateAction<IBrandCreationData>>;
  data: IBrandCreationData;
}) {
  return (
    <div className="max-w-[656px] w-full border border-gray-200 bg-base-white rounded-[8px] p-[36px]">
      <div className="flex gap-[24px] max-md:flex-col">
        <TypeCard
          onClick={async () => {
            if (data.type === OrganizationType.PERSONAL) {
              setIsChangeBrandTypeAgreementOpen(true);
            } else {
              if (data.type === OrganizationType.COMPANY) {
                setCurrentStep(1);
              } else {
                setData((prev) => ({
                  ...prev,
                  type: OrganizationType.COMPANY,
                }));
                await handleCreateCopilotSession(
                  CopilotType.ORGANIZATIONCREATIONCOMPANY,
                );
                setCurrentStep(1);
              }
            }
          }}
          data={data}
          type={OrganizationType.COMPANY}
        />
        <TypeCard
          onClick={async () => {
            if (data.type === OrganizationType.COMPANY) {
              setIsChangeBrandTypeAgreementOpen(true);
            } else {
              if (data.type === OrganizationType.PERSONAL) {
                setCurrentStep(1);
              } else {
                setData((prev) => ({
                  ...prev,
                  type: OrganizationType.PERSONAL,
                }));
                await handleCreateCopilotSession(
                  CopilotType.ORGANIZATIONCREATIONPERSONAL,
                );
                setCurrentStep(1);
              }
            }
          }}
          data={data}
          type={OrganizationType.PERSONAL}
        />
      </div>
    </div>
  );
}

export default BrandType;
