import React, { useState } from "react";
import PostCard, { IPostCard } from "./PostCard";
import { PostStatus } from "../../utils/enums/Enums";
import { ISSelectInputDropdownItem } from "../../design-system/SSelectInputRadioDropdown";
import { ISinglePostVM } from "../../utils/interfaces/IPost";
import { useCreationStore } from "../../store/creationStore";
import Creation from "../../pages/Creation";
import ContentSkeleton from "../Skeletons/ContentSkeleton";
//@ts-ignore
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import SButton from "../../design-system/SButton";

function PostList({
  posts,
  isLoading,
  handleOpenPost,
}: {
  handleOpenPost: (p: ISinglePostVM) => void;
  isLoading: boolean;
  posts: ISinglePostVM[];
}) {
  return (
    <>
      <div className="mt-[32px] mb-[40px]">
        <ResponsiveMasonry
          columnsCountBreakPoints={{
            350: 1,
            900: 2,
            1200: 3,
            1440: 4,
            1960: 5,
            2200: 6,
          }}
        >
          <Masonry gutter="12px">
            {isLoading && (
              <>
                <ContentSkeleton />
              </>
            )}
            {posts.map((item, i) => (
              <PostCard post={item} key={i} />
            ))}
          </Masonry>
        </ResponsiveMasonry>
        {/* <PostCard /> */}
        {/* {posts.map((item) => (
          <PostCard
            postName={item.name}
            postId={item.id}
            status={item.socialNetworkPosts[0].postStatus}
            usersItem={assigneUsersData}
            assignedIndex={0}
            handleClick={() => handleOpenPost(item)}
            socialNetworks={[1, 2]}
          />
        ))} */}
      </div>
    </>
  );
}

export default PostList;
