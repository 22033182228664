import React from "react";

function FlowItemSkeleton() {
  return (
    <div className="flex items-center space-x-2 p-4 bg-white rounded-lg shadow-sm">
      {/* Skeleton for Icon */}
      <div className="w-10 h-10 bg-gray-200 rounded-lg animate-pulse"></div>

      {/* Skeleton for Text Content */}
      <div className="flex flex-col space-y-2">
        <div className="w-32 h-4 bg-gray-200 rounded animate-pulse"></div>
        <div className="w-48 h-3 bg-gray-200 rounded animate-pulse"></div>
      </div>
    </div>
  );
}

export default FlowItemSkeleton;
