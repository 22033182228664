import React from "react";
import SBadge from "../../../../../design-system/SBadge";
import SButton from "../../../../../design-system/SButton";
import Stocks from "./DefaultState/Stocks";
import SuggestedPrompts from "./DefaultState/SuggestedPrompts";
import Tags from "./DefaultState/Tags";
import { ImageGenerationStyle } from "../../../../../utils/enums/Enums";

function DefaultState({
  setInputValue,
  setActiveTab,
  activeStyleTag,
  setActiveStyleTag,
}: {
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  setActiveTab: React.Dispatch<
    React.SetStateAction<"search" | "generator" | "stocks" | "selector">
  >;
  activeStyleTag: ImageGenerationStyle;
  setActiveStyleTag: React.Dispatch<React.SetStateAction<ImageGenerationStyle>>;
}) {
  return (
    <>
      <Tags
        activeStyleTag={activeStyleTag}
        setActiveStyleTag={setActiveStyleTag}
      />
      <SuggestedPrompts setInputValue={setInputValue} />
      <Stocks setActiveTab={setActiveTab} />
    </>
  );
}

export default DefaultState;
