import { useQuery } from "@tanstack/react-query";
import { IBaseData } from "../../utils/interfaces/IBase";
import { makeRequest } from "../auth/axios";
import { INotifications } from "../../utils/interfaces/INotifications";
export const useGetNotifications = ({
  page,
  pageSize,
  isRead = true,
}: {
  page: number;
  pageSize: number;
  isRead?: boolean;
}) => {
  return useQuery<IBaseData<INotifications>>({
    queryKey: ["useGetNotifications", page, pageSize, isRead],
    queryFn: async () => {
      let response = await makeRequest.get(
        `/Notifications?Page=${page}&PageSize=${pageSize}`
      );
      return response as IBaseData<INotifications>;
    },
  });
};
