import React from "react";
import TextsSection from "../TextsSection";
import { useBrandStore } from "../../../store/brandStore";
import { usePutBrand } from "../../../api/brand/put";
import _ from "lodash";
import { toast } from "../../../hooks/toast";
function Identity() {
  const { currentBrand } = useBrandStore();
  const { mutateAsync: updateBrand } = usePutBrand();
  return (
    <>
      <TextsSection
        fieldName="BrandIdentity"
        loadingCount={3}
        title="📘 Brand Core"
        subtitle="How your brand is built and defined"
        isLoading={!currentBrand?.brandingData?.BrandIdentity?.Mission}
        texts={[
          {
            key: "mission",
            content: currentBrand?.brandingData?.BrandIdentity?.Mission,
            title: "Mission",
            showBorder: true,
            handleChangeText: (text: string) => {
              if (
                currentBrand &&
                currentBrand?.brandingData?.BrandIdentity?.Mission !== text
              ) {
                let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
                tmpBrandingData.BrandIdentity.Mission = text;
                updateBrand({
                  brandId: currentBrand.id,
                  brandingData: tmpBrandingData,
                })
                  .then(() => {
                    toast("Brand Identity updated successfully", "success");
                  })
                  .catch((err) => {
                    toast(
                      `Failed to update Brand Identity, ${err.response.data.message}`,
                      "error",
                    );
                  });
              }
            },
          },
          {
            key: "vision",
            content: currentBrand?.brandingData?.BrandIdentity?.Vision,
            title: "Vision",
            showBorder: true,
            handleChangeText: (text: string) => {
              if (
                currentBrand &&
                currentBrand?.brandingData?.BrandIdentity?.Vision !== text
              ) {
                let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
                tmpBrandingData.BrandIdentity.Vision = text;
                updateBrand({
                  brandId: currentBrand.id,
                  brandingData: tmpBrandingData,
                })
                  .then(() => {
                    toast("Brand Vision updated successfully", "success");
                  })
                  .catch((err) => {
                    toast(
                      `Failed to update Brand Vision, ${err.response.data.message}`,
                      "error",
                    );
                  });
              }
            },
          },
          {
            key: "value",
            content: currentBrand?.brandingData?.BrandIdentity?.Values,
            title: "Value",
            showBorder: false,
            handleChangeText: (text: string) => {
              if (
                currentBrand &&
                currentBrand?.brandingData?.BrandIdentity?.Values !== text
              ) {
                let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
                tmpBrandingData.BrandIdentity.Values = text;
                updateBrand({
                  brandId: currentBrand.id,
                  brandingData: tmpBrandingData,
                })
                  .then(() => {
                    toast("Brand Value updated successfully", "success");
                  })
                  .catch((err) => {
                    toast(
                      `Failed to update Brand Value, ${err.response.data.message}`,
                      "error",
                    );
                  });
              }
            },
          },
        ]}
      />
    </>
  );
}

export default Identity;
