import React from "react";
import { IPostSingleCommentVM } from "../../../utils/interfaces/IPost";
import { format, parseISO, setHours, setMinutes } from "date-fns";

function Message({ comment }: { comment: IPostSingleCommentVM }) {
  return (
    <div className="flex gap-[12px]">
      <img
        className="w-[40px] h-[40px] min-w-[40px] min-h-[40px] rounded-full"
        src={comment.userPhotoUrl}
        alt=""
      />
      <div className="w-full">
        <div className="flex justify-between items-center">
          <p className="text-sm-m text-gray-700">{comment.userName}</p>
          <p className="text-xs text-gray-600">
            {format(comment.created, "EEEE h:mm a")}
          </p>
        </div>
        <p
          className="
            text-md text-gray-900 px-[14px] py-[10px] mt-[6px]
            bg-gray-50 border border-gray-200 rounded-tr-[8px] rounded-br-[8px] rounded-bl-[8px]
            "
        >
          {comment.commentText}
        </p>
      </div>
    </div>
  );
}

export default Message;
