import {
  faExclamationCircle,
  faSmile,
  faSmileBeam,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SButton from "../../design-system/SButton";
import { TeamPackageStatus } from "../../utils/enums/Enums";

export interface IBillingInfo {
  type: TeamPackageStatus;
  handleClick: () => void;
}

export const BillingInfo = ({ type, handleClick }: IBillingInfo) => {
  const bgColor =
    type === TeamPackageStatus.CANCELED
      ? "bg-error-25"
      : type === TeamPackageStatus.PAUSED
        ? "bg-warning-25"
        : "bg-success-25";
  return (
    <div
      className={`w-full flex justify-between items-center py-5 px-[10px] rounded-lg ${bgColor} max-md:flex-wrap max-md:items-start max-md:gap-[12px]`}
    >
      <div className="flex gap-3 items-center">
        {type === TeamPackageStatus.CANCELED && (
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="text-error-500"
          />
        )}
        {type === TeamPackageStatus.PAUSED && (
          <FontAwesomeIcon icon={faWarning} className="text-warning-500" />
        )}
        {type === TeamPackageStatus.ACTIVE && (
          <FontAwesomeIcon icon={faSmileBeam} className="text-success-500" />
        )}
        <h1 className="text-gray-700 text-sm">
          {type === TeamPackageStatus.CANCELED &&
            `Activate your plan by adding a payment method to access all features.
          Click "Activate" to start.`}
          {type === TeamPackageStatus.PAUSED &&
            `Your trial is active, but you will not be able to access STORI soon if you don't add a payment method. Press manage plan to finish setting up your subscription.`}
          {type === TeamPackageStatus.ACTIVE &&
            `Your current subscription status is active, but you can upgrade plan anytime`}
        </h1>
      </div>
      <SButton
        onClick={handleClick}
        size="sm"
        type={
          type === TeamPackageStatus.CANCELED
            ? "primary"
            : type === TeamPackageStatus.PAUSED
              ? "secondaryGray"
              : "secondaryColor"
        }
        className={`min-w-fit ${type === TeamPackageStatus.CANCELED && "bg-error-600 hover:bg-error-700 border-error-600"}`}
      >
        {type === TeamPackageStatus.CANCELED
          ? "Activate"
          : type === TeamPackageStatus.PAUSED
            ? "Manage Plan"
            : "Upgrade Plan"}
      </SButton>
    </div>
  );
};
