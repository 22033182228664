import { useQuery } from "@tanstack/react-query";
import { BrandPostIdea } from "../../../../utils/interfaces/IBrand";
import { IBaseData } from "../../../../utils/interfaces/IBase";
import { makeRequest } from "../../../auth/axios";

export const useGetRandomPostIdea = () => {
  return useQuery<IBaseData<BrandPostIdea>>({
    queryKey: ["useGetRandomPostIdea"],
    queryFn: async () => {
      const response = await makeRequest.get(`/Organizations/PostIdeas/Random`);
      return response as IBaseData<BrandPostIdea>;
    },
  });
};
