import React from "react";
import SButton from "../../design-system/SButton";
import { faAdd, faCircle, faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { IBaseData } from "../../utils/interfaces/IBase";
import { ITeamVM } from "../../utils/interfaces/ITeam";
import LettersAvatar from "../utils/LettersAvatar";
import { usePutSwitchTeam } from "../../api/workspaces/swich/put";
import { useGetWorkspaces } from "../../api/workspaces/get";
import { IWorkspaceVM } from "../../utils/interfaces/IWorkspace";

function WorkspaceSwitcher({
  teams,
  currentTeam,
  setIsMobileMenuOpen,
}: {
  teams?: IBaseData<IWorkspaceVM[]>;
  currentTeam?: IBaseData<ITeamVM>;
  setIsMobileMenuOpen?: (open: boolean) => void;
}) {
  const { mutateAsync: switchTeam, isPending } = usePutSwitchTeam();
  return (
    <div
      className={`
        ${teams ? (teams?.data.length > 1 ? "pt-[24px]" : "pt-[12px]") : "pt-[24px]"}
          bg-base-white flex flex-col items-center absolute bottom-[100%] left-0 z-[1] w-full border-t border-t-gray-200
        `}
    >
      {/* <SButton
        size="sm"
        type="secondaryGray"
        rIcon={faAdd}
        className="mb-[12px]"
      >
        Create workspace
      </SButton> */}
      <div className="px-[20px] flex flex-col w-full max-h-[180px] overflow-auto">
        {teams &&
          teams.data.map(
            (item, index) =>
              item.id !== currentTeam?.data?.id && (
                <div
                  onClick={() => {
                    switchTeam({ teamId: item.id });
                    setIsMobileMenuOpen && setIsMobileMenuOpen(false);
                  }}
                  className={`${index !== teams.data.length - 1 ? "border-b" : "border-b-0"} border-b-gray-200 py-[20px] flex items-center justify-between gap-[6px] cursor-pointer`}
                >
                  <div className="flex gap-[12px] items-center">
                    <LettersAvatar text={item.name} />
                    <p className="text-sm-sb text-gray-700">{item.name}</p>
                  </div>
                  <SButton
                    icon={faCircle}
                    disabled={isPending}
                    type="tertiaryGray"
                  />
                </div>
              ),
          )}
      </div>
    </div>
  );
}

export default WorkspaceSwitcher;
