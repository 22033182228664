import React from "react";
//@ts-ignore
import logo from "../../../assets/images/logos/stori-s-square.svg";
import SButton from "../../../design-system/SButton";
import {
  faBook,
  faBookOpen,
  faPlayCircle,
  faVideo,
} from "@fortawesome/pro-regular-svg-icons";
function Introduce() {
  return (
    <div className="flex flex-col items-center justify-center w-full h-[224px] bg-transparent rounded-[8px] px-[20px] py-[16px] max-md:h-full">
      <img
        className="w-[46px] h-[46px] mb-[12px] max-w-[46px] max-h-[46px]"
        src={logo}
        alt="stori-logo"
      />
      <p className="text-sm-b text-gray-900">Introducing STORI</p>
      <div className="flex gap-[12px] w-full mt-[16px] max-md:flex-col">
        <SButton
          type="secondaryColor"
          lIcon={faBookOpen}
          size="md"
          className="w-full max-md:h-fit"
          onClick={() => {
            window.open("https://help.storiai.com", "_blank");
          }}
        >
          Guide
        </SButton>
        <SButton
          type="secondaryGray"
          lIcon={faVideo}
          size="md"
          className="w-full max-md:h-fit "
          onClick={() => {
            window.open(
              "https://calendly.com/d/5b7-5yy-4fy/stori-demo",
              "_blank",
            );
          }}
        >
          Demo
        </SButton>
      </div>
    </div>
  );
}

export default Introduce;
