import { useState } from "react";

type AcceptOption = string[] | "images" | "videos" | "image/png" | "image/jpg";

interface SelectFileOptions {
  multiple?: boolean;
  accept?: AcceptOption;
}

export const useUploadFiles = () => {
  const [files, setFiles] = useState<File[] | null>(null);
  const selectFile = (options: SelectFileOptions = {}) => {
    // validTypes - one way is to pass array with extensions like - ['png', 'jpg']. second way - 'images', 'videos'
    const { multiple = false, accept } = options;
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.multiple = multiple;
    if (accept) {
      if (Array.isArray(accept)) {
        fileInput.accept = accept.map((type) => `.${type}`).join(",");
      } else {
        if (accept === "images") {
          const types = ["png", "jpg", "jpeg"];
          fileInput.accept = types.map((type) => `.${type}`).join(",");
        }
        if (accept === "videos") {
          const types = ["mp4", "mov", "avi", "wmv", "flv", "webm", "mkv"];
          fileInput.accept = types.map((type) => `.${type}`).join(",");
        }
      }
    } else {
      fileInput.accept = "";
    }
    fileInput.style.display = "none";
    document.body.appendChild(fileInput);

    fileInput.click();

    fileInput.addEventListener("change", (e) => {
      const target = e.target as HTMLInputElement; // Type assertion
      const selectedFiles = target.files ? Array.from(target.files) : [];
      setFiles(selectedFiles);
      document.body.removeChild(fileInput);
    });
  };

  return { selectFile, files, setFiles };
};
