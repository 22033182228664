import { useCreationStore } from "../../../../../store/creationStore";
import {
  CopilotRole,
  SocialNetworkType,
  SocialNetworkTypeName,
} from "../../../../../utils/enums/Enums";
import { useCreationHelperFunctions } from "../../../hooks/useCreationHelperFunctions";
import { copilotChatMessageResponse } from "./copilotChatMessageResponse";
import { ICreateTaskMessage } from "./IBriefInterfaces";

const onlyForOneSocMedia = ({
  socMediaTypeName,
  textVersion,
}: {
  socMediaTypeName: string;
  textVersion: string;
}) =>
  `Work on this text: ${textVersion}. Make changes for only this text. return object as ${socMediaTypeName} Text`;

export const useBriefHelperFunctions = ({
  setDisable,
  setChatResponse,
  setValue,
}: {
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  setChatResponse: React.Dispatch<React.SetStateAction<string>>;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const {
    postData,
    copilotSessionId,
    briefMessages,
    activeSoc,
    setBriefMessages,
  } = useCreationStore();

  const { getSocialNetworkNameByType, getActiveSocialNetworkPost } =
    useCreationHelperFunctions();
  const fetchStream = async ({
    tmpValue,
    socName,
  }: {
    tmpValue: string;
    socName?: string;
  }) => {
    const response = await copilotChatMessageResponse(
      tmpValue,
      copilotSessionId,
      socName
    );
    const reader = response.body?.getReader();
    let buffer = "";

    const sleep = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    const typeText = async (text: string) => {
      const groupSize = getGroupSize(text.length);
      const typingDelay = 0;

      for (let i = 0; i < text.length; i += groupSize) {
        buffer += text.slice(i, i + groupSize);
        // await setChatResponseAsync(buffer);
        await sleep(typingDelay);
      }
    };

    const setChatResponseAsync = (data: string) => {
      return new Promise((resolve) => {
        setChatResponse(data);
        resolve(data);
      });
    };

    const getGroupSize = (length: number) => {
      if (length <= 100) return 3;
      if (length <= 200) return 10;
      if (length <= 300) return 20;
      return 30;
    };

    while (reader) {
      const { value, done } = await reader.read();

      if (done) {
        setChatResponseAsync(buffer);
        setDisable(false);
        break;
      }

      const chunkText = new TextDecoder("utf-8")
        .decode(value)
        .replace(/undefined/g, "");
      await typeText(chunkText);
    }
  };

  const sendMessage = async (text: string, storyUpdated: boolean = false) => {
    setDisable(true);
    setChatResponse("");
    setValue("");
    const userMessage: ICreateTaskMessage = {
      role: CopilotRole.USER,
      content: text,
      json: {
        Type: "UserMessage",
        Data: {
          UserMessage: text,
          AdditionalContent: undefined,
          SocialNetworkType: undefined,
        },
      },
      isSuccess: true,
    };
    const aiMessage: ICreateTaskMessage = {
      role: CopilotRole.ASSISTANT,
      content: "",
      isSuccess: false,
    };
    let params: {
      tmpValue: string;
      socName?: string;
    } = {
      tmpValue: text,
    };
    // add additional content for only one social media
    if (
      getActiveSocialNetworkPost()?.text &&
      getActiveSocialNetworkPost()!.text!.length > 0
    ) {
      params.socName = getSocialNetworkNameByType(
        getActiveSocialNetworkPost()!.socialNetworkType
      );
    }
    setBriefMessages([...briefMessages, userMessage, aiMessage]);
    await fetchStream(params);
  };

  return { sendMessage };
};
