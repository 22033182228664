import React, { useEffect, useState } from "react";
import { getQuery } from "../hooks/functions/queries";
import { useConfirmChangeEmail } from "../api/auth/changeEmail/post";
import SButton from "../design-system/SButton";
import {
  faArrowLeft,
  faEnvelopeBadge,
} from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import bgGrid from "../assets/images/grid-background.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UpdateEmailConfirm = () => {
  const userId = getQuery("userId");
  const email = getQuery("email");
  const code = getQuery("code");
  const navigate = useNavigate();
  const { mutateAsync: confirmChangeEmail } = useConfirmChangeEmail();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (userId && email && code) {
      confirmChangeEmail({ userId, email, code }).catch((err) => {
        setError(err.response.data.message);
      });
    }
  }, [userId, email, code, confirmChangeEmail]);

  return (
    <div className="w-full h-[70dvh] flex justify-center items-center">
      <div className="relative max-w-[360px] w-full flex justify-center flex-col items-center">
        <img
          className="absolute  z-0 top-[-10%] w-full h-[300px]"
          src={bgGrid}
          alt="bg"
        />

        <div className="relative z-10 w-full flex flex-col items-center justify-center">
          <div className="flex justify-center items-center rounded-[8px] w-[40px] aspect-square bg-base-white border-[1px] border-gray-200 shadow-xs">
            <FontAwesomeIcon
              icon={faEnvelopeBadge}
              fontSize="16px"
              className="text-gray-600"
            />
          </div>
          <p className="display-sm-sb text-gray-900 mt-[24px] text-center">
            {!error ? "Email confirmed successfully!" : "Something went wrong"}
          </p>
          <p className="text-md text-gray-600 mt-[12px] text-center">
            {!error
              ? "Thanks for confirmation! 🎉 You can continue using STORI now."
              : error}
          </p>
          <SButton
            onClick={() => navigate("/account")}
            size="sm"
            lIcon={faArrowLeft}
            type="linkGray"
            className="w-full !gap-[12px] mt-[24px]"
          >
            Back to STORI
          </SButton>
        </div>
      </div>
    </div>
  );
};

export default UpdateEmailConfirm;
