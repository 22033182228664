import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { IBaseData } from "../../utils/interfaces/IBase";
import { IBrandingDataVM, ISingleBrandVM } from "../../utils/interfaces/IBrand";

export const usePutBrand = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IBaseData<ISingleBrandVM>,
    Error,
    { brandId: number; brandingData: IBrandingDataVM }
  >({
    mutationFn: ({ brandId, brandingData }) =>
      makeRequest.put(`/Organizations/${brandId}/BrandingData`, {
        ...brandingData,
      }),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetBrand"] });
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentBrand"] });
    },
  });
};
