import React, { useEffect, useRef, useState } from "react";
import Head from "./Library/Head";
import { LibraryGridCard } from "../../../Library/LibraryGridCard";
import { useFolderHistory } from "../../../Library/hooks/useFolderHistory";
import ImageView from "./Library/ImageView";
import { renderItems } from "../../../Library/functions/renderItems";
import { handleDoubleClick } from "../../../Library/functions/handleDoubleClick";
import { LibrarySkeleton } from "../../../Library/LibrarySkeleton";
import { useCreationLibraryHelperFunctions } from "./hooks/useCreationLibraryHelperFunctions";
import {
  ISingleFIleVM,
  ISingleFoldersAndFilesVM,
} from "../../../../utils/interfaces/IAssets";
import { useCreationHelperFunctions } from "../../hooks/useCreationHelperFunctions";
import { useCreationStore } from "../../../../store/creationStore";
import { IBreadcrumbsItem } from "../../../../design-system/SBreadcrumbs";

function LibraryTab({
  folderHistory,
  foldersAndFiles,
  isFetching,
  setFolderHistory,
  setFoldersAndFiles,
  setPage,
  setParentFolder,
  parentFolderId,
  wrapperRef,
}: {
  wrapperRef: any;
  folderHistory: IBreadcrumbsItem[];
  setFoldersAndFiles: React.Dispatch<
    React.SetStateAction<ISingleFoldersAndFilesVM[]>
  >;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  foldersAndFiles: ISingleFoldersAndFilesVM[];
  setFolderHistory: React.Dispatch<React.SetStateAction<IBreadcrumbsItem[]>>;
  setParentFolder: React.Dispatch<
    React.SetStateAction<{
      id: number | null;
      title: string;
    } | null>
  >;
  isFetching: boolean;
  parentFolderId?: number;
}) {
  const { getActiveSocialNetworkPost } = useCreationHelperFunctions();

  const items = renderItems(foldersAndFiles);
  const { insertMediaForAllSocialNetworkType } =
    useCreationLibraryHelperFunctions();
  return (
    <div
      ref={wrapperRef}
      className="mt-[16px] overflow-auto"
      style={{ height: "calc(100% - 96px)" }}
    >
      <Head
        setFolderHistory={setFolderHistory}
        setFoldersAndFiles={setFoldersAndFiles}
        setPage={setPage}
        folderHistory={folderHistory}
        setParentFolder={setParentFolder}
      />
      <div className="mt-[16px] grid gap-[16px] grid-cols-2 lg:grid-cols-3 2xl:grid-cols-6 ">
        {items.map((item) => (
          <>
            {item.cardType === "Folder" && (
              <LibraryGridCard
                className="w-full h-[240px] lg:h-[260px] 2xl:h-[180px]"
                onClick={() => {
                  handleDoubleClick({
                    setPage,
                    setFoldersAndFiles,
                    cardTitle: item.cardTitle,
                    cardType: item.cardType,
                    setFolderHistory,
                    setParentFolder,
                    id: item.id,
                  });
                }}
                cardTitle={item.cardTitle}
                cardType={item.cardType}
                id={item.id}
                setFoldersAndFiles={setFoldersAndFiles}
                setPage={setPage}
              />
            )}
            {(item.cardType === "Image" || item.cardType === "Video") && (
              <ImageView
                isSelected={getActiveSocialNetworkPost()!.files.some(
                  (sItem) => sItem.url === item?.object?.url
                )}
                onClick={() => {
                  if (item.object) {
                    insertMediaForAllSocialNetworkType(
                      item.object as ISingleFIleVM
                    );
                  }
                }}
                source={item.imageSrc as string}
              />
            )}
          </>
        ))}
        {isFetching && (
          <LibrarySkeleton className="!w-[unset] !h-[unset] aspect-square !px-0 !py-0" />
        )}
      </div>
    </div>
  );
}

export default LibraryTab;
