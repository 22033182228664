import { Dispatch, SetStateAction } from "react";
import { useDeleteFoldersAndFiles } from "../../api/library/foldersAndFiles/delete";
import SButton from "../../design-system/SButton";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import {
  ISingleFIleVM,
  ISingleFoldersAndFilesVM,
} from "../../utils/interfaces/IAssets";
import { useCreationStore } from "../../store/creationStore";
import { useCreationHelperFunctions } from "../creation/hooks/useCreationHelperFunctions";
import { isVideo } from "./hooks/getFileType";
import { toast } from "../../hooks/toast";
import { downloadSelected } from "./functions/downloadSelected";

export function LibrarySelectNavbar({
  foldersAndFiles,
  setSelectedItems,
  selectedItems,
  className,
  setFoldersAndFiles,
  setPage,
  setOpenCreatePutModal,
}: {
  foldersAndFiles: ISingleFoldersAndFilesVM[];
  setSelectedItems: ([{ id, isFolder }]: {
    id: number;
    isFolder: boolean;
  }[]) => void;
  selectedItems: { id: number; isFolder: boolean }[];
  className?: string;
  setFoldersAndFiles?: Dispatch<SetStateAction<ISingleFoldersAndFilesVM[]>>;
  setPage?: Dispatch<SetStateAction<number>>;
  setOpenCreatePutModal: Dispatch<SetStateAction<boolean>>;
}) {
  const { mutateAsync: deleteSelected } = useDeleteFoldersAndFiles({
    setFoldersAndFiles,
    setPage,
    setSelectedItems,
  });

  const { setInAdvanceMedia, reset } = useCreationStore();
  const { handleOpenPost } = useCreationHelperFunctions();
  const handleCreatePost = () => {
    reset();
    let tmpFiles: ISingleFIleVM[] = [];
    foldersAndFiles.map((item) => {
      if (
        selectedItems.some((sItem) => sItem.id === item.id && !sItem.isFolder)
      ) {
        tmpFiles.push({ ...item, id: undefined });
      }
    });
    if (tmpFiles.length === 0) {
      toast("Please select images or video");
      return;
    }
    let isThereImage = false;
    let isThereVideo = false;
    tmpFiles.map((item) => {
      if (isVideo(item.url)) isThereVideo = true;
      else isThereImage = true;
    });
    if (isThereImage && isThereVideo) {
      toast(`You can't select video and image together`);
      return;
    }
    setInAdvanceMedia(tmpFiles);
    handleOpenPost({ shouldReset: false });
  };

  return (
    <div className={`${className} flex`}>
      <SButton
        onClick={() => {
          setSelectedItems([]);
        }}
        type="secondaryGray"
        className="rounded-r-none bg-gray-50 hover:bg-gray-200 focus:bg-gray-300"
        lIcon={faClose}
      >
        <span>
          {selectedItems.length}{" "}
          <span className={"max-md:hidden"}>Selected</span>
        </span>
      </SButton>
      <SButton
        type="secondaryGray"
        className="border-l-0 rounded-l-none rounded-r-none"
        onClick={handleCreatePost}
      >
        <span>Create Post</span>
      </SButton>
      <SButton
        type="secondaryGray"
        className="border-l-0 rounded-l-none rounded-r-none"
        onClick={() => {
          setOpenCreatePutModal(true);
        }}
      >
        <span>Create Folder</span>
      </SButton>
      <SButton
        onClick={() => {
          downloadSelected(selectedItems, foldersAndFiles);
        }}
        type="secondaryColor"
        className="border-l-0 rounded-l-none rounded-r-none"
      >
        <span>
          Download <span className={"max-md:hidden"}>Selected</span>
        </span>
      </SButton>
      <SButton
        onClick={() => {
          const folderIds = selectedItems
            .filter((item) => item.isFolder)
            .map((item) => item.id);
          const fileIds = selectedItems
            .filter((item) => !item.isFolder)
            .map((item) => item.id);

          deleteSelected({ folderIds, fileIds })
            .then(() => {
              toast("Folder deleted successfully", "success");
            })
            .catch((e) => {
              toast(e.response.data.message, "error");
            });
        }}
        type="secondaryGray"
        className="border-l-0 rounded-l-none bg-error-400 text-white"
      >
        <span>
          Delete <span className={"max-md:hidden"}>Selected</span>
        </span>
      </SButton>
    </div>
  );
}
