import { Dispatch, MouseEvent, SetStateAction, useState } from "react";
import { usePutFolder } from "../../../api/library/folders/put";
import { useDeleteFolder } from "../../../api/library/folders/delete";
import { usePutFile } from "../../../api/library/files/put";
import { useDeleteFile } from "../../../api/library/files/delete";
import { ISingleFoldersAndFilesVM } from "../../../utils/interfaces/IAssets";

export const useGridCardActions = ({
  setFoldersAndFiles,
  setPage,
  rawTitle
}: {
  setFoldersAndFiles?: Dispatch<SetStateAction<ISingleFoldersAndFilesVM[]>>;
  setPage?: Dispatch<SetStateAction<number>>;
  rawTitle?: string;
}) => {
  const [open, setOpen] = useState(false);
  const [openRename, setOpenRename] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [value, setValue] = useState(rawTitle ? rawTitle : '');

  const { mutateAsync: updateFolder } = usePutFolder({
    setFoldersAndFiles,
    setPage,
  });

  const { mutateAsync: deleteFolder } = useDeleteFolder({
    setFoldersAndFiles,
    setPage,
  });

  const { mutateAsync: updateFile } = usePutFile({
    setFoldersAndFiles,
    setPage,
  });

  const { mutateAsync: deleteFile } = useDeleteFile({
    setFoldersAndFiles,
    setPage,
  });

  const handleRename = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    setOpenRename(true);
  };

  const handleDelete = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    setOpenConfirmation(true);
  };

  const handleDownload = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>, url: string, name: string) => {
    e.stopPropagation();
    const link = document.createElement("a");
    link.href = url;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return {
    open,
    setOpen,
    openRename,
    setOpenRename,
    openConfirmation,
    setOpenConfirmation,
    value,
    setValue,
    handleRename,
    handleDelete,
    updateFolder,
    deleteFolder,
    updateFile,
    deleteFile,
    handleDownload
  };
};