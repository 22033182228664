import { faComments, faX } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function Head({ onClose }: { onClose: () => void }) {
  return (
    <div className="px-[16px] items-center justify-between flex py-[12px] bg-gray-25 border-b border-b-gray-200">
      <div className="flex gap-[12px] items-center">
        <div className="flex justify-center items-center w-[40px] h-[40px] bg-base-white border-gray-300 border rounded-[8px]">
          <FontAwesomeIcon icon={faComments} className="text-gray-700" />
        </div>
        <p className="text-sm-sb text-gray-700">Comments</p>
      </div>
      <FontAwesomeIcon
        onClick={onClose}
        icon={faX}
        className="p-[6px] text-gray-600 text-[12px] cursor-pointer"
      />
    </div>
  );
}

export default Head;
