import create from "zustand";
import { notificationSignalRService } from "../socket-services/NotificationSignalRService";
import { ISingleNotification } from "../utils/interfaces/INotifications";

interface StoreState {
  page: number;
  notifications: ISingleNotification[];
  liveMessages: ISingleNotification[];
  removeLiveMessage: (id: number) => void;
  setPage: (p: number) => void;
  setNotifications: (p: ISingleNotification[]) => void;
  initNotificationSignalR: () => void;
  receiveLiveMessage: () => void;
}

const useNotificationSocketStore = create<StoreState>((set) => ({
  page: 1,
  notifications: [],
  setPage: (page: number) => set({ page }),
  setNotifications: (notifications: ISingleNotification[]) =>
    set({ notifications }),

  liveMessages: [],
  removeLiveMessage: (id) =>
    set((state) => ({
      liveMessages: state.liveMessages.filter((msg) => msg.id !== id),
    })),
  initNotificationSignalR: () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    notificationSignalRService; // Just initialize SignalR
  },

  receiveLiveMessage: () => {
    notificationSignalRService.on<ISingleNotification>(
      "NewNotificationReceived",
      (message: ISingleNotification) => {
        set((state) => ({ liveMessages: [...state.liveMessages, message] }));
      }
    );
  },
}));

export default useNotificationSocketStore;
