import React from "react";
import { ISingleCustomFieldVM } from "../../../utils/interfaces/IBrand";
import SButton from "../../../design-system/SButton";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";

function CustomField({
  field,
  setActiveField,
  setIsCustomFieldModalOpen,
}: {
  setIsCustomFieldModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveField: React.Dispatch<
    React.SetStateAction<ISingleCustomFieldVM | undefined>
  >;
  field: ISingleCustomFieldVM;
}) {
  return (
    <div className="group rounded-[8px] overflow-hidden border-[1px] border-gray-20 max-md:flex-col">
      <div className="px-[32px] py-[33px] flex justify-between items-center gap-[16px] bg-gray-50 max-md:py-[16px] max-md:px-[20px]">
        <p className="display-xs-b text-graycool-800">{field.name}</p>
        <SButton
          onClick={() => {
            setActiveField(field);
            setIsCustomFieldModalOpen(true);
          }}
          icon={faEdit}
          type="secondaryGray"
          size="md"
          className="opacity-0 pointer-events-none group-hover:pointer-events-auto group-hover:opacity-100"
        />
      </div>
      <div className="px-[32px] py-[24px] rounded-b-[8px] bg-base-white max-md:px-[20px]">
        <div dangerouslySetInnerHTML={{ __html: field.text }} />
      </div>
    </div>
  );
}

export default CustomField;
