import React, { useState } from "react";
import SModalBase from "../../../../design-system/SModalBase";
import { useCreationHelperFunctions } from "../../hooks/useCreationHelperFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faX } from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../../../design-system/SButton";
import { useCreationLibraryHelperFunctions } from "./hooks/useCreationLibraryHelperFunctions";
import { ISingleFIleVM } from "../../../../utils/interfaces/IAssets";
import ExpandedImageModal from "../../../utils/ExpandedImageModal";

function ExpandedImages({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { selectMedia } = useCreationLibraryHelperFunctions();
  const { getActiveSocialNetworkPost } = useCreationHelperFunctions();
  const [expandedImage, setExpandedImage] = useState<string | null>(null);
  return (
    <>
      {expandedImage && (
        <ExpandedImageModal
          isOpen={!!expandedImage}
          onClose={() => setExpandedImage(null)}
          source={expandedImage}
        />
      )}
      <SModalBase
        dialogPanelClassName={"max-w-[600px] w-full"}
        isOpen={isOpen}
        onClose={onClose}
        showX={false}
      >
        <SButton
          onClick={onClose}
          icon={faX}
          size="sm"
          type="secondaryGray"
          className="absolute left-[calc(100%+6px)] top-0"
        />
        <div className="flex flex-wrap max-h-[80dvh] overflow-auto gap-[30px] justify-center h-full w-full">
          {getActiveSocialNetworkPost() &&
            getActiveSocialNetworkPost()?.files.map((item) => (
              <div className="w-[40%] aspect-square mx-[12px] relative rounded-[8px] overflow-hidden">
                <div className="absolute top-[12px] right-[12px] flex flex-col gap-[12px]">
                  <SButton
                    icon={faX}
                    type="secondaryGray"
                    className="opacity-80 rounded-full w-[32px] !h-[32px]"
                    iconProps={{
                      className: "text-[14px]",
                    }}
                    onClick={() => {
                      selectMedia(item as ISingleFIleVM);
                      if (getActiveSocialNetworkPost()?.files.length === 1) {
                        onClose();
                      }
                    }}
                  />
                  <SButton
                    icon={faExpand}
                    onClick={() => setExpandedImage(item.url)}
                    type="secondaryGray"
                    className="opacity-80 rounded-full w-[32px] !h-[32px]"
                    iconProps={{
                      className: "text-[14px]",
                    }}
                  />
                </div>
                <img
                  className="w-full h-full object-cover"
                  src={item.url}
                  alt="image"
                />
              </div>
            ))}
        </div>
      </SModalBase>
    </>
  );
}

export default ExpandedImages;
