import React, { useEffect, useState } from "react";
import PageHeading from "../../components/PageHeading";
import { useLocation, useNavigate } from "react-router-dom";
import { ITabItem } from "../../design-system/STertiaryTabs";
import BrandBook from "./BrandBook";
import BrandMembers from "./BrandMembers";
import BrandConnections from "./BrandConnections";
import BrandSettings from "./BrandSettings";
import { getQuery } from "../../hooks/functions/queries";
import RegenerateBrandModal from "../../components/brand/modals/RegenerateBrandModal";
import { useBrandStore } from "../../store/brandStore";
import { useBrandEffects } from "../../components/brand/hooks/useBrandEffects";
import { BrandInviteMemberModal } from "../../components/brand/BrandInviteMemberModal";
import SButton from "../../design-system/SButton";
import { faUserPlus } from "@fortawesome/pro-regular-svg-icons";
import { useGetUser } from "../../hooks/useUserInfo";
import { OrganizationUserRole } from "../../utils/enums/Enums";

const getBySelected = (selected: number, arr: string[]) => {
  switch (selected) {
    case 0:
      return arr[0];
    case 1:
      return arr[1];
    case 2:
      return arr[2];
    case 3:
      return arr[3];
    default:
      return arr[0];
  }
};

function Brand() {
  const [selected, setSelected] = useState(-1);
  const [isRegenerateModalOpen, setIsRegenerateModalOpen] = useState(false);
  const tabItems: ITabItem[] = [
    {
      index: 0,
      text: "Brandbook",
    },
    {
      index: 1,
      text: "Members",
    },
    {
      index: 2,
      text: "Connected accounts",
    },
    {
      index: 3,
      text: "Settings",
    },
  ];

  useBrandEffects({
    setIsRegenerateModalOpen,
    isRegenerateModalOpen,
    setSelected,
  });
  const [inviteModal, setInviteModal] = useState(false);
  const navigate = useNavigate();

  const querySection = getQuery("section");
  useEffect(() => {
    if (querySection) {
      switch (querySection) {
        case "brandbook":
          setSelected(0);
          break;
        case "members":
          setSelected(1);
          break;
        case "accounts":
          setSelected(2);
          break;
        case "settings":
          setSelected(3);
          break;
        default:
          setSelected(0);
      }
    }
  }, [querySection]);
  const { data: userInfo } = useGetUser();
  return (
    <>
      {inviteModal &&
        userInfo?.data.data.userRole !== OrganizationUserRole.VIEWER && (
          <BrandInviteMemberModal
            inviteModal={inviteModal}
            setInviteModal={setInviteModal}
          />
        )}
      {isRegenerateModalOpen &&
        userInfo?.data.data.userRole !== OrganizationUserRole.VIEWER && (
          <RegenerateBrandModal
            isOpen={isRegenerateModalOpen}
            onClose={() => setIsRegenerateModalOpen(false)}
          />
        )}
      <div className="w-full py-[24px]">
        <PageHeading
          title={getBySelected(selected, [
            "Brand Book",
            "Connected Accounts",
            "Members",
            "Settings",
          ])}
          subtitle={getBySelected(selected, [
            "Manage, edit or regenerate your brand elements and details.",
            "Manage your brand's social network accounts.",
            "Manage your brand members and their account permissions.",
            "Brand Settings.",
          ])}
          tabsProps={{
            items: tabItems,
            selected: selected,
            setSelected: (i) => {
              setSelected(i);
              // navigate(window.location.pathname);
            },
            rItems: (
              <>
                {selected === 0 &&
                  userInfo?.data.data.userRole !==
                    OrganizationUserRole.VIEWER && (
                    <SButton
                      onClick={() => setIsRegenerateModalOpen(true)}
                      children="Regenerate Brand"
                      type={"secondaryColor"}
                      size={"md"}
                    />
                  )}
                {selected === 1 &&
                  userInfo?.data.data.userRole !==
                    OrganizationUserRole.VIEWER && (
                    <SButton
                      onClick={() => setInviteModal(true)}
                      children="Add Brand Member"
                      rIcon={faUserPlus}
                      type={"secondaryColor"}
                      size={"md"}
                    />
                  )}
              </>
            ),
          }}
        />
        {selected === 0 && <BrandBook />}
        {selected === 1 && <BrandMembers setInviteModal={setInviteModal} />}
        {selected === 2 && <BrandConnections />}
        {selected === 3 && <BrandSettings />}
      </div>
    </>
  );
}

export default Brand;
