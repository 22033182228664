import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getLastSegment } from "../../hooks/functions/queries";
import { ISidebarPage } from "./NavigationGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function NavigationGroupItem({
  title,
  icon,
  path,
  prePages,
  handleClick,
  alwaysActive = false,
  isSoon = false,
}: ISidebarPage) {
  const location = useLocation();
  const lastSegment = getLastSegment(location.pathname);
  const isActive = `/${lastSegment}${location.search}` === path;
  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      <div
        className={`${isActive && "bg-gray-100"} rounded-[6px] px-[12px] group flex gap-[12px] py-[8px] items-center cursor-pointer`}
        onClick={() => {
          if (handleClick) {
            handleClick();
            navigate(path || "/");
          } else {
            navigate(path || "/");
          }
        }}
      >
        <FontAwesomeIcon
          icon={icon}
          className={`${!alwaysActive && !isActive && "group-hover:text-gray-800"} text-[16px] text-gray-600 w-[18px]`}
        />
        <p
          className={`
              text-md-sb transition-all
              ${!alwaysActive && !isActive && " group-hover:text-gray-800"}
              text-gray-700 
              `}
        >
          {title}
        </p>
        {isSoon && (
          <div className="flex items-center px-[8px] py-0 rounded-full border border-gray-200 bg-gray-50 text-gray-700 text-center text-xs-m max-md:hidden">
            soon
          </div>
        )}
      </div>
    </div>
  );
}

export default NavigationGroupItem;
