import React from "react";
import SButton from "../../../design-system/SButton";
import { useNavigate } from "react-router-dom";

function WithoutBrand({ handleClick }: { handleClick: () => void }) {
  const navigate = useNavigate();
  return (
    <div
      className="absolute top-[-30px] left-0 w-full h-full bg-[rgba(0,0,0,0.05)] flex flex-col justify-center items-center"
      style={{ backdropFilter: "blur(2px)" }}
    >
      <p className="text-md-sb text-base">Start creating your brand</p>
      <p className="mt-[4px] text-sm text-gray-500 mb-[16px]">
        or invite your team members
      </p>
      <div className={"flex gap-[12px]"}>
        <SButton
          onClick={() => navigate("/create-brand")}
          type="secondaryColor"
          size="lg"
          className="w-fit"
        >
          Create brand
        </SButton>
        <SButton
          onClick={() => handleClick()}
          type="secondaryGray"
          size="lg"
          className="w-fit"
        >
          Invite users
        </SButton>
      </div>
    </div>
  );
}

export default WithoutBrand;
