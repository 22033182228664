import { usePostStockImages } from "../../../../../api/creation/stocks/post";
import { useCreationStore } from "../../../../../store/creationStore";
import { ImageGenerationStyle } from "../../../../../utils/enums/Enums";

export const useHelperInputHelperFunctions = () => {
  const { mutateAsync: generateStock } = usePostStockImages();
  const { setStocks, setStocksPage, defaultStockStory } = useCreationStore();
  const imageGenerationStyles = [
    ImageGenerationStyle.Photorealistic,
    ImageGenerationStyle.FacePortrait,
    ImageGenerationStyle.PopArt,
    ImageGenerationStyle.Cartoon,
    ImageGenerationStyle.SplatterPaint,
    ImageGenerationStyle.BlogIllustration,
    ImageGenerationStyle.MinimalLineArt,
    ImageGenerationStyle.Neon,
    ImageGenerationStyle.AnimatedCharacter,
    ImageGenerationStyle.Painting,
  ];
  const getGenerationStyleNameById = (id: ImageGenerationStyle): string => {
    switch (id) {
      case ImageGenerationStyle.Photorealistic:
        return "Photorealistic";
      case ImageGenerationStyle.FacePortrait:
        return "Face Portrait";
      case ImageGenerationStyle.PopArt:
        return "Pop Art";
      case ImageGenerationStyle.Cartoon:
        return "Cartoon";
      case ImageGenerationStyle.SplatterPaint:
        return "Splatter Paint";
      case ImageGenerationStyle.BlogIllustration:
        return "Blog Illustration";
      case ImageGenerationStyle.MinimalLineArt:
        return "Minimal LineArt";
      case ImageGenerationStyle.Neon:
        return "Neon";
      case ImageGenerationStyle.AnimatedCharacter:
        return "Animated Character";
      case ImageGenerationStyle.Painting:
        return "Painting";
      default:
        return "Unknown Style";
    }
  };

  const handleSearchStocks = (value: string) => {
    setStocks([]);
    setStocksPage(1);
    generateStock({
      prompt: value || defaultStockStory,
      page: 1,
    }).then((res) => {
      setStocks(res.data.images);
    });
  };

  // const handleSearch = (searchValue: string) => {
  //   setLibrarySearchValue()
  // }

  return {
    getGenerationStyleNameById,
    handleSearchStocks,
    imageGenerationStyles,
  };
};
