import { useEffect } from "react";
import { useGetCurrentBrand } from "../../../api/brand/current/get";
import { usePostPost } from "../../../api/posts/post";
import { useCreationStore } from "../../../store/creationStore";
import { SocialNetworkType } from "../../../utils/enums/Enums";
import { usePostGeneratePostName } from "../../../api/creation/generateName/post";
import { useUpdatePost } from "./useUpdatePost";

export const useInitializePost = () => {
  const { postData, inAdvanceMedia, setPostData } = useCreationStore();
  const { mutateAsync: createPost } = usePostPost();
  const { mutateAsync: generateName } = usePostGeneratePostName();
  const { updateName } = useUpdatePost();
  useEffect(() => {
    if (!postData) {
      // if (inAdvanceMedia.length > 0) {
      // }
      createPost({
        socialNetworkPosts: [
          {
            socialNetworkType: SocialNetworkType.FACEBOOK,
            files: inAdvanceMedia.length > 0 ? inAdvanceMedia : undefined,
          },
          {
            socialNetworkType: SocialNetworkType.INSTAGRAM,
            files: inAdvanceMedia.length > 0 ? inAdvanceMedia : undefined,
          },
          {
            socialNetworkType: SocialNetworkType.LINKEDIN,
            files: inAdvanceMedia.length > 0 ? inAdvanceMedia : undefined,
          },
          {
            socialNetworkType: SocialNetworkType.PINTEREST,
            files: inAdvanceMedia.length > 0 ? inAdvanceMedia : undefined,
          },
          {
            socialNetworkType: SocialNetworkType.TWITTER,
            files: inAdvanceMedia.length > 0 ? inAdvanceMedia : undefined,
          },
        ],
        story: "",
      }).then((res) => {
        setPostData(res.data);
      });
    }
  }, [postData]);

  useEffect(() => {
    if (postData && !postData.name) {
      if (postData.story.length > 0) {
        generateName({ story: postData.story }).then((res) => {
          updateName(res.data.name);
        });
      } else if (
        postData.socialNetworkPosts.length > 0 &&
        postData.socialNetworkPosts[0].text &&
        postData.socialNetworkPosts[0].text?.length > 20
      ) {
        generateName({ story: postData.socialNetworkPosts[0].text }).then(
          (res) => {
            updateName(res.data.name);
          }
        );
      }
    }
  }, [postData]);
};
