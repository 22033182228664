import { useHandleOutsideClick } from "../../hooks/useHandleOutsideClick";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";

const SelectItem = ({
  handleClick,
  text,
  icon,
  avatar,
  isSelected,
}: {
  handleClick: (e: any) => void;
  isSelected: boolean;
  text: string;
  icon?: IconProp;
  avatar?: string;
}) => (
  <div
    onClick={handleClick}
    className={`flex justify-between px-[8px] py-[10px] hover:bg-gray-50 ${isSelected && "bg-gray-50"}  cursor-pointer items-center`}
  >
    <div className="flex gap-[8px] items-center w-full">
      {avatar && (
        <img
          className="w-[24px] aspect-square rounded-full object-contain"
          src={avatar}
          alt="User Avatar"
        />
      )}
      {icon && (
        <div className="flex w-[20px] aspect-square justify-center items-center">
          <FontAwesomeIcon
            icon={icon}
            fontSize={"14px"}
            className="text-gray-500 mb-[2px]"
          />
        </div>
      )}
      <p className="text-md-m text-gray-900">{text}</p>
    </div>
    {isSelected && (
      <FontAwesomeIcon
        icon={faCheck}
        fontSize={"14px"}
        className="text-brand-600"
      />
    )}
  </div>
);

export interface ISSelectInputDropdownItem {
  text: string;
  index: number;
  avatar?: string;
  icon?: IconProp;
}

export interface ISSSelectRadioInputDropdown
  extends React.HTMLAttributes<HTMLDivElement> {
  selected?: number | null;
  setSelected: (e: number | null) => void;
  items: ISSelectInputDropdownItem[];
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  excludedIds: string[];
  defaultSelect?: string;
}

function SSelectInputRadioDropdown({
  selected,
  setSelected,
  isOpen,
  setIsOpen,
  excludedIds,
  items,
  className,
  ...props
}: ISSSelectRadioInputDropdown) {
  const wrapperRef = useRef<any>();
  useHandleOutsideClick({
    callBack: () => {
      setIsOpen(false);
    },
    ref: wrapperRef,
    excludedIds: excludedIds,
  });

  return (
    <div
      ref={wrapperRef}
      className={`${isOpen ? "block" : "hidden"}  ${className} bg-base-white py-[4px] rounded-[8px] border-[1px] 
    border-gray-200 shadow-lg max-h-[320px]  overflow-y-auto`}
      {...props}
    >
      {items &&
        items.map((item, i) => (
          <SelectItem
            key={i}
            handleClick={(e) => {
              e.stopPropagation();
              setSelected(item.index);
              setIsOpen(false);
            }}
            icon={item.icon}
            isSelected={item.index === selected}
            text={item.text}
            avatar={item.avatar}
          />
        ))}
    </div>
  );
}

export default SSelectInputRadioDropdown;
