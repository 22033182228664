import {
  faMicrophone,
  faSend,
  faStop,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useCopilotHelper } from "./hooks/useCopilotHelper";
import SButton from "../../design-system/SButton";
import { useCopilotStore } from "../../store/copilotStore";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { toast } from "../../hooks/toast";

function CopilotInput() {
  const { isInputDisabled } = useCopilotStore();
  const [value, setValue] = useState("");
  const { sendMessage } = useCopilotHelper();
  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  // useEffect(() => {
  //   if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
  //     toast(
  //       "Your browser does not support speech recognition. Please try a different browser.",
  //     );
  //   }
  // }, []);
  const [lastState, setLastState] = useState("");
  useEffect(() => {
    if (listening) setValue(transcript);
  }, [transcript]);

  return (
    <div className="w-full flex gap-[12px] items-center px-[24px] py-[12px] bg-gray-50 border-t border-t-gray-200">
      <div className="h-auto relative w-full">
        <textarea
          disabled={isInputDisabled}
          value={listening ? lastState + " " + value : value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              e.preventDefault();
              if (value.length > 0) sendMessage({ value, setValue });
              setValue("");
            }
          }}
          placeholder="Send a message..."
          className={`${isInputDisabled ? "cursor-not-allowed" : "default"} h-fit max-h-[68px] disabled:bg-gray-50 pl-[16px] pr-[32px] focus:border-gray-400 focus:ring-0 focus:outline-0 outline-none resize-none w-full rounded-[12px] border-gray-300 bg-base-white text-md placeholder:text-gray-500 text-gray-800`}
        />
        <div
          onClick={() => {
            if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
              toast(
                "Your browser does not support speech recognition. Please try a different browser.",
              );
              return;
            }
            if (listening) {
              if (lastState.length > 0) setValue(lastState + " " + value);
              setLastState("");
              SpeechRecognition.stopListening();
              resetTranscript();
            } else {
              resetTranscript();
              setLastState(value);
              setValue("");
              SpeechRecognition.startListening({ continuous: true });
            }
          }}
          className={`
            ${listening ? "bg-red-200 text-red-500" : "bg-brand-600 text-base-white"}
            absolute top-[50%] translate-y-[-50%] right-[16px] w-[32px] h-[32px] 
            cursor-pointer flex justify-center items-center rounded-full
          `}
        >
          <FontAwesomeIcon
            className="text-[14px]"
            icon={listening ? faStop : faMicrophone}
            beat={listening}
          />
        </div>
      </div>
      <SButton
        onClick={() => {
          if (value.length > 0) sendMessage({ value, setValue });
          setValue("");
        }}
        disabled={isInputDisabled}
        icon={faSend}
        type="secondaryColor"
        className="rounded-full"
      />
    </div>
  );
}

export default CopilotInput;
