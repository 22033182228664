import React, { ReactNode } from "react";
import { ISingleNotification } from "../../utils/interfaces/INotifications";
import { formatDistanceToNowStrict } from "date-fns/formatDistanceToNowStrict";
import { parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import { NotificationType } from "../../utils/enums/Enums";

const NotificationsInHeaderItem = ({
  notification,
  message,
  handleCancel,
  handleSubmit,
}: {
  notification: ISingleNotification;
  message: ReactNode;
  handleSubmit?: (e: any) => void;
  handleCancel?: (e: any) => void;
}) => {
  const navigate = useNavigate();
  const ago = formatDistanceToNowStrict(
    parseISO(notification.created.toString())
  );
  return (
    <div
      className={
        "relative flex gap-[12px] py-[24px] border-b border-b-gray-200 cursor-pointer"
      }
      onClick={() => navigate("/notifications")}
    >
      <img
        src={notification.senderPhotoUrl}
        alt={`Profile ${notification.senderName}`}
        className={"rounded-full w-[48px] h-[48px] bg-gray-700 object-cover"}
      />
      <div>
        <div className={"flex gap-[8px]"}>
          <p className={"text-sm-m text-gray-700"}>{notification.senderName}</p>
          <p className={"text-xs text-gray-600"}>{ago} ago</p>
        </div>
        <p className={"text-sm text-gray-600"}>{message}</p>
        <div className={"text-sm-m mt-1 flex items-center gap-4"}>
          {notification.type === NotificationType.INVITATION && (
            <>
              <p onClick={handleSubmit} className="text-brand-600">
                Accept
              </p>
              <p onClick={handleCancel} className="text-gray-600">
                Reject
              </p>
            </>
          )}
        </div>
      </div>
      <div
        className={
          "w-[10px] h-[10px] absolute right-4 rounded-full bg-success-500"
        }
      ></div>
    </div>
  );
};

export default NotificationsInHeaderItem;
