import React, { Dispatch, SetStateAction } from "react";
import {
  faBuildingUser,
  faHardDrive,
  faImage,
  faLayerGroup,
  faNewspaper,
  faRobot,
  faUsers,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SCheckbox from "../../design-system/SCheckbox";
import { IPackageVM } from "../../utils/interfaces/ISubscriptions";
import { IPlanData } from "../../pages/UpdatePlan";
import { BillingPeriod } from "../../utils/enums/Enums";
import SBadge from "../../design-system/SBadge";

export interface Limits {
  limit: number;
  total: number;
  unit: string;
}

export interface Plan {
  name: string;
  description: string;
  price: number;
  duration: string;
  limits?: Limits[];
}

interface PlanItemProps {
  plan: IPlanData;
  index: number;
  selected: IPlanData | undefined;
  setSelected: Dispatch<SetStateAction<IPlanData | undefined>>;
  currentPackage: IPackageVM | undefined;
}

const PlanItem = ({
  plan,
  index,
  selected,
  setSelected,
  currentPackage,
}: PlanItemProps) => {
  const isSelected = selected?.details?.id === plan.details?.id;
  const planFeatures = [
    {
      icon: faBuildingUser,
      label: "Brands",
      value: plan.details?.numberOfOrganizations,
    },
    {
      icon: faHardDrive,
      label: "Storage",
      value: `${plan.details?.storageSpace}GB`,
    },
    {
      icon: faImage,
      label: "AI Image Generation",
      value: plan.details?.numberOfImageGenerationPerMonth,
    },
    {
      icon: faNewspaper,
      label: "Posts",
      value: plan.details?.numberOfPostsPerMonth,
    },
    {
      icon: faUsers,
      label: "Editors",
      value: plan.details?.numberOfEditorUsers,
    },
  ];
  return (
    <div
      className={`flex flex-col rounded-[12px] ${
        isSelected
          ? "border-[2px] border-brand-600"
          : "border-[1px] border-gray-200"
      } bg-white shadow-xs w-full cursor-pointer`}
      onClick={() => setSelected(plan)}
    >
      <div
        className={`flex justify-center items-center p-[16px] pr-[20px] gap-[16px] ${
          isSelected
            ? "border-b-[2px] border-brand-600"
            : "border-b-[1px] border-gray-200"
        }`}
      >
        <div className="flex justify-center items-center w-[28px] h-[28px]">
          <div className="flex flex-shrink-0 justify-center items-center p-[8px] w-[34px] h-[34px] rounded-full border-[4px] border-brand-50 bg-brand-100">
            <FontAwesomeIcon
              icon={faLayerGroup}
              className="text-[16px] p-[8px] text-brand-600 font-semibold"
            />
          </div>
        </div>
        <p className="flex text-md-sb text-gray-700 flex-grow">{plan.name} </p>
        {currentPackage && currentPackage.id === plan.details?.id && (
          <SBadge>Current</SBadge>
        )}
        <SCheckbox size="md" checked={isSelected} />
      </div>
      <div className="flex flex-col gap-[4px] p-[16px]">
        <p className="text-[30px] font-semibold text-gray-900">
          ${plan.details?.price}
          <span className="text-sm text-gray-600 pl-[4px]">
            {plan.details?.billingPeriod === BillingPeriod.MONTHLY
              ? "/Per month"
              : "/Annually"}
          </span>
        </p>
        <p className="text-sm text-gray-600">{plan.details?.description}</p>
      </div>
      <div className="flex flex-col gap-[16px] p-[24px]  rounded-b-[12px]">
        {planFeatures.map((feature, index) => (
          <div
            key={index}
            className="flex items-center space-x-3 text-gray-700"
          >
            <FontAwesomeIcon
              icon={feature.icon}
              className="text-brand-600 w-[20px] h-[20px]"
            />
            <span className="text-sm-m">{feature.label}:</span>
            <span className="text-sm">
              {feature.value === 9999 ? "Unlimited" : feature.value}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlanItem;
