import React, { useEffect, useState } from "react";
import { useAccountStore } from "../../../store/accountStore";
import FlowItem from "./FlowItem";
import FlowItemSkeleton from "./FlowItemSkeleton";
import _ from "lodash";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { IOnboardingInfo } from "../../../utils/interfaces/IWorkspace";
import {
  faFlag,
  faShareFromSquare,
  faTableRows,
  faUserPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useNavigate } from "react-router-dom";
import { useCreationHelperFunctions } from "../../creation/hooks/useCreationHelperFunctions";
export type IFlowItemStatus = "completed" | "todo" | "active";
export interface IFlowItem {
  title: string;
  description: string;
  status: IFlowItemStatus;
  callBack: () => void;
  icon: IconProp;
  key:
    | "isSocialAccountConnected"
    | "isBrandCreated"
    | "isPostCreated"
    | "workspaceHasOtherMembers";
}
function Flow({
  onboardingInfo,
  isOnboardingInfoLoading,
}: {
  onboardingInfo?: IBaseData<IOnboardingInfo>;
  isOnboardingInfoLoading: boolean;
}) {
  const navigate = useNavigate();
  const { personalInfo } = useAccountStore();
  const { handleOpenPost } = useCreationHelperFunctions();
  const [data, setData] = useState<IFlowItem[]>([
    {
      key: "isBrandCreated",
      title: "Start with Brand",
      description: "Tell us about your brand",
      status: "todo",
      icon: faFlag,
      callBack: () => {
        navigate("/create-brand");
      },
    },
    {
      key: "isPostCreated",
      title: "Create Post",
      description: "Start Creating Posts",
      status: "todo",
      icon: faTableRows,
      callBack: () => {
        handleOpenPost({});
      },
    },
    {
      key: "workspaceHasOtherMembers",
      title: "Invite Members",
      description: "Collaborate with your team",
      status: "todo",
      icon: faUserPlus,
      callBack: () => {
        navigate("/brand?section=members");
      },
    },
    {
      key: "isSocialAccountConnected",
      title: "Connect Social Accounts",
      description: "Share posts to your followers",
      status: "todo",
      icon: faShareFromSquare,
      callBack: () => {
        navigate("/brand?section=accounts");
      },
    },
  ]);

  useEffect(() => {
    if (onboardingInfo) {
      const updatedData: IFlowItem[] = _.cloneDeep(data).map((item, index) => {
        if (onboardingInfo.data[item.key]) {
          return {
            ...item,
            status: "completed",
          };
        } else if (
          index === data.findIndex((i) => !onboardingInfo.data[i.key])
        ) {
          return {
            ...item,
            status: "active",
          };
        }
        return item;
      });
      setData(updatedData);
    }
  }, [onboardingInfo]);

  return (
    <div className="w-full flex flex-col justify-between">
      <p className="display-xs-sb max-w-[256px] text-gray-500 w-full">
        <span className="text-gray-800">Hello, {personalInfo?.name}</span>{" "}
        please finish up setting your brand👇
      </p>
      <div
        style={{
          boxShadow:
            "0px 0px 0px 2px #FCFCFD, 0px 0px 0px 4px rgba(123, 53, 204, 0.10)",
        }}
        className="mt-[56px] bg-base-white rounded-[12px] border border-brand-600 p-[12px]"
      >
        {isOnboardingInfoLoading ? (
          <div className="flex gap-[12px] flex-col">
            <FlowItemSkeleton />
            <FlowItemSkeleton />
            <FlowItemSkeleton />
          </div>
        ) : (
          data.map((item, index) => (
            <FlowItem
              flowItem={item}
              borderBottom={data.length - 1 !== index}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default Flow;
