import React, { useState } from "react";
import BrandSwitcher from "./BrandSwitcher";
import Navigation from "./Navigation";
import HelperCardWrapper from "./HelperCardWrapper";
import ActiveWorkspace from "./ActiveWorkspace";

function Sidebar() {
  const [isBrandSelectorOpen, setIsBrandSelectorOpen] = useState(false);
  const [isWorkspaceSelectorOpen, setIsWorkspaceSelectorOpen] = useState(false);
  return (
    <div
      className={`sticky top-[6px] mt-[6px] left-[6px] z-[99] rounded-[6px] transition-all ${isBrandSelectorOpen || isWorkspaceSelectorOpen ? "bg-[rgba(0,0,0,0.04)]" : "bg-base-white"}  flex flex-col 
        h-[calc(100dvh-12px)] max-w-[280px] min-w-[280px] border border-gray-200 overflow-hidden max-md:min-w-[200px] max-md:max-w-[200px] max-sm:hidden`}
    >
      <div className="overflow-auto h-full">
        <BrandSwitcher
          isBrandSelectorOpen={isBrandSelectorOpen}
          setIsBrandSelectorOpen={setIsBrandSelectorOpen}
        />
        <Navigation />
      </div>
      <HelperCardWrapper />
      <ActiveWorkspace
        isWorkspaceSelectorOpen={isWorkspaceSelectorOpen}
        setIsWorkspaceSelectorOpen={setIsWorkspaceSelectorOpen}
      />
    </div>
  );
}

export default Sidebar;
