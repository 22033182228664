import { usePutSwitchBrand } from "../../../api/brand/switch/put";
import { useDeleteNotification } from "../../../api/notifications/delete";
import { useRespondInvitation } from "../../../api/invitations/invitationPending/put";
import { usePutSwitchTeam } from "../../../api/workspaces/swich/put";

export const useRespondFromNotification = () => {
  const { mutateAsync: respondInvite } = useRespondInvitation();
  const { mutateAsync: deleteNotification } = useDeleteNotification();
  const { mutateAsync: switchWorkspace } = usePutSwitchTeam();
  const { mutateAsync: switchBrand } = usePutSwitchBrand();
  const handleClick = async (
    notificationId: number,
    organizationId: number,
    statusId: number,
    isOrganization: boolean
  ) => {
    await respondInvite({
      id: organizationId,
      statusId: statusId,
    }).then((res) => {
      if (statusId === 2) {
        if (res.data.organizations.length > 0) {
          switchBrand({
            id: res.data.organizations[0].id,
          });
        } else {
          if (res?.data?.workspace?.id)
            switchWorkspace({
              teamId: res.data.workspace.id,
            });
        }
      }
    });
    deleteNotification({ id: notificationId });
  };

  return { handleClick };
};
