import React, { useEffect, useState } from "react";
import SButton from "../design-system/SButton";
import Creation from "./Creation";
import SDrawer from "../design-system/SDrawer";
import { useCreationStore } from "../store/creationStore";
import STable from "../design-system/STable";
import { IMembersListRow } from "../components/tables/MembersListRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/pro-regular-svg-icons";
import { toast } from "../hooks/toast";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

function Test() {
  const { transcript, resetTranscript, listening } = useSpeechRecognition();

  useEffect(() => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      alert(
        "Your browser does not support speech recognition. Please try a different browser."
      );
    }
  }, []);

  const startListening = () =>
    SpeechRecognition.startListening({ continuous: true });
  const stopListening = () => SpeechRecognition.stopListening();

  return (
    <div>
      <button onClick={startListening} disabled={listening}>
        Start Listening
      </button>
      <button onClick={stopListening} disabled={!listening}>
        Stop Listening
      </button>
      <button onClick={resetTranscript}>Reset</button>
      <p>{transcript}</p>
    </div>
  );
}

export default Test;
