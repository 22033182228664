import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faReddit,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import React from "react";
import { SocialNetworkType } from "../../../utils/enums/Enums";
import { useCreationStore } from "../../../store/creationStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";

function SocMediaSwitcher() {
  const { activeSoc, setActiveSoc } = useCreationStore();
  const socMedias = [
    {
      icon: faXTwitter,
      color: "#000000",
      type: SocialNetworkType.TWITTER,
    },
    {
      icon: faLinkedin,
      color: "#0077B5",
      type: SocialNetworkType.LINKEDIN,
    },
    {
      icon: faInstagram,
      color: "#E1306C",
      type: SocialNetworkType.INSTAGRAM,
    },
    {
      icon: faPinterest,
      color: "#E60023",
      type: SocialNetworkType.PINTEREST,
    },
    {
      icon: faFacebook,
      color: "#0866FF",
      type: SocialNetworkType.FACEBOOK,
    },
    {
      icon: faReddit,
      color: "#FF4500",
      type: SocialNetworkType.REDIT,
    },
  ];
  return (
    <div className="flex mb-[38px] border-[1px] border-gray-300 w-fit rounded-[8px] h-[40px] bg-base-white">
      <Tooltip id="creation-preview-side-switcher-redit">Coming Soon</Tooltip>
      {socMedias.map((item, i) => (
        <div
          key={item.type}
          onClick={() => {
            if (item.type !== SocialNetworkType.REDIT) {
              if (activeSoc !== item.type) {
                setActiveSoc(item.type);
              }
            }
          }}
          data-tooltip-id={
            item.type === SocialNetworkType.REDIT
              ? "creation-preview-side-switcher-redit"
              : ""
          }
          className={`${i !== 0 && "border-l-[1px]"} ${activeSoc === item.type && "bg-gray-100"} ${item.type === SocialNetworkType.REDIT ? "cursor-default" : "cursor-pointer"} flex border-l-gray-300 justify-center  h-auto w-[44px] items-center`}
        >
          <FontAwesomeIcon
            icon={item.icon}
            color={item.color}
            fontSize={"18px"}
          />
        </div>
      ))}
    </div>
  );
}

export default SocMediaSwitcher;
