import React from "react";
import Sidebar from "./Sidebar";
import Header from "../header/Header";

function SidebarWrapper({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex ">
      <Sidebar />
      <div className="w-full">
        <Header />
        <div className="w-full px-[32px] max-md:px-[16px]">{children}</div>
      </div>
    </div>
  );
}

export default SidebarWrapper;
