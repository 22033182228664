import React from "react";
//@ts-ignore
import signUpImage from "../assets/images/sign-in-image.jpg";
import Form from "../components/auth/signup/Form";
//@ts-ignore
import logo from "../assets/images/logos/stori-full.svg";
import TipsSlider from "../components/auth/signup/TipsSlider";
function Signup() {
  return (
    <div className="flex h-[100dvh] p-[16px] max-md:flex-col-reverse max-md:pt-[40px] max-md:bg-gray-50">
      <div className="w-[50%] h-full flex justify-center items-center max-md:w-full">
        <img
          alt={"logo"}
          src={logo}
          className="w-[142px] mb-[40px] absolute top-[32px] left-[32px] max-md:left-[16px] max-md:w-[100px] max-md:top-[40px]"
        />
        <Form />
      </div>
      <div className="w-[50%] h-full bg-no-repeat bg-gradient-to-br from-[#42307D] to-[#7F56D9] rounded-[20px] max-md:hidden">
        <TipsSlider />
      </div>
    </div>
  );
}

export default Signup;
