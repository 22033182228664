import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../utils/interfaces/IBase";
import { IPostCOE, ISinglePostVM } from "../../utils/interfaces/IPost";
import { makeRequest } from "../auth/axios";
import { useContentStore } from "../../store/contentStore";
import { toast } from "../../hooks/toast";

export const useDeletePost = () => {
  const queryClient = useQueryClient();
  const { setPosts, setPage } = useContentStore();
  return useMutation<IBaseData<string>, any, { postId: number }>({
    mutationFn: ({ postId }) => makeRequest.delete(`/Posts/${postId}`),
    onSuccess: (res) => {
      setPosts([]);
      setPage(1);
      queryClient.invalidateQueries({ queryKey: ["useGetAllCreation"] });
      queryClient.invalidateQueries({ queryKey: ["useGetCalendarData"] });
    },
    onError: (err) => {
      toast(err?.response?.data?.message);
    },
  });
};
