import SUploader, { ISUploaderProps } from "../../design-system/SUploader";
import React from "react";

interface IFieldAvatar {
  fieldName: string;
  className?: string;
  avatar: string;
  uploaderProps?: ISUploaderProps;
}

function FieldAvatar({
  fieldName,
  avatar,
  className,
  uploaderProps,
}: IFieldAvatar) {
  return (
    <div
      className={`${className} flex justify-between py-[20px] border-b-[1px] border-gray-200`}
    >
      <div className="flex-none w-[140px]">
        <span className="text-left text-sm-sb text-gray-700">{fieldName}</span>
      </div>
      <div className="flex-grow flex justify-center gap-[20px] max-md:flex-col">
        <img
          src={avatar}
          className="w-[64px] h-[64px] object-cover rounded-full"
        />
        <SUploader className="max-w-[380px]" {...uploaderProps} />
      </div>
      <div className="flex-none w-1/4 max-md:hidden"></div>
    </div>
  );
}

export default FieldAvatar;
