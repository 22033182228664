import { Dispatch, SetStateAction, useRef } from "react";
import SCheckbox from "../../design-system/SCheckbox";
import { IBrandUsersVM, ISingleBrandVM } from "../../utils/interfaces/IBrand";

export const WorkspaceMemberBrands: React.FC<{
  workspaceUser?: IBrandUsersVM,
  organization?: ISingleBrandVM;
  setRemoveFromBrands?: Dispatch<SetStateAction<number[]>>;
  setAddToBrands?: Dispatch<SetStateAction<number[]>>;
  userId?: string;
  isPending?: boolean;
}> = ({workspaceUser, isPending, setRemoveFromBrands, organization, setAddToBrands, userId}) => {
  return (
    <div
      className="flex justify-between items-center border-b p-4"
    >
      <div className="flex items-center gap-4">
        <img
          className="w-8 h-8 object-cover"
          src={workspaceUser ? workspaceUser.organizationLogo : organization ? organization.logo : ""}
          alt={workspaceUser ? workspaceUser.organizationName : organization ? organization.name : ""}
        />
        <h1 className="text-sm-sb text-gray-700">
          {workspaceUser && workspaceUser.organizationName.substring(0, 30)}
          {workspaceUser && workspaceUser.organizationName.length > 30 ? "..." : ""}
          {organization && organization.name.substring(0, 30)}
          {organization && organization.name.length > 30 ? "..." : ""}
        </h1>
      </div>
      <SCheckbox disabled={isPending} onChange={() => {
        workspaceUser && setRemoveFromBrands && setRemoveFromBrands((prev) => {
          if (prev.includes(workspaceUser.organizationId)) {
            return prev.filter((id) => id !== workspaceUser.organizationId);
          } else {
            return [...prev, workspaceUser.organizationId];
          }
        });
        organization && setAddToBrands && setAddToBrands((prev) => {
          if (prev.includes(organization.id)) {
            return prev.filter((id) => id !== organization.id);
          } else {
            return [...prev, organization.id];
          }
        })
      }} defaultChecked={organization ? false : true} size="sm" />
    </div>
  );
};
