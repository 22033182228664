import React from "react";
import Logo from "./Logo";
import Tagline from "./Tagline";
import Typography from "./Typography";
import { useBrandStore } from "../../../store/brandStore";
import { OrganizationType } from "../../../utils/enums/Enums";

function MainInfo() {
  const { currentBrand } = useBrandStore();
  return (
    <div className="rounded-[8px] overflow-hidden border-[1px] border-gray-20 max-md:flex-col">
      <div className="px-[32px] py-[33px] bg-gray-50 max-md:py-[16px] max-md:px-[20px]">
        <p className="display-xs-b text-graycool-800">
          🌟 {currentBrand?.name}
        </p>
        {/*<p className="text-md text-graycool-600 mt-[2px]">‎</p>*/}
      </div>
      <Logo />
      {currentBrand?.organizationType === OrganizationType.COMPANY && (
        <Tagline />
      )}
      <Typography />
    </div>
  );
}

export default MainInfo;
