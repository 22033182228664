import React from "react";
import SBadge from "../../../../../design-system/SBadge";
import { useHelperInputHelperFunctions } from "./useHelperInputHelperFunctions";
import { ImageGenerationStyle } from "../../../../../utils/enums/Enums";

function SelectorTags({
  activeStyleTag,
  setActiveStyleTag,
}: {
  activeStyleTag: ImageGenerationStyle;
  setActiveStyleTag: React.Dispatch<React.SetStateAction<ImageGenerationStyle>>;
}) {
  const { imageGenerationStyles, getGenerationStyleNameById } =
    useHelperInputHelperFunctions();
  return (
    <div className="flex mt-[12px] mx-[24px] flex-wrap gap-[8px]">
      {imageGenerationStyles.map((styleId) => (
        <SBadge
          onClick={() => {
            if (activeStyleTag !== styleId) {
              setActiveStyleTag(styleId);
            }
          }}
          className={`${styleId === activeStyleTag && "bg-gray-600 border-gray-600 !text-base-white"} cursor-pointer`}
          size="sm"
          key={styleId}
        >
          {getGenerationStyleNameById(styleId)}
        </SBadge>
      ))}
    </div>
  );
}

export default SelectorTags;
