import React from "react";
import SButton from "../../../../../../design-system/SButton";
import { useCreationStore } from "../../../../../../store/creationStore";

export const StockSkeleton = () => {
  return (
    <div className={`flex gap-[12px] animate-pulse`}>
      <div className="w-[96px] h-[96px] rounded-[12px] bg-gray-200" />
      <div className="w-[96px] h-[96px] rounded-[12px] bg-gray-200" />
      <div className="w-[96px] h-[96px] rounded-[12px] bg-gray-200" />
    </div>
  );
};

function Stocks({
  setActiveTab,
}: {
  setActiveTab: React.Dispatch<
    React.SetStateAction<"search" | "generator" | "stocks" | "selector">
  >;
}) {
  const { stocks } = useCreationStore();
  return (
    <>
      <div className="w-full h-[4px] bg-gray-100 mt-[12px] mb-[12px]" />
      <div className="px-[24px]">
        <div className="w-full flex items-center justify-between">
          <p className="text-xs-m text-gray-400">Stock Images</p>
          <SButton
            type="linkColor"
            size="sm"
            onClick={() => setActiveTab("stocks")}
          >
            👉 See all
          </SButton>
        </div>
        <div
          className="mt-[8px] flex overflow-auto gap-[12px]"
          onClick={() => setActiveTab("stocks")}
        >
          {stocks.length > 0 ? (
            stocks
              .slice(0, 5)
              .map((item) => (
                <img
                  key={item.thumbnailSmallUrl}
                  className="max-w-[96px] w-full aspect-square object-cover rounded-[12px]"
                  src={item.thumbnailSmallUrl}
                  alt=""
                />
              ))
          ) : (
            <StockSkeleton />
          )}
        </div>
      </div>
    </>
  );
}

export default Stocks;
