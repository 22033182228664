import React from "react";
import { ISocialNetworkPostVM } from "../../../utils/interfaces/IPost";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  getCalendarItemBGColor,
  getCalendarItemOpacityBGColor,
  getCalendarItemTextColor,
} from "../functions/calendarRenderHelper";
import { SocialNetworkType } from "../../../utils/enums/Enums";
import { useCalendarHelper } from "../hooks/useCalendarHelper";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

function WeekPostCard({
  post,
  className,
}: {
  post: ISocialNetworkPostVM;
  className?: string;
}) {
  const { getIcon, openPostFromCalendar } = useCalendarHelper();
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        openPostFromCalendar(post.postId);
      }}
      className={`${className} cursor-pointer flex gap-[6px] rounded-[4px] h-[32px] overflow-hidden ${getCalendarItemOpacityBGColor(post.postStatus)} items-center`}
    >
      <div
        className={`${getCalendarItemBGColor(post.postStatus)} px-[8px] py-[6px]`}
      >
        <FontAwesomeIcon
          icon={getIcon(post.socialNetworkType) as IconProp}
          className="text-[16px] text-base-white"
        />
      </div>
      <div className="py-[6px]">
        <p className={`${getCalendarItemTextColor(post.postStatus)} text-xs`}>
          {post.name ?? "New Post"}
        </p>
      </div>
    </div>
  );
}

export default WeekPostCard;
